import { Breadcrumbs, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ActiveIcon } from "../../images/assets/activeIcon.svg";
import { ReactComponent as Archived } from "../../images/assets/archived.svg";
import postJob from "../../images/assets/postJob.svg";
import { getJobsByCompany, updateJobStatus } from "../../Middleware/CompanyApis";
import { getToken, getUserData } from "../../Middleware/Constans";
import ActiveJob from "../ActiveJob/ActiveJob";
import ArchivedJob from "../ArchivedJob/ArchivedJob";
import styles from "./MyJobs.module.css";

export default function MyJobs() {
    const [value, setValue] = useState("active");
    const [jobs, setJobs] = useState([]);
    // const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const token = getToken();
    const companyData = getUserData();
    // 
    const companyName = companyData.company_slug;
    const companyID = companyData.company_name;
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlePostJob = () => {
        navigate(`/companyProfile/${companyID}/postJob`);
    };

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                // setLoading(true);
                const data = await getJobsByCompany(token, companyName);
                setJobs(data.results);
                // 
            } catch (error) {
                console.error("Failed to fetch job details:", error);
            } finally {
                // setLoading(false);
            }
        };

        fetchJobs();
    }, [companyName, token]);

    const handleStatusChange = async (jobId, newStatus) => {
        try {
            const jobToUpdate = jobs.find(job => job.id === jobId);
            await updateJobStatus(token, companyName, jobToUpdate.slug, newStatus);
            setJobs(prevJobs =>
                prevJobs.map(job =>
                    job.id === jobId ? { ...job, is_active: newStatus } : job
                )
            );
        } catch (error) {
            console.error("Failed to update job status:", error);
        }
    };

    const activeJobs = jobs.filter(job => job.is_active);
    const archivedJobs = jobs.filter(job => !job.is_active);

    return (
        <div className={styles.muJobsCOntainer}>
            <div className="d-flex justify-content-between align-items-center p-3">
                <Breadcrumbs aria-label="breadcrumb" className={styles.crumbsContainer}>
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Typography color="text.primary">My-Jobs</Typography>
                </Breadcrumbs>
                <button className={styles.postJobBtn} onClick={handlePostJob}>
                    <img className="pe-2" src={postJob} alt="plus" />
                    Post a job
                </button>
            </div>

            <div className={styles.tabContainer}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="profile-sections"
                >
                    <Tab
                        className={styles.tab}
                        value="active"
                        label="Active"
                        icon={<ActiveIcon />}
                        iconPosition="start"
                    />
                    <Tab
                        className={styles.tab}
                        value="archived"
                        label="Archived"
                        icon={<Archived />}
                        iconPosition="start"
                    />
                </Tabs>
            </div>

            <div className={styles.tabsContent}>
                {value === "active" && <ActiveJob jobs={activeJobs} onStatusChange={handleStatusChange} />}
                {value === "archived" && <ArchivedJob jobs={archivedJobs} onStatusChange={handleStatusChange} />}
            </div>
        </div>
    );
}
