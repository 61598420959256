import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Typography } from '@mui/material';
import JobInformation from './JobInformation';
import JobDescription from './JobDescription';
import PerksAndBenefits from './PerksAndBenefits';
import { useNavigate } from 'react-router-dom';
import { getJobEmployment, getJobLocation, getCat, getSkills, getJobLevel, getComapnyProfile } from '../../Middleware/CandidateApis'; // Adjust the import path as necessary
import styles from './PostJob.module.css';
import { getUserData, WEBSITE_URL } from '../../Middleware/Constans';
import Swal from 'sweetalert2';

const steps = ['Job Information', 'Job Description', 'Perks & Benefits'];

const PostJob = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    jobTitle: '',
    typeOfEmployment: [],
    location: [],
    salary: '',
    categories: [],
    requiredSkills: [],
    applyBefore: '',
    jobDescription: '',
    responsibilities: [{ description: '' }],
    qualifications: [{ description: '' }],
    niceToHaves: [{ description: '' }],
    benefits: [{ description: '' }],
    minSalary: 0,
    maxSalary: 250000,
    jobLevel: null
  });
  const [isBenefitsComplete, setIsBenefitsComplete] = useState(false);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [jobLocations, setJobLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [skills, setSkills] = useState([]);
  const [jobLevels, setJobLevels] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmploymentTypes = async () => {
      try {
        const result = await getJobEmployment();
        setEmploymentTypes(result.results);
      } catch (error) {
        console.error("Error fetching employment types:", error);
      }
    };

    const fetchJobLocations = async () => {
      try {
        const result = await getJobLocation();
        setJobLocations(result.results);
      } catch (error) {
        console.error("Error fetching job locations:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const result = await getCat();
        setCategories(result.results);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchSkills = async () => {
      try {
        const result = await getSkills();
        setSkills(result.results);
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    };

    const fetchJobLevels = async () => {
      try {
        const result = await getJobLevel();
        setJobLevels(result.results);
      } catch (error) {
        console.error("Error fetching job levels:", error);
      }
    };

    const fetchCompanyId = async () => {
      const companyName = getUserData();
      try {
        const companyProfile = await getComapnyProfile(companyName.company_name);
        setCompanyId(companyProfile.company_profile.id);
      } catch (error) {
        console.error("Error fetching company profile:", error);
      }
    };

    fetchEmploymentTypes();
    fetchJobLocations();
    fetchCategories();
    fetchSkills();
    fetchJobLevels();
    fetchCompanyId();
  }, []);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      jobTitle: '',
      typeOfEmployment: [],
      location: [],
      salary: '',
      categories: [],
      requiredSkills: [],
      applyBefore: '',
      jobDescription: '',
      responsibilities: [{ description: '' }],
      qualifications: [{ description: '' }],
      niceToHaves: [{ description: '' }],
      benefits: [{ description: '' }],
      minSalary: 0,
      maxSalary: 250000,
      jobLevel: null
    });
  };

  const handleSubmit = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      company: companyId,
      title: formData.jobTitle,
      job_description: formData.jobDescription,
      type_of_employment: formData.typeOfEmployment,
      type_of_job_location: formData.location,
      job_category: formData.categories,
      job_level: formData.jobLevel ? [formData.jobLevel] : [], // Ensure it's an array
      job_skills: formData.requiredSkills,
      job_responsibility: formData.responsibilities.map(res => ({ description: res.description })),
      applicant_qualification: formData.qualifications.map(qual => ({ description: qual.description })),
      applicant_nice_to_have_skill: formData.niceToHaves.map(skill => ({ description: skill.description })),
      jobP_benefit: formData.benefits.map(benefit => ({ description: benefit.description })),
      min_salary: formData.minSalary,
      max_salary: formData.maxSalary,
      deadline: formData.applyBefore
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${WEBSITE_URL}/job/job/`, requestOptions)
      .then(response => {
        if (!response.ok) {
          return response.text().then(text => { throw new Error(text); });
        }
        return response.text();
      })
      .then(result => {
        
        Swal.fire({
          title: 'Success!',
          text: 'Job posted successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          navigate('/my-jobs'); // Redirect to /my-jobs after success
        });
      })
      .catch(error => {
        console.error('Error:', error);
        Swal.fire({
          title: 'Error!',
          text: `Job posting failed`,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <JobInformation formData={formData} setFormData={setFormData} handleNext={handleNext} employmentTypes={employmentTypes} jobLocations={jobLocations} categories={categories} skills={skills} jobLevels={jobLevels} />;
      case 1:
        return <JobDescription formData={formData} setFormData={setFormData} />;
      case 2:
        return <PerksAndBenefits formData={formData} setFormData={setFormData} setIsBenefitsComplete={setIsBenefitsComplete} />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className={styles.root}>
      <Stepper className={` ${styles.stepperStep} my-5 w-75 m-auto`} activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            <div className='my-5 d-flex justify-content-around'>
              {activeStep !== 0 && (
                <Button onClick={handleBack}>
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={
                  !formData.jobTitle ||
                  !formData.typeOfEmployment.length ||
                  !formData.location.length ||
                  !formData.applyBefore ||
                  (activeStep === 1 && (!formData.jobDescription || !formData.responsibilities.length || !formData.qualifications.length)) ||
                  (activeStep === 2 && (!formData.benefits.length || !isBenefitsComplete))
                }
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostJob;
