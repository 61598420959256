const countries = [
  { code: 'DZ', label: 'Algeria' },
  { code: 'BH', label: 'Bahrain' },
  { code: 'DJ', label: 'Djibouti' },
  { code: 'EG', label: 'Egypt' },
  { code: 'IQ', label: 'Iraq' },
  { code: 'JO', label: 'Jordan' },
  { code: 'KW', label: 'Kuwait' },
  { code: 'LB', label: 'Lebanon' },
  { code: 'LY', label: 'Libya' },
  { code: 'MR', label: 'Mauritania' },
  { code: 'MA', label: 'Morocco' },
  { code: 'OM', label: 'Oman' },
  { code: 'PS', label: 'Palestine' },
  { code: 'QA', label: 'Qatar' },
  { code: 'SA', label: 'Saudi Arabia' },
  { code: 'SO', label: 'Somalia' },
  { code: 'SS', label: 'South Sudan' },
  { code: 'SD', label: 'Sudan' },
  { code: 'SY', label: 'Syria' },
  { code: 'TN', label: 'Tunisia' },
  { code: 'AE', label: 'United Arab Emirates' },
  { code: 'YE', label: 'Yemen' },
  // You can add other countries as needed
];

export default countries;
