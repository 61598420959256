import React from 'react';
import styles from './ProfessionalInfo.module.css';

const LanguagesComponent = ({ languages }) => {

  return (
    <div className='Languages'>
      <h6 className={styles.textProfessional}>Languages</h6>
      <ul className={styles.languageContainer}>
        {languages.map((language, index) => (
          <div key={index} className={styles.languageContent}>
            <li className={styles.languageColor}>{language.language}</li>
            <li>{language.grade}</li>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default LanguagesComponent;
