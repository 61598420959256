import React, { useEffect, useState } from "react";
import arrow from "../../images/assets/Icon.svg";
import { getJobsByCategory } from "../../Middleware/CompanyApis";
import { getToken } from "../../Middleware/Constans";
import JobCard from "../OpenJobs/JobCard";
import { Link } from "react-router-dom";
import styles from "./SimilarJobs.module.css";

const SimilarJobs = ({ title, jobCategories }) => {
    const [jobs, setJobs] = useState([]);
    const token = getToken();

    useEffect(() => {
        if (jobCategories.length > 0) {
            // 
            getJobsByCategory(token, jobCategories)
                .then((result) => {
                    setJobs(result.results);
                })
                .catch((error) => {
                    console.error("Error fetching jobs:", error);
                });
        }
    }, [token, jobCategories]);

    return (
        <div className={styles.openJobsContainer}>
            <div className={styles.openJobsHeader}>
                <h2>{title}</h2>
                <Link href="/all-jobs">
                    Show all jobs
                    <img src={arrow} alt="arrow" />
                </Link>
            </div>

            <div className={styles.openJobsCardContainer}>
                {jobs.slice(0, 6).map((job) => (
                    <JobCard key={job.id} job={job} />
                ))}
            </div>
        </div>
    );
};

export default SimilarJobs;
