import React from "react";
import { createTheme, Grid, ThemeProvider } from "@mui/material";
// import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../config";
import { useSelector } from "react-redux";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2575FC",
    },
    secondary: {
      main: "#FDAD10",
    },
    text: {
      main: "#7A7A7A",
    },
    accent: {
      main: "#61CE70",
    },
  },
  typography: {
    h1: {
      fontSize: "64px",
      color: "white",
    },
    h2: {
      fontSize: "28px",
      color: "white",
    },
    h4: {
      fontWeight: "100",
      color: "text.main",
    },
    h5: {
      fontWeight: "100",
      color: "text.main",
    },
  },
});

function EditVacancy() {
  const state = useSelector((state) => state);
  const loggedUser = useSelector((state) => state.loggedUserReducer);
  const navigate = useNavigate();
  const { id } = useParams();
  // const [vacancy, setVacancy] = React.useState([]);
  const [orgName, setOrgName] = React.useState("");
  const [visibilty, setVisibility] = React.useState("");
  const [flexibility, setFlexibility] = React.useState("");

  const validationSchema = yup.object({
    title: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    description: yup
      .string()
      .trim()
      .min(10, "Too Short!")
      .max(200, "Too Long!")
      .required("Required"),
    location: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    seniority: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    org: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    flexibility: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    visibility: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
  });

  const navigateMyVacancies = () => {
    navigate(`/my-vacancies/${id}`);
  };

  const editVacancy = (values) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);
    myHeaders.append("Content-Type", "application/json");



    var raw = JSON.stringify({
      title: values.title,
      description: values.description,
      location: values.location,
      seniority: values.seniority,
      org: values.org,
      flexibility: values.flexibility,
      visibility: values.visibility,
    });


    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/vacancy/myvacancies/${id}`, requestOptions)
      .then((response) => response.json())
      .then(() => {
        navigateMyVacancies();
      })
      .catch((error) => console.error(error));
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      location: "",
      seniority: "",
      org: "",
      flexibility: "",
      visibility: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      editVacancy(values);
    },
  });

  const handleChangeOrg = (event) => {
    setOrgName(event.target.value);
    formik.values.org = event.target.value;
  };
  const handleChangeVisibilty = (event) => {
    setVisibility(event.target.value);
    formik.values.visibility = event.target.value;
  };
  const handleChangeFlexibility = (event) => {
    setFlexibility(event.target.value);
    formik.values.flexibility = event.target.value;
  };
  React.useEffect(() => {
    document.title = "Hiring Portal - Edit vacancy";
    if (state.loggedUserReducer.token === undefined) navigate("/signin");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/vacancy/myvacancies/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // setVacancy(result.data[0]);
        formik.initialValues.title = result.data[0].title;
        formik.initialValues.description = result.data[0].description;
        formik.initialValues.org = result.data[0].org;
        formik.initialValues.location = result.data[0].location;
        formik.initialValues.seniority = result.data[0].seniority;
        formik.initialValues.flexibility = result.data[0].flexibility;
        formik.initialValues.visibility = result.data[0].visibility;
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: "#F7F7F7", minHeight: "50vh" }}>
        <Grid
          container
          gap={3}
          mt={2}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={10}
            sm={10}
            md={6}
            lg={7}
            sx={{ backgroundColor: "white" }}
          >
            <List>
              <ListItem>
                <Typography variant="h5" sx={{ color: "black" }}>
                  Edit your vacancy details
                </Typography>
              </ListItem>
            </List>
            <Divider />
            <Box component="div" sx={{ mt: 1, color: "black" }}>
              <Box component="div">
                <List>
                  <ListItem>
                    <Typography variant="h6" sx={{ color: "black" }}>
                      Vacancy details
                    </Typography>
                  </ListItem>
                </List>
              </Box>
              <Box component="div" sx={{ p: 2 }}>
                <Box component="form" onSubmit={formik.handleSubmit}>
                  <Box
                    sx={{
                      maxWidth: "100%",
                      mb: 2,
                    }}
                  >
                    <TextField
                      autoFocus
                      name="title"
                      label="Job title"
                      id="title-small"
                      fullWidth
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.title && Boolean(formik.errors.title)
                      }
                    // helpertext={formik.touched.title && formik.errors.title}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <small className="text-danger">
                        {formik.errors.title}
                      </small>
                    )}
                  </Box>
                  <Box
                    sx={{
                      maxWidth: "100%",
                    }}
                  >
                    <TextField
                      name="description"
                      label="Vacancy description"
                      id="description-small"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      // helpertext={
                      //   formik.touched.description && formik.errors.description
                      // }
                      fullWidth
                      multiline
                      rows={2}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <small className="text-danger">
                          {formik.errors.description}
                        </small>
                      )}
                  </Box>
                  <Box component="div" sx={{ mt: 1 }}>
                    <Box component="div">
                      <List>
                        <ListItem>
                          <Typography variant="h6" sx={{ color: "black" }}>
                            Do you have more details?
                          </Typography>
                        </ListItem>
                      </List>
                    </Box>
                    <Box component="div" sx={{ p: 2 }}>
                      <Box
                        sx={{
                          maxWidth: "100%",
                          mb: 2,
                        }}
                      >
                        <TextField
                          name="location"
                          id="location-small"
                          value={formik.values.location}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.location &&
                            Boolean(formik.errors.location)
                          }
                          // helpertext={
                          //   formik.touched.location && formik.errors.location
                          // }
                          label="Location"
                          fullWidth
                        />
                        {formik.touched.location && formik.errors.location && (
                          <small className="text-danger">
                            {formik.errors.location}
                          </small>
                        )}
                      </Box>
                      <Box
                        sx={{
                          maxWidth: "100%",
                          mb: 2,
                        }}
                      >
                        <TextField
                          label="Seniority"
                          id="seniority-small"
                          fullWidth
                          name="seniority"
                          value={formik.values.seniority}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.seniority &&
                            Boolean(formik.errors.seniority)
                          }
                          helpertext={
                            formik.touched.seniority && formik.errors.seniority
                          }
                        />
                        {formik.touched.seniority &&
                          formik.errors.seniority && (
                            <small className="text-danger">
                              {formik.errors.seniority}
                            </small>
                          )}
                      </Box>
                      <Box
                        sx={{
                          maxWidth: "100%",
                          mb: 1,
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            <strong>Flexibility</strong> <small>{formik.initialValues.flexibility}</small>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={flexibility}
                            label="flexibility"
                            onChange={handleChangeFlexibility}
                          >
                            <MenuItem value={"Full time"}>Full time</MenuItem>
                            <MenuItem value={"Part time"}>Part time</MenuItem>
                            <MenuItem value={"Hybrid"}>Hybrid</MenuItem>
                            <MenuItem value={"Remote"}>Remote</MenuItem>
                          </Select>
                        </FormControl>
                        {formik.touched.flexibility &&
                          formik.errors.flexibility && (
                            <small className="text-danger">
                              {formik.errors.flexibility}
                            </small>
                          )}
                      </Box>
                      <Box
                        sx={{
                          maxWidth: "100%",
                          mb: 1,
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            <strong>Company hiring for</strong> <small>{formik.initialValues.org}</small>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={orgName}
                            label="Company hiring for"
                            onChange={handleChangeOrg}
                          >
                            <MenuItem value={"EYouth"}>EYouth</MenuItem>
                            <MenuItem value={"LinkedIn"}>LinkedIn</MenuItem>
                          </Select>
                        </FormControl>
                        {formik.touched.org && formik.errors.org && (
                          <small className="text-danger">
                            {formik.errors.org}
                          </small>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <hr
                    style={{
                      border: "1px solid blue",
                      width: "75%",
                      margin: "auto",
                    }}
                  ></hr>
                  <Box component="div" sx={{ mt: 1 }}>
                    <Box component="div">
                      <List>
                        <ListItem>
                          <Typography variant="h6" sx={{ color: "black" }}>
                            Make this project visible to:
                          </Typography>
                        </ListItem>
                      </List>
                    </Box>
                    <Box component="div" sx={{ p: 2 }}>
                      <Box
                        sx={{
                          maxWidth: "100%",
                          mb: 2,
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            <strong>Visibility</strong> <small>{formik.initialValues.visibility}</small>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={visibilty}
                            label="Company hiring for"
                            onChange={handleChangeVisibilty}
                          >
                            <MenuItem value={"Private"}>Private</MenuItem>
                            <MenuItem value={"Public"}>Public</MenuItem>
                          </Select>
                        </FormControl>
                        {formik.touched.visibility &&
                          formik.errors.visibility && (
                            <small className="text-danger">
                              {formik.errors.visibility}
                            </small>
                          )}
                      </Box>
                    </Box>
                  </Box>
                  <hr
                    style={{
                      border: "1px solid blue",
                    }}
                  ></hr>
                  <Box component="div" display="flex" sx={{ px: 2 }}>
                    <Box component="div" width={"50%"}>
                      <Typography variant="h6">* Required field</Typography>
                    </Box>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        width: "50%",
                        justifyContent: "end",
                      }}
                    >
                      <Box component="div">
                        <Button type="submit" variant="contained">
                          Edit
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={8}
            sm={6}
            md={4}
            lg={3}
            sx={{
              minHeight: "300px",
              boxShadow: "0px -5px 1px 0px rgba(6,0,58,0.9)",
            }}
          >
            <Box
              component="div"
              sx={{
                backgroundColor: "#ddddff",
                padding: "5px 25px",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item lg={1}>
                  <TipsAndUpdatesIcon></TipsAndUpdatesIcon>
                </Grid>
                <Grid item lg={10}>
                  <Typography variant="h6" sx={{ color: "black" }}>
                    Get customized talent insights
                  </Typography>
                </Grid>
                <Typography variant="caption">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
              </Grid>
            </Box>
            <Box component="div" sx={{ padding: "25px" }}>
              <Box component="div" mb={1}>
                <Box component="div" sx={{ display: "block" }}>
                  <Typography variant="h4" sx={{ color: "black" }}>
                    8K+
                  </Typography>
                </Box>
                <Box component="div" sx={{ display: "block" }}>
                  <Typography variant="caption">
                    with a specific job title
                  </Typography>
                </Box>
              </Box>
              <Box component="div" mb={1}>
                <Box component="div" sx={{ display: "block" }}>
                  <Typography variant="h4" sx={{ color: "black" }}>
                    8K+
                  </Typography>
                </Box>
                <Box component="div" sx={{ display: "block" }}>
                  <Typography variant="caption">
                    in a specific location
                  </Typography>
                </Box>
              </Box>
              <Box component="div">
                <Box component="div" sx={{ display: "block" }}>
                  <Typography variant="h4" sx={{ color: "black" }}>
                    8K+
                  </Typography>
                </Box>
                <Box component="div" sx={{ display: "block" }}>
                  <Typography variant="caption" style={{ padding: "0px 0px" }}>
                    meet your requirements
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );

  // return (
  //   <Box
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     height="100vh"
  //   >
  //     <CircularProgress size={100} />
  //   </Box>
  // );
}
export default EditVacancy;
