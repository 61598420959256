import React from "react";
import styles from "./OpenJobs.module.css";
import companyLogo from "../../images/notFound.jpeg";

const JobCard = ({ job }) => {
  // Helper function to safely join array elements or return a fallback text
  const joinArray = (arr) => (Array.isArray(arr) && arr.length ? arr.map(item => item.name).join(", ") : "N/A");

  return (
    <a href={`/job-details/${job.company.slug}/${job.slug}`} target="_blank" rel="noopener noreferrer" className={styles.companyInfoCard}>
      <div className={styles.companyLogo}>
        <img src={job.company.logo ? job.company.logo : companyLogo} alt="company-logo" className={styles.companyLogo} />
      </div>
      <div className={styles.jobTitle}>
        <h2 className='my-2'>{job.title}</h2>
        <div className="d-flex gap-2">
          <h4>{job.company.name}</h4>
          <span>
            {job.company.locations.map((location) => (
              <span key={location.id}> {location.title} </span>
            ))}
          </span>
        </div>
        <div className={`d-flex ${styles.jobDescription}`}>
          <h4>{joinArray(job.type_of_employment)}</h4>
          <h4>{joinArray(job.job_category)}</h4>
        </div>
      </div>
    </a>
  );
};

export default JobCard;
