const imagesData1 = [
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/MAGHRABI+LOGO.png",
    title: "Maghrabi",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/53-1400X600.jpg",
    title: "error",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/-DtEf1k0_400x400.png",
    title: "Homzmart",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/B.TECH-Logo.png",
    title: "B.TECH-",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/CairoCart_logo.png",
    title: "CairoCart",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Foodics.png",
    title: "Foodics",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/arqqa.png",
    title: "arqqa",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Goodix.jpg",
    title: "Goodix",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/HSBC+Logo.png",
    title: "HSBC",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Huawei.jfif",
    title: "Huawei",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Jobzella-Logo-2.png",
    title: "Jobzella",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/LOGO-02+(2).png",
    title: "LOGO-02",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/dot+it.png",
    title: "dot",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/dottopia.png",
    title: "dottopia",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/download+(1).png",
    title: "download1",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/download+(2).png",
    title: "download2",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/download+(3).png",
    title: "download3",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/download+(4).png",
    title: "download4",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/download.png",
    title: "download",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/elmenus.jpg",
    title: "elmenus",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/excel+systems+logo.jpg",
    title: "excel",
  },

  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/filkhedma.png",
    title: "filkhedma",
  },

  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/goodsmart.png",
    title: "goodsmart",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/goziii.png",
    title: "error",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Hadbrok-insurance-broker.png",
    title: "error",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/i+netwrok.png",
    title: "error",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/icon.png",
    title: "error",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/LfLf-min.jpg",
    title: "error",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/logo.png",
    title: "Mixel",
  },

  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/logo-with-modeso.jpg",
    title: "modeso",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Maxab+logo.jpg",
    title: "Maxab",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/MicrosoftTeams-image+(15).png",
    title: "MicrosoftTeams",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Nawy+Blue-01.png",
    title: "Nawy Blue",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/nexta+logo.png",
    title: "nexta",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Petromin+Co+En.png",
    title: "Petromin",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Profolio.jfif",
    title: "Profolio",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Raya-Customer-Experience-Egypt-3746-1622555910.png",
    title: "Raya-Customer-Experience-Egypt",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/rubikal-logo.png",
    title: "rubikal",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/seoudi+market.jfif",
    title: "seoudi market",
  },

  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/talent+360.png",
    title: "talent 360",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Vezeeta-logo.png",
    title: "Vezeeta",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/WG+Logo-02+(005).png",
    title: "WG",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Worxmart+Logo+(2).png",
    title: "Worxmart",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/%D9%85%D9%88%D9%82%D8%B9+%D9%86%D9%88%D9%86.png",
    title: "%D9%85%D9%88%D9%82%D8%B9+%D9%86%D9%88%D9%86",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/wordbee+logo.jpg",
    title: "wordbee",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Fatura.jpg",
    title: "Fatura",
  },
  {
    img: "https://eyouth-videos.s3.me-south-1.amazonaws.com/Hiring_Portal_Partners/Sutherland_LogoXTagline_Ver_COL.png",
    title: "Sutherland",
  },
];

export default imagesData1;
