import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import JobSeeker from "../../components/JobSeeker/JobSeeker";
import signInImg from "../../images/assets/loginBG.jpeg";
import styles from "./NewSignUpCandidate.module.css";
import CompanySignUp from './CompanySignUp';
import UserSignUp from "./UserSignUp";
import { getToken } from "../../Middleware/Constans";
// import CompanyLogin from "../../components/CompanyLogin/CompanyLogin";

function NewSignUpCandidate() {
    const [value, setValue] = useState("applicant-login");
    const token = getToken()


    useEffect(() => {
        if (token) {
            window.location.href = ("/home")
        }

    }, [])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.signInContainer}>
            <div className={styles.imgContainer}>
                <img src={signInImg} alt="Eyouth-logo" />
            </div>

            <div className={styles.formContainer}>
                <div className={styles.toggleContainer}>
                    <Tabs
                        className={styles.tabsContainer}
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { display: "none" } }} // Hide the indicator
                    >
                        <Tab
                            className={`${styles.tab} ${value === 'applicant-login' ? styles.activeTab : ''}`}
                            value="applicant-login"
                            label="Job Seeker"
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: "#E9EBFD",
                                    color: "var(--main_color)",
                                    borderBottom: "none"
                                }
                            }}
                        />
                        <Tab
                            className={`${styles.tab} ${value === 'company-login' ? styles.activeTab : ''}`}
                            value="company-login"
                            label="Company"
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: "#E9EBFD",
                                    color: "var(--main_color)",
                                    borderBottom: "none"
                                }
                            }}
                        />
                    </Tabs>
                </div>

                <div className={styles.tabsContent}>
                    {value === "applicant-login" && <UserSignUp />}
                    {value === "company-login" && <CompanySignUp />}
                </div>
            </div>
        </div>
    );
}
export default NewSignUpCandidate;
