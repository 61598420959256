import React from "react";
import styles from "./PostingJobs.module.css";
import { getUserData } from "../../Middleware/Constans";

export const PostingJobs = () => {
    const userData = getUserData();
    // 

    return (
        <>
            {userData.company_name && (
                <div className={styles.postingJobsContainer}>
                    <div className={styles.jobsBtnContainer}>
                        <h2>Start posting jobs today</h2>
                        <h5>Start posting jobs.</h5>
                        {userData.company_name ? (
                            <a href={`/companyProfile/${userData.company_name}/postJob`}>
                                Post New Job
                            </a>
                        ) : (
                            <a href="/partner-request">Partner Request</a>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
