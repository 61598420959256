import React, { useState, useEffect } from 'react';
import styles from './JobDashboardCompanyView.module.css';
import stripe_logo from '../../images/assets/stripe_logo_Dp4F5YyKm 1.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import mdi_people from '../../images/assets/mdi_people-group.png';
import people from '../../images/assets/Vector (1).png';
import JobCardDashboard from './JobCardDashboard/JobCardDashboard';
import PiplineCard from './PiplineCard/PiplineCard';
import { Link, useParams } from 'react-router-dom';
import { getToken } from '../../Middleware/Constans';
import { getSingleJob } from '../../Middleware/CompanyApis';
import { ApplicantsInJob } from '../../Middleware/CandidateApis';
import Swal from 'sweetalert2';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function JobDashboardCompanyView() {
  const [value, setValue] = useState(0);
  const [jobDetails, setJobDetails] = useState(null);
  const [applicantsData, setApplicantsData] = useState([]);
  const { companyName, jobSlug } = useParams();
  const token = getToken();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const res = await getSingleJob(token, companyName, jobSlug);
        setJobDetails(res);
      } catch (error) {
        console.error("Failed to fetch job details:", error);
        Swal.fire({
          icon: 'error',
          title: 'Fetch Error',
          text: 'Failed to fetch job details. Please try again later.',
        });
      }
    };

    fetchJobDetails();
  }, [companyName, jobSlug, token]);

  useEffect(() => {
    if (!jobDetails || !jobDetails.id) return;

    const fetchApplicantsInJob = async () => {
      try {
        const res = await ApplicantsInJob(token, jobDetails.id);
        setApplicantsData(res.results);

      } catch (error) {
        console.error("Failed to fetch applicants:", error);
        Swal.fire({
          icon: 'error',
          title: 'Fetch Error',
          text: 'Failed to fetch applicants. Please try again later.',
        });
      }
    };

    fetchApplicantsInJob();
  }, [jobDetails]);

  const submittedApplicants = Array.isArray(applicantsData) ? applicantsData.filter(applicant => applicant.status === 'Submitted') : [];

  return (
    <>
      <div className={styles.dashboardBackground}>
        <div className={`d-flex justify-content-start ${styles.buttonJobsDiv}`}>
          <Link to={`/job-details/${companyName}/${jobSlug}`}>
            <button className={`${styles.buttonJobs}`}>
              <ArrowBackIcon /> Back to job details
            </button>
          </Link>
        </div>

        <div className={styles.companyCard}>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center w-75'>
              <img width="25%" src={jobDetails && jobDetails.company ? jobDetails.company.logo : stripe_logo} alt="Company Logo" />
              <div className='ms-4'>
                <p>{jobDetails ? jobDetails.title : 'Loading...'}</p>
                <span>
                  {jobDetails && jobDetails.company ? jobDetails.company.name : ''}
                  {jobDetails && jobDetails.type_of_job_location && jobDetails.type_of_job_location[0] ? `, ${jobDetails.type_of_job_location[0].name}` : ''}
                  {jobDetails && jobDetails.type_of_employment && jobDetails.type_of_employment[0] ? `, ${jobDetails.type_of_employment[0].name}` : ''}
                </span>
              </div>
            </div>
            <div>
              <span>{jobDetails ? `Total Applicants: ${applicantsData.length}` : ""}</span>
            </div>
          </div>
        </div>
      </div>

      <Box className="w-75 m-auto">
        <Box>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab
              label={
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <img src={mdi_people} alt="All Apply Requests Icon" />
                  <Typography variant="body1">All Apply Requests</Typography>
                </div>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <img src={people} alt="Pipeline Icon" />
                  <Typography variant="body1">Pipeline</Typography>
                </div>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {submittedApplicants.length > 0 ? (
            submittedApplicants.map((applicant) => (
              <JobCardDashboard
                key={applicant.id}
                id={applicant.id}
                image={applicant.applicant.profile_image}
                name={applicant.applicant.user.username || ""}
                jobTitle={applicant.applicant.user.email}
                location={`${applicant.applicant.country}, ${applicant.applicant.city}`}
                university={applicant.applicant.education.length > 0 ? applicant.applicant.education[0].university : 'N/A'}
                experience={applicant.applicant.work_experience_years}
                username={applicant.applicant.user.username}
              />
            ))
          ) : (

            <div className={styles.noDataMessage}>There is no applicant submitted now. Review application on pipeline.</div>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PiplineCard applicantsData={applicantsData} />
        </CustomTabPanel>
      </Box>
    </>
  );
}

export default JobDashboardCompanyView;
