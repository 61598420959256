import { createSlice } from "@reduxjs/toolkit";

export const acceptedSlice = createSlice({
  name: "acceptedSlice",
  initialState: [],
  reducers: {
    SET_ACCEPTED: (state, action) => {
      state = action.payload;
      return state;
    },
    ADD_ACCEPTED: (state, action) => {
      const found = state.find(
        (ele) => ele.username === action.payload.username
      );
      if (!found) {
        state.push(action.payload);
      }
      return state;
    },
    REMOVE_ACCEPTED: (state, action) => {
      return state.filter(
        (element) => element.username !== action.payload.username
      );
    },
  },
});

export const {
  ADD_ACCEPTED,
  REMOVE_ACCEPTED,
  SET_ACCEPTED,
} = acceptedSlice.actions;
export default acceptedSlice.reducer;
