import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import PartnersImages from "./PartnersImages";
import "./PartnersImages.css";

// function srcset(image, size, rows = 1, cols = 1) {
//   return {
//     src: `${image}?w=${size}&h=${size * rows}&fit=cover&auto=format`,
//     srcSet: `${image}?w=${size * cols}&h=${size * rows
//       }&fit=crop&auto=format&dpr=2 2x`,
//   };
// }

function PartnersSection() {

  // const navigate = useNavigate()

  // const navigatePartners = () => {
  //   navigate("/partners");
  // };

  return (
    <Box
      sx={{
        textAlign: "center",
        py: 2,
        marginTop: "15px"
      }}
    >
      <Typography
        component="div"
        gutterBottom
        className="partners-title"
      >
        Our partners
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        maxWidth="false"
        py={1}
      >
        <Grid item md={11} sm={12} xs={12}>
          <PartnersImages />
        </Grid>
      </Grid>

      {/* <div className="d-flex justify-content-center my-5">

        <Button
          sx={{
            background: "#263997", color: "white", "&:hover": {
              background: "#F7BE1E",
              color: "#263997"
            }
          }}
          onClick={navigatePartners}
        >
          {" "}
          See More
          <KeyboardDoubleArrowRightIcon />
        </Button>
      </div> */}
    </Box>
  );
}

export default PartnersSection;
