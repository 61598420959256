import { createSlice } from "@reduxjs/toolkit";

export const categoryResultsSlice = createSlice({
  name: "categoryResultsSlice",
  initialState: [],
  reducers: {
    setCategoryResults: (state, action) => {
      state = action.payload;
      return state;
    },
    clearCatgoryResults: (state) => {
      state = [];
      return state;
    },
  },
});

export const { setCategoryResults, clearCatgoryResults } =
  categoryResultsSlice.actions;

export default categoryResultsSlice.reducer;
