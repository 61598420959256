import { createSlice } from "@reduxjs/toolkit";

export const currentPipelineSlice = createSlice({
  name: "currentPipeline",
  initialState: "",
  reducers: {
    currentPipeline: (state, action) => {
      state = action.payload;
      return state;
    },
    clearPipeline: (state) => {
      state = "";
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { currentPipeline, clearPipeline } = currentPipelineSlice.actions;

export default currentPipelineSlice.reducer;
