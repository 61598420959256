import React, { useState } from 'react';
import iconPhoto from "../../images/assets/IconPhoto.svg";
import styles from "./CompanyPhotos.module.css";
import { Button } from '@mui/material';
import { getToken, WEBSITE_URL } from '../../Middleware/Constans';

const API_URL = `${WEBSITE_URL}/company/images/`; // Ensure this is the correct endpoint
const API_TOKEN = getToken();

export default function CompanyPhotos() {
    const [files, setFiles] = useState([]);

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Iterate over each file and send a separate request
        for (const file of files) {
            const formData = new FormData();
            formData.append("image", file);

            const headers = new Headers();
            headers.append("Authorization", `JWT ${API_TOKEN}`);
            headers.append("Accept", "application/json");

            const requestOptions = {
                method: "POST",
                headers: headers,
                body: formData,
                redirect: "follow",
            };

            try {
                const response = await fetch(API_URL, requestOptions);
                if (!response.ok) {
                    const errorResult = await response.json();
                    throw new Error(`Error: ${response.status} - ${errorResult.message}`);
                }
                const result = await response.json();
                // 
                // Handle success for this file (e.g., show a success message or update UI)
            } catch (error) {
                // console.error("Upload failed:", error.message);
                // Handle error for this file (e.g., show an error message)
            }
        }

        // Clear files and input after upload
        setFiles([]);
        event.target.reset();
    };

    return (
        <div className={styles.companyPhotosContainer}>
            <div className={styles.photoSection}>
                <div className={styles.photoHead}>
                    <h2>Basic Information</h2>
                    <h3>Add photos of your company. You can add only 4 photos.</h3>
                </div>

                <form onSubmit={handleSubmit} className={styles.uploadContainer}>

                    {/* {[...Array(4)].map((_, index) => ( */}
                    <div className={styles.uploadImgSection}>
                        <label htmlFor={`upload-image`} className={styles.uploadLabel}>
                            <img src={iconPhoto} alt="icon" />
                            <h4>
                                <span>Click to replace </span>
                            </h4>
                            <h5>SVG, PNG, JPG or GIF (max. 400 x 400px)</h5>
                        </label>
                        <input
                            id={`upload-image`}
                            type="file"
                            className={styles.uploadInput}
                            onChange={handleFileChange}
                            accept=".svg, .png, .jpg, .gif"
                            hidden
                        />
                    </div>
                    {/* ))} */}

                    <div className={styles.saveBtnSection}>
                        <Button type="submit" variant="contained" color="primary" disabled={files.length === 0}>
                            Upload Photos
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
