import { createSlice } from "@reduxjs/toolkit";

export const contactedSlice = createSlice({
  name: "contactedSlice",
  initialState: [],
  reducers: {
    SET_CONTACTED: (state, action) => {
      state = action.payload;
      return state;
    },
    ADD_CONTACTED: (state, action) => {
      const found = state.find(
        (ele) => ele.username === action.payload.username
      );
      if (!found) {
        state.push(action.payload);
      }
      return state;
    },
    REMOVE_CONTACTED: (state, action) => {
      return state.filter(
        (element) => element.username !== action.payload.username
      );
    },
  },
});

export const {
  ADD_CONTACTED,
  SET_CONTACTED,
  REMOVE_CONTACTED,
} = contactedSlice.actions;
export default contactedSlice.reducer;
