import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getComapnyProfile } from "../../Middleware/CandidateApis";
import { getUserData, WEBSITE_URL } from "../../Middleware/Constans";
import Loading from "../../components/Loading/Loading";
import OpenJobs from "../../components/OpenJobs/OpenJobs";
import fireIcon from "../../images/assets/Frame 1189.png";
import imageLogo from "../../images/assets/Nomad.svg";
import buildingIcon from "../../images/assets/building.png";
import editIcon from "../../images/assets/edit.svg";
import groupIcon from "../../images/assets/groupIcons .png";
import postJob from "../../images/assets/postJob.svg";
import LocationIcon from "../../images/locationIcon.png";
import styles from "./CompanyProfile.module.css";

function CompanyProfile() {
  const [companyProfile, setCompanyProfile] = useState(null);
  const navigate = useNavigate();
  // const token = getToken();
  const { id } = useParams();
  // 
  const userData = getUserData();
  // const company = userData.company_name;

  useEffect(() => {
    const fetchCompanyProfile = async () => {
      try {
        const data = await getComapnyProfile(id);
        // 
        setCompanyProfile(data.company_profile); // Set the company profile data
      } catch (error) {
        console.error("Failed to fetch company profile:", error);
      }
    };

    fetchCompanyProfile();
  }, [id]);

  const handleEditCompany = () => {
    navigate(`/companyProfile/${id}/editProfile`);
  };

  function handleClick(event) {
    event.preventDefault();
  }

  const handlePostJob = () => {
    navigate(`/companyProfile/${id}/postJob`);
  };

  if (!companyProfile) return <Loading />;

  return (
    <>
      <div
        className={styles.companyProfileBackdround}
        role="presentation"
        onClick={handleClick}
      >
        {userData.company_name === id && (
          <div className={styles.btnSection}>
            <button className={styles.addJobBtn} onClick={handleEditCompany}>
              <img className="pe-2" src={editIcon} alt="edit" />
              Edit Profile Details
            </button>
            <button className={styles.postJobBtn} onClick={handlePostJob}>
              <img className="pe-2" src={postJob} alt="plus" />
              Post a job
            </button>
          </div>
        )}{" "}
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Link underline="hover" color="inherit" href="/companies">
            Companies
          </Link>
          <Typography color="text.primary">
            {companyProfile && companyProfile.name
              ? companyProfile.name
              : "Company Name"}
          </Typography>
        </Breadcrumbs>
        <div className="d-flex my-5">
          <div className="mx-5">
            <img
              src={`${WEBSITE_URL}${companyProfile && companyProfile.logo
                ? companyProfile.logo
                : imageLogo
                }`}
              width="100px"
              alt="company-logo"
            />
          </div>
          <div className="d-flex flex-column mt-2">
            <div className="d-flex align-items-center">
              <h1 className={styles.companyName}>
                {companyProfile && companyProfile.name
                  ? companyProfile.name
                  : "Company Name"}
              </h1>
              {/* <span className="border p-2">{companyProfile && companyProfile.company_size ? companyProfile.company_size : "0"} jobs</span> */}
            </div>
            <a
              href={
                companyProfile && companyProfile.website
                  ? companyProfile.website
                  : "#"
              }
            >
              {companyProfile && companyProfile.website
                ? companyProfile.website
                : "Website"}
            </a>
            <div className={styles.companyProfileDetails}>
              <div className="d-flex align-items-center">
                <img className="me-3" src={fireIcon} alt="fireIcon" />
                <div className="d-flex flex-column">
                  <span className="text-muted">Founded</span>
                  <span>
                    {companyProfile && companyProfile.date_founded
                      ? new Date(
                        companyProfile.date_founded
                      ).toLocaleDateString()
                      : "N/A"}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <img className="me-3" src={groupIcon} alt="groupIcon" />
                <div className="d-flex flex-column">
                  <span className="text-muted">Employees</span>
                  <span>
                    {companyProfile && companyProfile.company_size
                      ? companyProfile.company_size
                      : "N/A"}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <img className="me-3" src={LocationIcon} alt="LocationIcon" />
                <div className="d-flex flex-column">
                  <span className="text-muted">Location</span>
                  <span>
                    {companyProfile && companyProfile.locations
                      ? companyProfile.locations
                        .map((location) => location.title)
                        .join(", ")
                      : "N/A"}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <img className="me-3" src={buildingIcon} alt="buildingIcon" />
                <div className="d-flex flex-column">
                  <span className="text-muted">Industry</span>
                  <span>
                    {companyProfile && companyProfile.industryy
                      ? companyProfile.industryy
                      : "N/A"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-3">
        <h2 className="text-primary my-5">Company Profile</h2>
        <p>
          {companyProfile && companyProfile.about
            ? companyProfile.about
            : "No information available."}
        </p>

        <h2 className="text-primary my-5">Contact</h2>
        <div className="d-flex flex-wrap gap-3 w-75">
          {companyProfile && companyProfile.twitter_link && (
            <div className={styles.contact_div}>
              <TwitterIcon />{" "}
              <a href={companyProfile.twitter_link}>
                Twitter
              </a>
            </div>
          )}
          {companyProfile && companyProfile.facebook_link && (
            <div className={styles.contact_div}>
              <FacebookRoundedIcon />{" "}
              <a href={companyProfile.facebook_link}>
                Facebook
              </a>
            </div>
          )}
          {companyProfile && companyProfile.linkedin_link && (
            <div className={styles.contact_div}>
              <LinkedInIcon />{" "}
              <a href={companyProfile.linkedin_link}>
                Linkedin
              </a>
            </div>
          )}
          {companyProfile && companyProfile.instagram_link && (
            <div className={styles.contact_div}>
              <InstagramIcon />{" "}
              <a href={companyProfile.instagram_link}>
                Instagram
              </a>
            </div>
          )}
          {companyProfile && companyProfile.youtube_link && (
            <div className={styles.contact_div}>
              <YouTubeIcon />{" "}
              <a href={companyProfile.youtube_link}>
                Youtube
              </a>
            </div>
          )}
        </div>

        <div className={styles.companyImagesContainer}>
          {companyProfile && companyProfile.company_images && companyProfile.company_images.length > 0 ? (
            <div className={styles.imageWrapper}>
              {companyProfile.company_images
                .slice()
                .reverse()
                .slice(0, 2)
                .map((img, index) => (
                  <img
                    key={index}
                    className={index === 0 ? styles.mainImage : styles.subImage}
                    src={`${WEBSITE_URL}${img.image_link}`}
                    alt={`company-img-${index + 1}`}
                  />
                ))}
            </div>
          ) : (
            <p>No images available.</p>
          )}
        </div>


      </div >
      <OpenJobs title="My Jobs" companyId={companyProfile.id} />
    </>
  );
}

export default CompanyProfile;
