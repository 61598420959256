import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import styles from './UserProfile.module.css';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EditIcon from '@mui/icons-material/Edit';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { getUserProfile } from '../../Middleware/CandidateApis';
import { WEBSITE_URL, getToken, getUserData } from '../../Middleware/Constans';
import AppliedJobs from './AppliedJobs/AppliedJobs';
import Attachments from './Attachments/Attachments';
import EYouthCertification from './EYouthCertification/EYouthCertification';
import Points from './Points/Points';
import ProfessionalInfo from './ProfessionalInfo/ProfessionalInfo';
import { useNavigate } from 'react-router-dom';
import defultImage from '../../images/assets/logo.jpg';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: value === 100 ? 'green' : value >= 50 ? 'orange' : 'red',
    transform: 'translateX(-100%)', // This makes the bar fill from right to left
  },
  [`&.${linearProgressClasses.root}`]: {
    direction: 'rtl', // This makes the bar fill from right to left
  },
}));

function UserProfile() {
  const [value, setValue] = useState(0);
  const [profile, setProfile] = useState({});
  const [totalPointsPercentage, setTotalPointsPercentage] = useState(0);
  const token = getToken();
  const navigate = useNavigate();
  const userData = getUserData();

  const handleEditProfile = () => {
    navigate("/user-profile/edit-profile");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getUserProfile(token).then((res) => {
      const profileData = res.applicant_profile;
      setProfile(profileData);


      const {
        contact_points,
        eyouth_certifications_points,
        offline_courses_points,
        personal_points,
        professional_points,
      } = profileData;

      const totalPoints = contact_points + eyouth_certifications_points + offline_courses_points + personal_points + professional_points;
      const maxPoints = 400; // Set the maximum points to 400
      const percentage = (totalPoints / maxPoints) * 100;
      setTotalPointsPercentage(percentage);
    }).catch((error) => {
      console.error("Error fetching profile data:", error);
    });
  }, [token]);

  return (
    <div className={styles.mainContainerProfile}>
      <div className={`d-flex ${styles.DetailsAndButton}`}>
        <button className={`my-4 btn ${styles.button_outline}`} onClick={handleEditProfile}>
          <EditIcon />
          Edit Profile Details
        </button>

        <div className={styles.pointDiv}>
          <h6 style={{ color: "var(--main_color)" }}> "A complete profile means a faster path <br /> to your next job!"</h6>
          <span> Your Total Points : {profile.total_points}</span>
          <div className='d-flex align-items-center'>
            <AssignmentIndIcon style={{ marginRight: '10px', color: "var(--main_color)" }} />
            <div style={{ flex: 1, marginRight: '25px' }}>
              <BorderLinearProgress variant="determinate" value={totalPointsPercentage} />
            </div>
            {totalPointsPercentage === 100 ? (
              <span style={{ color: "green", fontWeight: "bold" }}>completed perfectly!</span>
            ) : totalPointsPercentage >= 50 ? (
              <span style={{ color: "orange", fontWeight: "bold", width: "46%" }}>You still can complete your profile, go on!</span>
            ) : (
              <span style={{ color: "red", fontWeight: "bold" }}>Complete your data</span>
            )}
          </div>

        </div>

      </div>

      <div className={`d-flex w-100 m-auto justify-content-around ${styles.mobileViewContent}`}>
        <aside className={styles.AsideContainer}>
          <div className='d-flex align-items-center border-bottom my-2'>
            <img src={profile.profile_image ? `${WEBSITE_URL}${profile.profile_image}` : defultImage} alt="" className={`my-2 ${styles.userProfileImage}`} />
            <div className='d-flex flex-column mb-3 ms-3'>
              <p>{profile.fullname} </p>
              <span className='p-0 m-0'>{profile.job_title} </span>
              <span className='p-0 m-0'>{profile.work_experience_years} Years of Experience </span>
            </div>
          </div>
          <h2 className={styles.contactH1}>Contact</h2>
          <div className='d-flex mb-3'>
            <EmailIcon className='me-2' />
            <div className='d-flex flex-column'>
              <span>Email</span>
              <span>{userData.email}</span>
            </div>
          </div>
          <div className='d-flex mb-3'>
            <LocalPhoneIcon className='me-2' />
            <div className='d-flex flex-column'>
              <span>Phone</span>
              <span>{profile.phonenumber}</span>
            </div>
          </div>
          {profile.linkedin_link && <div className='d-flex mb-3'>
            <LinkedInIcon className='me-2' sx={{ color: "var(--main_color)" }} />
            <div className='d-flex flex-column'>
              <a href={profile.linkedin_link}> <span>Linkedin</span> </a>
            </div>
          </div>}
          {profile.instgram_link && <div className='d-flex mb-3'>
            <InstagramIcon className='me-2' sx={{ color: "var(--main_color)" }} />
            <div className='d-flex flex-column'>
              <a href={profile.instgram_link}> <span>Instagram</span></a>
            </div>
          </div>}
          {profile.facebook_link && <div className='d-flex mb-3'>
            <FacebookRoundedIcon className='me-2' sx={{ color: "var(--main_color)" }} />
            <div className='d-flex flex-column'>
              <a href={profile.facebook_link}> <span >Facebook</span></a>
            </div>
          </div>}
          <Points profile={profile} />
        </aside>

        <main className={styles.mainContainer}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Personal Info" className={styles.PersonalInfo} {...a11yProps(0)} />
                <Tab label="Professional Info" className={styles.PersonalInfo} {...a11yProps(1)} />
                <Tab label="CV & Attachments" className={styles.PersonalInfo} {...a11yProps(2)} />
                <Tab label="My Applications" className={styles.PersonalInfo} {...a11yProps(3)} />
                <Tab label="EYouth Certifications" className={styles.PersonalInfo} {...a11yProps(4)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <PersonalInformation FullName={profile.fullname} dateOfBirth={profile.date_birth}
                street={profile.street} country={profile.country} city={profile.city} gender={profile.gender}
                military_status={profile.military_status} nationality={profile.nationality} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ProfessionalInfo
                Work_Experience={profile.work_experience}
                About_me={profile.about}
                Education={profile.education}
                Skills={profile.skills}
                Languages={profile.languages}
                Certifications={profile.certifications}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Attachments CVs={profile.cv_item} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <AppliedJobs />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <EYouthCertification />
            </CustomTabPanel>
          </Box>
        </main>
      </div>
    </div>
  );
}

export default UserProfile;
