import React, { useEffect, useState } from 'react';
import { Companies } from '../../components/Companies/Companies';
import line from "../../images/assets/allJobsUnderLine.svg";
import { getAllCompanies, getComapnyProfile, getUserProfile } from '../../Middleware/CandidateApis';
import styles from './AllCompanies.module.css';
import { getToken, getUserData } from '../../Middleware/Constans';
import { useNavigate } from 'react-router-dom';

function AllCompanies() {
  const [companyData, setComapnyData] = useState([]);
  useEffect(() => {
    getAllCompanies().then((res) => {
      // 
      setComapnyData(res.results);
    }).catch((error) => {
      
    });
  }, []);
  const userData = getUserData();
  const navigate = useNavigate();

  const token = getToken();
  useEffect(() => {
    if (userData.user_type === "company") {
      getComapnyProfile(userData.company_name).then((res) => {
        
        if (res.company_profile.completed === false) {
          window.location.href = (`/companyProfile/${userData.company_slug}/editProfile`);
        }
      }).catch(error => {
        console.error("Error fetching company profile:", error);
      });
    }
  }, [userData, navigate]);

  useEffect(() => {
    if (userData.user_type === "applicant") {
      getUserProfile(token).then((res) => {
        
        if (res.completed === false) {
          window.location.href = (`/user-profile/edit-profile`);
        }
      }).catch(error => {
        console.error("Error fetching company profile:", error);
      });
    }
  }, [userData, navigate]);




  return (
    <div>
      {/* <div className={styles.introComapnies}>
        <h1>Find Your <img src={backgroundImage} className={styles.imageCompany} />
        </h1>
        <span className='text-muted mt-3'>Find the dream companies you dream work for</span>
      </div> */}

      <div className={styles.allJobsHeader}>
        <div className="d-flex">
          <h2>Find your</h2>
          <div className="d-flex flex-column">
            <h4>dream company</h4>
            <img src={line} alt="under-line" />
          </div>
        </div>
        <h5>
          Build your future with your ideal company.
        </h5>
      </div>
      <Companies Sectiontitle="Recommended Companies" h2Title={styles.h2Title}
        subTitle="Based on your profile, company preferences, and recent activity"
        CompniesData={companyData} />
    </div>
  );
}

export default AllCompanies;