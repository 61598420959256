import React from 'react';
import styles from './ProfessionalInfo.module.css';

const CertificationsComponent = ({ certifications }) => {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
  };
  return (
    <div className='Certifications'>
      <h6 className={styles.textProfessional}>Certifications</h6>
      <ul className={styles.certList}>
        {certifications.map((cert, index) => (
          <li key={index} className={styles.certItem}>
            <h5 className={styles.certTitle}>{cert.title}</h5>
            <h6 className={styles.certDescription}>{cert.description}</h6>
            <span className={styles.certOrganization}>From: {cert.organization}</span>
            <span className={styles.certDate}>{formatDate(cert.date)}</span>
            {cert.link && (
              <a href={cert.link} target="_blank" rel="noopener noreferrer" className={styles.certLink}>
                View Certificate
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CertificationsComponent;