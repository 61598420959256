import React from 'react';
import styles from './JobCardDashboard.module.css';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import Swal from 'sweetalert2';
import defultImage from '../../../images/assets/logo.jpg';
import { getToken, WEBSITE_URL } from '../../../Middleware/Constans';

function JobCardDashboard(props) {
  const handleAddToPipeline = async (id) => {
    const token = getToken();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `JWT ${token}`);

    const raw = JSON.stringify({
      "status": "On Consideration"
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`https://hiringstg.eyouthlearning.com/job/job-application/${id}/`, requestOptions);
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Applicant status updated successfully.',
        });
        // Optionally, you can refresh the data or update the state to reflect the changes
      } else {
        const errorText = await response.json();
        throw new Error(errorText);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Failed to update status: ${error.message}`,
      });
    }
  };

  return (
    <div className={`${styles.cardJobDashboard} my-2`}>
      <div className={styles.cardJobDashboard2}>
        <div className={styles.userProfileImageContainer}>
          <img className={styles.userProfileImage} src={props.image ? `${WEBSITE_URL}${props.image}` : defultImage} alt="" />
        </div>
        <div className="d-flex flex-row">
          <div className="d-flex flex-column">
            <h5>{props.name}</h5>
            <span className="text-muted">{props.jobTitle}</span>
            <span>
              <LocationOnRoundedIcon className={styles.iconColors} />
              {props.location}
            </span>
            <span>
              <SchoolRoundedIcon className={styles.iconColors} />
              {props.university}
            </span>
            <span>
              <InsightsRoundedIcon className={styles.iconColors} />
              {props.experience}
            </span>
          </div>

          <div className='d-flex flex-column justify-content-between'>
            <button className={styles.buttonAddToPipline} onClick={() => handleAddToPipeline(props.id)}>
              <AddCircleOutlineRoundedIcon /> Add To Pipeline
            </button>
            <button className={styles.buttonViewProfile}>
              <a className='text-white' style={{ textDecoration: "none" }} href={`/job-dashboard/userprofile/${props.username}`}>
                View Profile
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobCardDashboard;
