import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./ProfessionalInfo.module.css";

const EducationModal = ({ open, handleClose, education, setEducation, handleSave }) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!education) {
      setEducation({
        university: '',
        college: '',
        degree: '',
        grade: '',
        fromm: '',
        to: '',
      });
    }
  }, [education, setEducation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEducation((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateFields(education);
    if (Object.keys(newErrors).length === 0) {
      handleSave(education);
    } else {
      setErrors(newErrors);
    }
  };

  const validateFields = (education) => {
    const errors = {};
    if (!education.university) errors.university = "University is required";
    if (!education.degree) errors.degree = "Degree is required";
    if (!education.fromm) errors.fromm = "Start date is required";
    if (!education.to) errors.to = "End date is required";
    return errors;
  };

  if (!education) return null;

  return (
    <Dialog sx={{
      "& .MuiPaper-root": {
        maxHeight: "60%", // Adjust the max height as needed
      },
    }} open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        {education.university ? 'Edit Education' : 'Add Education'}
      </DialogTitle>
      <DialogContent>
        <form className={styles.formSection} onSubmit={handleSubmit}>
          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>University *</h6>
            <TextField
              name="university"
              value={education.university || ''}
              onChange={handleChange}
              className={styles.txtField}
              error={!!errors.university}
              helperText={errors.university}
              fullWidth
            />
          </div>

          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>College</h6>
            <TextField
              name="college"
              value={education.college || ''}
              onChange={handleChange}
              className={styles.txtField}
              error={!!errors.college}
              helperText={errors.college}
              fullWidth
            />
          </div>

          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>Degree *</h6>
            <TextField
              name="degree"
              value={education.degree || ''}
              onChange={handleChange}
              className={styles.txtField}
              error={!!errors.degree}
              helperText={errors.degree}
              fullWidth
            />
          </div>

          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>Grade</h6>
            <TextField
              name="grade"
              value={education.grade || ''}
              onChange={handleChange}
              className={styles.txtField}
              error={!!errors.grade}
              helperText={errors.grade}
              fullWidth
            />
          </div>

          <div className="d-flex gap-3">
            <div className={styles.dateField}>
              <h6 className={styles.fieldsName}>Start Date *</h6>
              <TextField
                type="date"
                name="fromm"
                value={education.fromm || ''}
                onChange={handleChange}
                className={styles.dateInput}
                error={!!errors.fromm}
                helperText={errors.fromm}
                fullWidth
              />
            </div>

            <div className={styles.dateField}>
              <h6 className={styles.fieldsName}>End Date *</h6>
              <TextField
                type="date"
                name="to"
                value={education.to || ''}
                onChange={handleChange}
                className={styles.dateInput}
                error={!!errors.to}
                helperText={errors.to}
                fullWidth
              />
            </div>
          </div>

          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EducationModal;
