import GridViewIcon from "@mui/icons-material/GridView";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import Swal from "sweetalert2";
import Loading from "../../components/Loading/Loading";
import SimilarJobs from "../../components/SimilarJobs/SimilarJobs";
import editIcon from "../../images/assets/edit.svg";
import arrow from "../../images/assets/Icon.svg";
import { applyJob } from "../../Middleware/CandidateApis";
import { getSingleJob } from "../../Middleware/CompanyApis";
import { getToken, getUserData } from "../../Middleware/Constans";
import styles from "./JobDetails.module.css";

export const JobDetails = () => {
    const navigate = useNavigate();
    const { companyName, jobSlug } = useParams();
    const token = getToken();
    const [jobDetails, setJobDetails] = useState(null);
    const [formattedDate, setFormattedDate] = useState("");

    const userData = getUserData();

    const handleEditCompany = () => {
        navigate("/companyProfile/:id/editProfile");
    };

    useEffect(() => {
        const fetchJobDetails = async () => {
            if (!token) {
                console.error("No token found");
                return;
            }

            try {
                const res = await getSingleJob(token, companyName, jobSlug);
                // 
                setJobDetails(res);

                // Format the date
                const date = new Date(res.created);
                const formatted = date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
                setFormattedDate(formatted);
            } catch (error) {
                console.error("Failed to fetch job details:", error);
            }
        };

        fetchJobDetails();
    }, [companyName, jobSlug, token]);

    if (!jobDetails) {
        return <Loading />; // Add a loading state
    }

    const shareUrl = window.location.href; // Current URL

    const handleApplyJob = async (jobDetails) => {
        try {
            const token = getToken();
            const applicantId = getUserData(); // Replace with actual method to get applicant ID
            // 

            const result = await applyJob(token, applicantId.id, jobDetails.id);
            // 
            Swal.fire({
                title: "Good job!",
                text: "Application submitted successfully!",
                icon: "success",
            });
        } catch (error) {
            console.error("Error applying for job:", error);
            Swal.fire({
                title: "Error!",
                text: "You already applied for this job.",
                icon: "error",
            });
        }
    };

    // Extract job categories from job details
    const jobCategories = jobDetails.job_category.map(
        (category) => category.slug
    );

    return (
        <div className={styles.jobDetailsContainer}>
            <div className={styles.jobDetailsHead}>
                {userData.user_type === "company" &&
                    userData.company_name === companyName && (
                        <div className={styles.jobDetailNavigate}>
                            <button className={styles.addJobBtn} onClick={handleEditCompany}>
                                <img className="pe-2" src={editIcon} alt="edit" />
                                Edit Profile Details
                            </button>
                            <a
                                href={`/job-dashboard/${jobDetails.company.slug}/${jobDetails.slug}`}
                            >
                                {" "}
                                <button className={styles.dashboardBtn}>
                                    <GridViewIcon className="mx-1" sx={{ color: "#FFCF44" }} />{" "}
                                    Job Dashboard
                                </button>
                            </a>
                        </div>
                    )}
                <div className={styles.breadcrumbsContainer}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Home
                        </Link>
                        <Link underline="hover" href="/my-jobs">
                            MyJobs
                        </Link>
                        <h3>{jobDetails.slug}</h3>
                    </Breadcrumbs>
                </div>
                <div className={styles.jobCardDetails}>
                    <div className={styles.jobTitle}>
                        <img
                            src={jobDetails.company && jobDetails.company.logo}
                            alt="company-logo"
                            className={styles.companyLogo}
                        />
                        <div className="d-flex gap-2 flex-column">
                            <h2>{jobDetails.title}</h2>
                            <div className="d-flex gap-2 align-items-center flex-row">
                                <h5>{jobDetails.company && jobDetails.company.name}</h5>
                                <div className={styles.circleDot}></div>
                                <h5>
                                    {jobDetails.type_of_job_location &&
                                        jobDetails.type_of_job_location[0] &&
                                        jobDetails.type_of_job_location[0].name}
                                </h5>
                                <div className={styles.circleDot}></div>
                                <h5>
                                    {jobDetails.type_of_employment &&
                                        jobDetails.type_of_employment[0] &&
                                        jobDetails.type_of_employment[0].name}
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className={styles.shareAndApply}>
                        <div className={styles.shareButtons}>
                            <FacebookShareButton
                                url={shareUrl}
                                quote={`Apply for this job on EYouth platform: ${jobDetails.title}`}
                                hashtag="#JobOpportunity"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={shareUrl}
                                title={`Apply for this job on EYouth platform: ${jobDetails.title}`}
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <WhatsappShareButton
                                url={shareUrl}
                                title={`Apply for this job on EYouth platform: ${jobDetails.title}`}
                                separator=":: "
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <LinkedinShareButton
                                url={shareUrl}
                                title={`Apply for this job on EYouth platform: ${jobDetails.title}`}
                                summary={jobDetails.description}
                                source="EYouth Jobs"
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>
                        {/* Conditionally render the Apply button */}
                        {userData.user_type !== "company" && (
                            <button
                                className={styles.applyBtn}
                                onClick={() => handleApplyJob(jobDetails)}
                            >
                                Apply
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.jobDetailsInfo}>
                <main className={styles.mainInfo}>
                    <div className={styles.descriptionInfo}>
                        <h2>Description</h2>
                        <p>{jobDetails.job_description}</p>
                    </div>
                    <div className={styles.responsibilityInfo}>
                        <h2>Responsibilities</h2>
                        <ul>
                            {jobDetails.job_responsibility.map((responsibility) => (
                                <li key={responsibility.id}>{responsibility.description}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.whoURInfo}>
                        <h2>Who You Are</h2>
                        <ul>
                            {jobDetails.applicant_qualification.map((qualification) => (
                                <li key={qualification.id}>{qualification.description}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.toHaveInfo}>
                        <h2>Nice-To-Haves</h2>
                        <ul>
                            {jobDetails.applicant_nice_to_have_skill.map((skill) => (
                                <li key={skill.id}>{skill.description}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.benefitsInfo}>
                        <h2>Perks & Benefits</h2>
                        <p>This job comes with several perks and benefits</p>
                        <div className={styles.benefitsCards}>
                            {jobDetails.jobP_benefit.map((benefit) => (
                                <h5 key={benefit.id}>{benefit.description}</h5>
                            ))}
                        </div>
                    </div>
                </main>

                <aside className={styles.asideInfo}>
                    <div className={styles.aboutThisRole}>
                        <h2>About this role</h2>
                        {/* <div className={styles.appliedJobs}>
                            <h3>
                                <span>5 applied </span>of 10 capacity
                            </h3>
                            <div className="mt-3">
                                <LinearProgress variant="determinate" value={progress} />
                            </div>
                        </div> */}
                        <div className={styles.asideJobInfo}>
                            <h3>Apply Before</h3>
                            <span>{jobDetails.deadline}</span>
                        </div>
                        <div className={styles.asideJobInfo}>
                            <h3>Job Posted On</h3>
                            <span>{formattedDate}</span>
                        </div>
                        <div className={styles.asideJobInfo}>
                            <h3>Job Type</h3>
                            <span>
                                {jobDetails.type_of_employment &&
                                    jobDetails.type_of_employment[0] &&
                                    jobDetails.type_of_employment[0].name}
                            </span>
                        </div>
                        <div className={styles.asideJobInfo}>
                            <h3>Salary</h3>
                            <span>
                                {jobDetails.min_salary}-{jobDetails.max_salary} EGP
                            </span>
                        </div>
                    </div>
                    <div className={styles.categoriesInfo}>
                        <h2>Categories</h2>
                        <div className="d-flex gap-2 flex-wrap">
                            {jobDetails.job_category.map((category) => (
                                <h4 key={category.id}>{category.name}</h4>
                            ))}
                        </div>
                    </div>
                    <div className={styles.skillsInfo}>
                        <h2>Required Skills</h2>
                        <div className="d-flex gap-2 flex-wrap">
                            {jobDetails.job_skills.map((skill) => (
                                <h4 key={skill.id}>{skill.name}</h4>
                            ))}
                        </div>
                    </div>
                </aside>
            </div>
            <div className={styles.companyInfo}>
                <div className={styles.companyInfoContainer}>
                    <div className={styles.companyIntro}>
                        <div className={styles.companyTitle}>
                            <img
                                className={styles.companyLogo}
                                src={jobDetails.company && jobDetails.company.logo}
                                alt="company-logo"
                            />
                            <div className="d-flex flex-column">
                                <h2>{jobDetails.company && jobDetails.company.name}</h2>
                                <a
                                    href={jobDetails.company && jobDetails.company.website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Read more about{" "}
                                    {jobDetails.company && jobDetails.company.name}
                                    <img className="mx-3" src={arrow} alt="arrow" />
                                </a>
                            </div>
                        </div>
                        <p className="mt-3">
                            {jobDetails.company && jobDetails.company.about}
                        </p>
                    </div>
                    
                    <div className={styles.companyPhotos}>
                        {jobDetails.company && jobDetails.company.company_images.length > 0 && (
                            <div className={styles.imagesContainer}>
                                {jobDetails.company.company_images
                                    .slice()
                                    .reverse()
                                    .slice(0, 2)
                                    .map((image, index) => (
                                        <img
                                            key={image.image_link}
                                            src={image.image_link}
                                            alt={`company-${index + 1}`}
                                            className={index === 0 ? styles.leftImageElement : styles.rightImageElement}
                                        />
                                    ))}
                            </div>
                        )}
                    </div>

                </div>
            </div>
            <SimilarJobs title="Similar Jobs" jobCategories={jobCategories} />
        </div>
    );
};
