import React, { useEffect, useState } from "react";
import line from "../../images/assets/allJobsUnderLine.svg";
import college from "../../images/assets/college.svg";
import experience from "../../images/assets/experience.svg";
import location from "../../images/assets/location.svg";
import photo from "../../images/assets/logo.jpg";
import {
    filterForApplicants,
    getCategories,
} from "../../Middleware/CompanyApis";
import styles from "./Hunt.module.css";
import { getToken } from "../../Middleware/Constans";
import { useNavigate } from "react-router-dom";

// Mock data for dropdowns
const nationalities = [
    "Algeria",
    "Bahrain",
    "Comoros",
    "Djibouti",
    "Egypt",
    "Iraq",
    "Jordan",
    "Kuwait",
    "Lebanon",
    "Libya",
    "Mauritania",
    "Morocco",
    "Oman",
    "Palestine",
    "Qatar",
    "Saudi Arabia",
    "Somalia",
    "Sudan",
    "Syria",
    "Tunisia",
    "United Arab Emirates",
    "Yemen",
];
const gender = ["male", "Female"];
// const locations = ["on-site", "remote", "hybrid"];
const experienceLevels = ["1-3", "3-5", "5<"];

export default function Hunt() {
    const [categories, setCategories] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        categories: "",
        work_experience_years: "",
        country: "",
        location: "",
        gender: "",
        certified_EYouth: false,
    });
    const [applicants, setApplicants] = useState({
        count: 0,
        results: [],
        next: null,
        previous: null,
    });
    const token = getToken();
    const navigate = useNavigate();

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setSelectedFilters({
            ...selectedFilters,
            [name]: value,
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSelectedFilters({
            ...selectedFilters,
            [name]: checked,
        });
    };

    const removeFilter = (filter) => {
        setSelectedFilters({
            ...selectedFilters,
            [filter]: filter === "certified_EYouth" ? false : "",
        });
    };

    const clearAllFilters = () => {
        setSelectedFilters({
            categories: "",
            work_experience_years: "",
            country: "",
            location: "",
            gender: "",
            certified_EYouth: false,
        });
    };

    // Fetch categories
    useEffect(() => {
        async function fetchCategories() {
            try {
                const result = await getCategories(token);
                setCategories(result);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        }

        fetchCategories();
    }, [token]);

    // Fetch filtered applicants
    const fetchFilteredApplicants = async (url = null) => {
        try {
            const result = await filterForApplicants(token, selectedFilters, url);
            setApplicants(result);
        } catch (error) {
            console.error("Error fetching applicants:", error);
        }
    };

    useEffect(() => {
        fetchFilteredApplicants();
    }, [token, selectedFilters]);

    const renderSelectedFilters = () => {
        return Object.entries(selectedFilters).map(([key, value]) => {
            if (value) {
                return (
                    <div key={key} className={styles.selectedFilter}>
                        {key === "certified_EYouth" ? "Certified" : value}
                        <button onClick={() => removeFilter(key)}>x</button>
                    </div>
                );
            }
            return null;
        });
    };

    const isAnyFilterSelected = () => {
        return Object.values(selectedFilters).some((value) => value);
    };

    const handlePageChange = (url) => {
        fetchFilteredApplicants(url);
    };

    return (
        <div className={styles.huntContainer}>
            <div className={styles.huntHeader}>
                <div className="d-flex">
                    <h2>Find</h2>
                    <div className="d-flex flex-column">
                        <h4>Top Talent</h4>
                        <img src={line} alt="under-line" />
                    </div>
                </div>
                <h5>Recruit the best candidates for your organization.</h5>
            </div>

            <div className={`container-fluid ${styles.huntAndFiltersContainer}`}>
                <div>
                    <div className={styles.filtersContainer}>
                        <div className={styles.selectContainer}>
                            <select
                                name="categories"
                                id="categories"
                                className={styles.selectCategories}
                                value={selectedFilters.categories}
                                onChange={handleSelectChange}
                            >
                                <option value="" disabled>
                                    Categories
                                </option>
                                {categories.map((categories) => (
                                    <option key={categories.id} value={categories.title}>
                                        {categories.title}
                                    </option>
                                ))}
                            </select>
                            <select
                                name="work_experience_years"
                                id="work_experience_years"
                                className={styles.selectCategories}
                                value={selectedFilters.work_experience_years}
                                onChange={handleSelectChange}
                            >
                                <option value="" disabled>
                                    Experience level
                                </option>
                                {experienceLevels.map((level) => (
                                    <option value={level} key={level}>
                                        {level}
                                    </option>
                                ))}
                            </select>
                            <select
                                name="country"
                                id="country"
                                className={styles.selectCategories}
                                value={selectedFilters.country}
                                onChange={handleSelectChange}
                            >
                                <option value="" disabled>
                                    Country
                                </option>
                                {nationalities.map((country, index) => (
                                    <option key={index} value={country}>
                                        {country}
                                    </option>
                                ))}
                            </select>

                            <select
                                name="gender"
                                id="gender"
                                className={styles.selectCategories}
                                value={selectedFilters.gender}
                                onChange={handleSelectChange}
                            >
                                <option value="" disabled>
                                    Gender
                                </option>
                                {gender.map((gender, index) => (
                                    <option key={index} value={gender}>
                                        {gender}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.checkboxContainer}>
                            <input
                                type="checkbox"
                                id="certified_EYouth"
                                name="certified_EYouth"
                                className={styles.checkboxInput}
                                checked={selectedFilters.certified_EYouth}
                                onChange={handleCheckboxChange}
                            />
                            <label
                                htmlFor="certified_EYouth"
                                className={styles.checkboxLabel}
                            >
                                Certified By EYouth
                            </label>
                        </div>
                    </div>
                    <div className={styles.selectedFiltersContainer}>
                        {renderSelectedFilters()}
                        {isAnyFilterSelected() && (
                            <button
                                onClick={clearAllFilters}
                                className={styles.clearAllButton}
                            >
                                Clear All
                            </button>
                        )}
                    </div>
                </div>

                <div className={styles.candidatesContainer}>
                    <div className={styles.candidatesHeader}>
                        <h2>All Candidates</h2>
                        <h4>Showing {applicants.count} results</h4>
                    </div>
                    {applicants.results.length > 0 ? (
                        <div className={styles.candidatesList}>
                            {applicants.results.map(
                                (applicant) =>
                                    applicant.user && (
                                        <div
                                            key={applicant.user.email}
                                            className={styles.candidateCard}
                                            onClick={() =>
                                                navigate(`/job-dashboard/userprofile/${applicant.user.username}`)
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            <img
                                                className="rounded-circle"
                                                src={applicant.profile_image || photo}
                                                alt="candidate"
                                            />
                                            <div>
                                                <div className={styles.candidateInfo}>
                                                    <div className={styles.candidateName}>
                                                        <h2>{applicant.fullname}</h2>
                                                        <h4>{applicant.job_title || "Not specified"}</h4>
                                                    </div>
                                                    <div className={styles.candidateMoreInfo}>
                                                        <div className="d-flex">
                                                            <img src={location} alt="location" />
                                                            <h6>
                                                                {applicant.city
                                                                    ? `${applicant.city}, ${applicant.country}`
                                                                    : applicant.country}
                                                            </h6>
                                                        </div>
                                                        {applicant.education.length > 0 && (
                                                            <div className="d-flex">
                                                                <img src={college} alt="college" />
                                                                <h6>{applicant.education[0].college}</h6>
                                                            </div>
                                                        )}
                                                        <div className="d-flex">
                                                            <img src={experience} alt="experience" />
                                                            <h6>
                                                                {`Experience : ${applicant.work_experience_years} ` ||
                                                                    "No experience"}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                    ) : (
                        <p>No applicants found.</p>
                    )}
                    <div className={styles.paginationContainer}>
                        {applicants.previous && (
                            <button onClick={() => handlePageChange(applicants.previous)}>
                                Previous
                            </button>
                        )}
                        {applicants.next && (
                            <button onClick={() => handlePageChange(applicants.next)}>
                                Next
                            </button>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
}
