import { WEBSITE_URL } from "./Constans";

// Api for Company to get Jobs + filters
export async function getJobs(filters) {
  try {
    const myHeaders = new Headers();
    // myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const params = new URLSearchParams();

    if (filters.employment.length)
      params.append("type_of_employment__slug", filters.employment.join(","));
    if (filters.location.length)
      params.append("type_of_job_location__slug", filters.location.join(","));
    if (filters.category.length)
      params.append("job_category__slug", filters.category.join(","));
    if (filters.level.length)
      params.append("job_level__slug", filters.level.join(","));

    // Always include is_active=true
    params.append("is_active", "true");

    const queryString = params.toString() ? `?${params.toString()}` : "";
    const url = `${WEBSITE_URL}/job/job/${queryString}`;

    const res = await fetch(url, requestOptions);
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error fetching jobs:", error);
    throw error;
  }
}

export async function getJobsByCategory(token, jobCategories) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const params = new URLSearchParams();

    if (jobCategories.length) {
      params.append("job_category__slug", jobCategories.join(",")); // Ensure this is a comma-separated string
    }

    const queryString = params.toString() ? `?${params.toString()}` : "";
    // 
    const url = `${WEBSITE_URL}/job/job/${queryString}`;

    const res = await fetch(url, requestOptions);
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error fetching jobs by category:", error);
    throw error;
  }
}

// Api to get a single job details by companyName and jobSlug
export async function getSingleJob(token, companyName, jobSlug) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const res = await fetch(
      `${WEBSITE_URL}/job/job/${companyName}/${jobSlug}/`,
      requestOptions
    );

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    const result = await res.json();
    // 
    return result;
  } catch (error) {
    console.error("Error fetching job details:", error);
    throw error;
  }
}

// Api to create a new job
export async function postJob(token, jobData) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(jobData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(`${WEBSITE_URL}/job/job/`, requestOptions);
    const result = await res.json();

    return result;
  } catch (error) {
    console.error("Error posting job:", error);
    throw error;
  }
}
// Get a jobs for a specific company
export async function getJobsByCompany(token, companyName) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const res = await fetch(
      `${WEBSITE_URL}/job/job/${companyName}/`,
      requestOptions
    );

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    const result = await res.json();
    // 
    return result;
  } catch (error) {
    console.error("Error fetching job details:", error);
    throw error;
  }
}
// update Job Status for a specific company

export async function updateJobStatus(token, companyName, jobTitle, isActive) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      is_active: isActive,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      `${WEBSITE_URL}/job/job/${companyName}/${jobTitle}/`,
      requestOptions
    );

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    const result = await res.json();
    // 
    return result;
  } catch (error) {
    console.error("Error updating job status:", error);
    throw error;
  }
}

// Api to post a company request
export async function postCompanyRequest(
  fullname,
  phonenumber,
  work_email,
  name,
  company_size,
  website
) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      fullname: fullname,
      phonenumber: phonenumber,
      work_email: work_email,
      name: name,
      company_size: company_size,
      website: website,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      `${WEBSITE_URL}/company/companyrequest/`,
      requestOptions
    );
    const result = await res.json();
    if (!res.ok) {
      throw new Error(
        JSON.stringify(result) || "A company with this name already exists."
      );
    }
    return result;
  } catch (error) {
    console.error("Error posting company request:", error);
    throw error;
  }
}

//Api to get all categories for Hunt page
export async function getCategories() {
  try {
    const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const res = await fetch(
      `${WEBSITE_URL}/applicant/categories/`,
      requestOptions
    );
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
}

// Api to filter applicant on hunt page
export async function filterApplicants(token) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const res = await fetch(`${WEBSITE_URL}/applicant/filter/`, requestOptions);
    const result = await res.json();
    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }
    return result;
  } catch (error) {
    console.error("Error fetching applicants:", error);
    throw error;
  }
}

// Api to filter applicant on hunt page
export async function filterForApplicants(token, filters, url = null) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);

    const queryParameters = new URLSearchParams();

    for (const key in filters) {
      if (filters[key]) {
        queryParameters.append(key, filters[key]);
      }
    }

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const requestUrl = url || `${WEBSITE_URL}/applicant/filter/?${queryParameters.toString()}`;
    const res = await fetch(requestUrl, requestOptions);
    const result = await res.json();
    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }
    return result;
  } catch (error) {
    console.error("Error fetching applicants:", error);
    throw error;
  }
}
