import React from 'react'
import styles from "./Partners.module.css"
import imagesData1 from "../../images/images";



function Partners() {
    return (
        <div id={styles.partnersContainer}>
            {imagesData1.map((item, index) => (
                <div className={styles.imgContainer}>
                    <img className={styles.partnerImg}
                        key={index}
                        src={item.img}
                        alt={item.title}
                        loading="lazy"
                    />
                </div>
            ))}
        </div>
    )
}

export default Partners