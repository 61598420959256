import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import React from 'react';
import { WEBSITE_URL } from '../../../Middleware/Constans';
import styles from './Attachments.module.css';

function Attachments({ CVs }) {
  return (
    <div>
      <h5 className='mb-4'>Attachments</h5>
      {CVs.map((attachment, index) => (
        <div key={index} className={styles.attachmentContainer}>
          <div className={styles.attachment}>
            <h6 className={styles.attachmentTitle}>{attachment.short_description}</h6>
            <a
              href={`${WEBSITE_URL}${attachment.attach_file}`}
              download
              className={styles.attachmentLink}
            >
              <PictureAsPdfIcon className={styles.icon} />
              Download {attachment.short_description}
            </a>
          </div>
          {index < CVs.length - 1 && <hr className={styles.separator} />}
        </div>
      ))}
    </div>
  );
}

export default Attachments;
