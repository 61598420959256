import React from 'react';
import styles from './ProfessionalInfo.module.css';

const EducationComponent = ({ education }) => {
  const getYear = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };

  return (
    <div className='Education '>
      <h6 className={styles.textProfessional}>Education</h6>
      <ul className={styles.educationContainer}>
        {education && education.length > 0 ? (
          education.map((edu, index) => (
            <li key={index} className={`d-flex flex-column border my-1 p-3 ${styles.educationContent}`} >
              <h5 className={styles.edu}>{edu.university}</h5>
              <span>{edu.degree}</span>
              <span>{edu.college}</span>
              <span>From: {getYear(edu.fromm)} - To: {getYear(edu.to)}</span>
              <span>Grade: {edu.grade}</span>
            </li>
          ))
        ) : (
          <p>No education details available.</p>
        )}
      </ul>
    </div>
  );
};

export default EducationComponent;
