import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from "@mui/material";
import styles from "./ProfessionalInfo.module.css";

const CertificationModal = ({ open, handleClose, certification, setCertification, handleSave }) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!certification) {
      setCertification({
        title: "",
        description: "",
        organization: "",
        link: "",
        date: ""
      });
    }
  }, [certification, setCertification]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCertification((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateFields(certification);
    if (Object.keys(newErrors).length === 0) {
      handleSave(certification);
    } else {
      setErrors(newErrors);
    }
  };

  const validateFields = (certification) => {
    const errors = {};
    if (!certification.title) errors.title = "Title is required";
    if (!certification.organization) errors.organization = "Organization is required";
    if (!certification.date) errors.date = "Date is required";
    if (!certification.description) errors.description = "Description is required";
    if (!certification.link) errors.link = "Link is required";
    return errors;
  };

  if (!certification) return null;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        {certification.organization ? 'Edit Certification' : 'Add Certification'}
      </DialogTitle>
      <DialogContent>
        <form className={styles.formSection} onSubmit={handleSubmit}>
          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>Certification Title *</h6>
            <TextField
              name="title"
              value={certification.title || ''}
              onChange={handleChange}
              className={styles.txtField}
              error={!!errors.title}
              helperText={errors.title}
              fullWidth
            />
          </div>

          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>Organization *</h6>
            <TextField
              name="organization"
              value={certification.organization || ''}
              onChange={handleChange}
              className={styles.txtField}
              error={!!errors.organization}
              helperText={errors.organization}
              fullWidth
            />
          </div>

          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>Description *</h6>
            <TextField
              name="description"
              value={certification.description || ''}
              onChange={handleChange}
              className={styles.txtField}
              error={!!errors.description}
              helperText={errors.description}
              fullWidth
              multiline
              rows={4}
            />
          </div>

          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>Link *</h6>
            <TextField
              name="link"
              value={certification.link || ''}
              onChange={handleChange}
              className={styles.txtField}
              error={!!errors.link}
              helperText={errors.link}
              fullWidth
            />
          </div>

          <div className={styles.dateField}>
            <h6 className={styles.fieldsName}>Date *</h6>
            <TextField
              type="date"
              name="date"
              value={certification.date || ''}
              onChange={handleChange}
              className={styles.dateInput}
              error={!!errors.date}
              helperText={errors.date}
              fullWidth
            />
          </div>

          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CertificationModal;
