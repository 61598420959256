import React, { useState, useEffect } from 'react';
import styles from './PiplineCard.module.css';
import defultImage from '../../../images/assets/logo.jpg';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { fetchApplicantsByStatus, updateApplicantStatus } from '../../../Middleware/CandidateApis';
import { getToken } from '../../../Middleware/Constans';

const PiplineCard = ({ applicantsData, jobId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState('In consideration');
  const [filter, setFilter] = useState('All');
  const [candidates, setCandidates] = useState([]);
  const token = getToken();

  useEffect(() => {
    // Initial fetch of all applicants excluding 'Submitted' status
    const filteredApplicants = applicantsData.filter(applicant => applicant.status !== 'Submitted');
    setCandidates(filteredApplicants);
  }, [applicantsData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (newStatus, id) => {
    if (newStatus && id) {
      try {
        await updateApplicantStatus(token, id, newStatus === 'Unsuitable' ? 'Ignored' : newStatus);
        setCandidates(prevCandidates =>
          prevCandidates.map(candidate =>
            candidate.id === id ? { ...candidate, status: newStatus } : candidate
          )
        );
      } catch (error) {
        console.error('Failed to update status:', error);
      }
    }
    setAnchorEl(null);
  };

  const handleFilterChange = async (event) => {
    const selectedFilter = event.target.value;
    setFilter(selectedFilter);

    try {
      const result = await fetchApplicantsByStatus(token, jobId, selectedFilter === 'All' ? '' : selectedFilter);
      setCandidates(result.results);
    } catch (error) {
      console.error('Failed to fetch filtered applicants:', error);
    }
  };

  const filteredCandidates = Array.isArray(candidates) ? candidates.filter(candidate =>
    filter === 'All' || candidate.status === filter
  ) : [];

  return (
    <div className='d-flex'>
      <div className='flex-grow-1 '>
        {filteredCandidates.length === 0 ? (
          <div className={`${styles.cardJobDashboard} ${styles.fullHeight} ${styles.noCandidates}`}>
            <div>
              {filter === 'In consideration' && 'There are no candidates in consideration'}
              {filter === 'Accepted' && 'There are no accepted candidates'}
              {filter === 'Unsuitable' && 'There are no unsuitable candidates'}
            </div>
          </div>
        ) : (
          filteredCandidates.map(candidate => (
            <div key={candidate.id} className={styles.cardJobDashboard}>
              <div className={styles.cardJobDashboard2}>
                <div className={styles.userProfileImageContainer}>
                  <img className={styles.userProfileImage} src={candidate.applicant.profile_image ? `https://hiringstg.eyouthlearning.com${candidate.applicant.profile_image}` : defultImage} alt="Profile" />
                </div>
                <div className="d-flex flex-column ms-3">
                  <h5>{candidate.applicant.user.username}</h5>
                  <span className="text-muted">{candidate.applicant.user.email}</span>
                  <span><LocationOnRoundedIcon className={styles.iconColors} /> {`${candidate.applicant.country}, ${candidate.applicant.city}`}</span>
                  <span><SchoolRoundedIcon className={styles.iconColors} /> {candidate.applicant.education.length > 0 ? candidate.applicant.education[0].university : 'N/A'}</span>
                  <span><InsightsRoundedIcon className={styles.iconColors} /> {candidate.applicant.work_experience_years}</span>
                </div>
                <div className='d-flex flex-column align-items-end'>
                  <div className="d-flex justify-content-between">
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) => { handleClick(event); setStatus(candidate.status); }}
                      className={styles.buttonInConsideration}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      {candidate.status}
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={() => handleClose()}
                    >
                      <MenuItem onClick={() => handleClose('In consideration', candidate.id)}>In consideration</MenuItem>
                      <MenuItem onClick={() => handleClose('Unsuitable', candidate.id)}>Unsuitable</MenuItem>
                      <MenuItem onClick={() => handleClose('Accepted', candidate.id)}>Accepted</MenuItem>
                    </Menu>
                    {/* <button className={styles.buttonRemoveFromPipeline}>
                      Remove from pipeline
                    </button> */}
                  </div>
                  <div className="ms-3 mt-5">
                    <button className={styles.buttonViewProfile}>
                      <a className='text-white' style={{ textDecoration: "none" }} href={`/job-dashboard/userprofile/${candidate.applicant.user.username}`}>
                        View Profile
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className={styles.piplinesDivFilter}>
        My Pipelines
        <ul>
          <li>
            <input
              type="radio"
              value="All"
              checked={filter === 'All'}
              onChange={handleFilterChange}
            />
            <label>All</label>
          </li>
          <li>
            <input
              type="radio"
              value="In consideration"
              checked={filter === 'In consideration'}
              onChange={handleFilterChange}
            />
            <label>In consideration</label>
          </li>
          <li>
            <input
              type="radio"
              value="Accepted"
              checked={filter === 'Accepted'}
              onChange={handleFilterChange}
            />
            <label>Accepted</label>
          </li>
          <li>
            <input
              type="radio"
              value="Unsuitable"
              checked={filter === 'Unsuitable'}
              onChange={handleFilterChange}
            />
            <label>Unsuitable</label>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PiplineCard;
