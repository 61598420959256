import { Button, Grid, IconButton, Paper, TextField } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect } from 'react';
import styles from './PostJob.module.css';

const PerksAndBenefits = ({ formData, setFormData, setIsBenefitsComplete }) => {
  const handleChange = (e, index) => {
    const { value } = e.target;
    const newBenefits = [...formData.benefits];
    newBenefits[index].description = value;
    setFormData({ ...formData, benefits: newBenefits });
  };

  const handleAddBenefit = () => {
    setFormData({
      ...formData,
      benefits: [...formData.benefits, { description: '' }]
    });
  };

  const handleRemoveBenefit = (index) => {
    const newBenefits = [...formData.benefits];
    newBenefits.splice(index, 1);
    setFormData({ ...formData, benefits: newBenefits });
  };

  useEffect(() => {
    const allBenefitsFilled = formData.benefits.every(benefit => benefit.description.trim() !== '');
    setIsBenefitsComplete(allBenefitsFilled);
  }, [formData.benefits, setIsBenefitsComplete]);

  return (
    <Paper className={styles.paper}>
      <div className={`d-flex justify-content-between align-items-center ${styles.responsivePost} ${styles.ResponsivePostWidth}`}>
        <h5 className={styles.title}>
          Perks & Benefits
        </h5>
        <div className='d-flex flex-column w-75'>
          {formData.benefits.map((benefit, index) => (
            <Grid container spacing={1} key={index} alignItems="flex-end">
              <Grid item xs={10}>
                <TextField
                  label={`Benefit ${index + 1}`}
                  value={benefit.description}
                  onChange={(e) => handleChange(e, index)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveBenefit(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button

            variant="contained"
            color="primary"
            onClick={handleAddBenefit}
            startIcon={<AddIcon />}
            className="w-50"
          >
            Add Benefit
          </Button>
        </div>
      </div>
    </Paper>
  );
  
};

export default PerksAndBenefits;
