import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import JobSeeker from "../../components/JobSeeker/JobSeeker";
import signInImg from "../../images/assets/loginBG.jpeg";
import styles from "./SignIn.module.css";
import CompanyLogin from "../../components/CompanyLogin/CompanyLogin";
import { styled } from "@mui/material/styles";
import { getToken } from "../../Middleware/Constans";

const StyledTab = styled(Tab)(({ theme }) => ({
  color: "var(--main_color)",
  fontWeight: 600,
  fontSize: "1rem",
  whiteSpace: "nowrap", // Prevents text from wrapping
  minWidth: "auto", // Allows the tab to shrink to the content size
  "&.Mui-selected": {
    backgroundColor: "#E9EBFD",
    color: "var(--main_color)",
    borderBottom: "none",
  },
}));


function SignInSide() {
  const [value, setValue] = useState("applicant-login");
  const token = getToken()


  useEffect(() => {
      if (token) {
          window.location.href = ("/home")
      }

  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={styles.signInContainer}>
      <div className={styles.imgContainer}>
        <img src={signInImg} alt="Eyouth-logo" />
      </div>

      <div className={styles.formContainer}>
        <div className={styles.toggleContainer}>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <StyledTab value="applicant-login" label="Job Seeker" />
            <StyledTab value="company-login" label="Company" />
          </Tabs>
        </div>

        <div className={styles.tabsContent}>
          {value === "applicant-login" && <JobSeeker />}
          {value === "company-login" && <CompanyLogin />}
        </div>
      </div>
    </div>
  );
}

export default SignInSide;
