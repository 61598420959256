import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function PageNotFound() {
  const location = useLocation();
  // 
  useEffect(() => {
    document.title = "Hiring Portal - Page not found";
  }, []);
  return (
    <>
      <Container>
        <Box
          sx={{
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../images/404-Error.jpg")}
            alt="Not Found"
            width={"70%"}
          />
          <Box
            sx={{
              width: "70%",
              textAlign: "center",
              my: 1,
            }}
          >
            <p style={{ fontSize: "1.2rem" }}>
              The requested URL <strong>{location.pathname}</strong> was not
              found on this server, <Link to={"/"} > <strong style={{ color: "black" }}>Click here</strong></Link> to go back
              home
            </p>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default PageNotFound;
