import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "category",
  initialState: "",
  reducers: {
    changeCategory: (state, action) => {
      state = action.payload;
      return state;
    },
    clearCategory: (state) => {
      state = "";
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCategory, clearCategory } = categorySlice.actions;

export default categorySlice.reducer;
