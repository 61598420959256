import React from "react";
import style from "./HeroSection.module.css";
import line from "../../images/assets/line.svg"

function HeroSection() {

  return (
    <div className={`container-fluid ${style.heroSectionContainer}`}>
      <div className="container-fluid">
        <h2>Discover more than <span> A thousand  Jobs</span></h2>
        <img src={line} alt="under-line" />
        <h5># With <span>EYouth</span> Online Recruitment Platform</h5>
      </div>
    </div>
  );
}

export default HeroSection;
