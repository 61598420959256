import React, { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Alert from "@mui/material/Alert";
import styles from "./EditCompanyProfile.module.css";
import Overview from "../Overview/Overview";
import SocialLinks from "../SocialLinks/SocialLinks";
import CompanyPhotos from "../CompanyPhotos/CompanyPhotos";
import { getUserData } from '../../Middleware/Constans';
import { getComapnyProfile } from '../../Middleware/CandidateApis';

export default function EditCompanyProfile() {
    const [value, setValue] = useState("overView");
    const [showAlert, setShowAlert] = useState(false);
    const [isProfileCompleted, setIsProfileCompleted] = useState(false);
    const userData = getUserData();

    useEffect(() => {
        if (userData.user_type === "company") {
            getComapnyProfile(userData.company_name).then((res) => {
                // 
                if (res.company_profile.completed === false) {
                    setShowAlert(true);
                    setIsProfileCompleted(false);
                } else {
                    setIsProfileCompleted(true);
                }
            }).catch(error => {
                console.error("Error fetching company profile:", error);
            });
        }
    }, [userData]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.editCompanyContainer}>
            <h2>Settings</h2>

            {showAlert && (
                <Alert severity="warning" onClose={() => setShowAlert(false)}>
                    You should complete your profile to post new job or hunt applicant.
                </Alert>
            )}

            <div>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="company-profile-sections"
                >
                    <Tab className={styles.tab} value="overView" label="Overview" />
                    <Tab className={styles.tab} value="socialLinks" label="Social Links" />
                    <Tab className={styles.tab} value="photos" label="Photos" />
                </Tabs>
            </div>

            <div className={styles.tabsContent}>
                {value === "overView" && <Overview />}
                {value === "socialLinks" && <SocialLinks />}
                {value === "photos" && <CompanyPhotos />}
            </div>
        </div>
    );
}
