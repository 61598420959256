import React, { useEffect, useState } from "react";
import arrow from "../../images/assets/Icon.svg";
import { getAllJobs } from "../../Middleware/CandidateApis";
import Loading from "../Loading/Loading";
import JobCard from "./JobCard";
import styles from "./OpenJobs.module.css";
import { getUserData } from "../../Middleware/Constans";

const OpenJobs = ({ title, companyId }) => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);

    const userData = getUserData();

    useEffect(() => {
        getAllJobs().then((result) => {
            if (companyId) {
                const filteredJobs = result.results.filter(job => job.company.id === companyId);
                setJobs(filteredJobs);
            } else {
                setJobs(result.results);
            }
            setLoading(false);
        }).catch((error) => {
            console.error('Error fetching jobs:', error);
            setLoading(false);
        });
    }, [companyId]);

    if (loading) {
        return <Loading />;
    }

    // Determine the correct URL based on user type
    const jobsUrl = userData.user_type === "applicant" ? "/all-jobs" : "/my-jobs";

    return (
        <div className={styles.openJobsContainer}>
            <div className={styles.openJobsHeader}>
                <h2>{title}</h2>
                <a href={jobsUrl}>
                    Show my jobs
                    <img src={arrow} alt="arrow" />
                </a>
            </div>

            <div className={styles.openJobsCardContainer}>
                {jobs.length > 0 ? (
                    jobs.slice(0, 4).map((job) => (
                        <JobCard key={job.id} job={job} />
                    ))
                ) : (
                    <p>No jobs available at the moment. Please check back later.</p>
                )}
            </div>
        </div>
    );
};

export default OpenJobs;