import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { loginUser } from "../../Middleware/CandidateApis";
import CountrySelect from "./CountrySelect";
import styles from "./UserSignUp.module.css"; // Ensure styles are correctly loaded

export default function UserSignUp() {
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    // const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleErrors = (errors) => {
        let errorMessages = [];
        for (const [value] of Object.entries(errors)) {
            errorMessages = errorMessages.concat(value);
        }
        return errorMessages.join(" ");
    };

    const registrationValidationSchema = yup.object({
        fullname: yup.string("Enter your full name").required("Full name is required"),
        password: yup.string("Enter your password").required("Password is required"),
        confirmPassword: yup.string("Confirm your password")
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        country: yup.string("Enter your country").required("Country is required"),
        gender: yup.string("Enter your gender").required("Gender is required"),
        phonenumber: yup.string("Enter your phone number").required("Phone number is required"),
        username: yup.string("Enter your username").required("Username is required"),
        email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
    });

    const registrationFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            fullname: "",
            password: "",
            confirmPassword: "",
            email: "",
            username: "",
            country: "",
            gender: "",
            phonenumber: ""
        },
        validationSchema: registrationValidationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            const formdata = new FormData();
            formdata.append("username", values.username);
            formdata.append("fullname", values.fullname);
            formdata.append("password", values.password);
            formdata.append("email", values.email);
            formdata.append("country", values.country);
            formdata.append("gender", values.gender);
            formdata.append("phonenumber", values.phonenumber);

            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow"
            };

            try {
                const response = await fetch("https://hiringstg.eyouthlearning.com/applicant/register/", requestOptions);
                const result = await response.json();
                if (response.ok) {
                    const loginResponse = await loginUser(values.username, values.password);
                    if (loginResponse.token) {
                        const { token, refresh_token, expiration_time, user } = loginResponse;
                        localStorage.setItem("token", token);
                        localStorage.setItem("refresh_token", refresh_token);
                        localStorage.setItem("expired-time", expiration_time);
                        localStorage.setItem("user", JSON.stringify(user));
                        window.location.href = ("/user-profile/edit-profile");
                    } else {
                        setAlert(true);
                    }
                } else {
                    // Handling and displaying the errors
                    const errorMessage = handleErrors(result);
                    setAlertMessage(errorMessage || "An error occurred. Please try again.");
                    setAlert(true);
                }
            } catch (error) {
                console.error("Error registering user:", error);
                setAlertMessage("An error occurred. Please try again.");
                setAlert(true);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <div className={styles.signInContainer}>
            <div className={styles.formContainer}>
                <div className={styles.formHeader}>
                    <h2>User Details</h2>
                </div>
                <form
                    onSubmit={registrationFormik.handleSubmit}
                    className="w-100 d-flex justify-content-center flex-column align-items-center"
                >
                    <div className="d-flex gap-2 flex-column w-50 justify-content-center align-items-center all-form-res">
                        <div className="d-flex justify-content-start w-100">
                            <InputLabel color="warning" htmlFor="username" className={styles.inputLabel}>
                                Username
                            </InputLabel>
                        </div>
                        <TextField
                            placeholder="Enter your username"
                            focused
                            color="warning"
                            fullWidth
                            name="username"
                            id="username"
                            variant="standard"
                            value={registrationFormik.values.username}
                            onChange={registrationFormik.handleChange}
                            helperText={registrationFormik.touched.username && registrationFormik.errors.username}
                            error={registrationFormik.touched.username && Boolean(registrationFormik.errors.username)}
                        />
                        <div className="d-flex justify-content-start w-100">
                            <InputLabel color="warning" htmlFor="email" className={styles.inputLabel}>
                                Email
                            </InputLabel>
                        </div>
                        <TextField
                            placeholder="Enter your email"
                            focused
                            color="warning"
                            fullWidth
                            name="email"
                            id="email"
                            variant="standard"
                            value={registrationFormik.values.email}
                            onChange={registrationFormik.handleChange}
                            helperText={registrationFormik.touched.email && registrationFormik.errors.email}
                            error={registrationFormik.touched.email && Boolean(registrationFormik.errors.email)}
                        />
                        <div className="d-flex justify-content-start w-100">
                            <InputLabel color="warning" htmlFor="fullname" className={styles.inputLabel}>
                                Full Name
                            </InputLabel>
                        </div>
                        <TextField
                            placeholder="Enter your full name"
                            focused
                            color="warning"
                            fullWidth
                            autoFocus
                            name="fullname"
                            id="fullname"
                            variant="standard"
                            value={registrationFormik.values.fullname}
                            onChange={registrationFormik.handleChange}
                            helperText={registrationFormik.touched.fullname && registrationFormik.errors.fullname}
                            error={registrationFormik.touched.fullname && Boolean(registrationFormik.errors.fullname)}
                        />
                        <div className="d-flex justify-content-start w-100">
                            <InputLabel color="warning" htmlFor="password" className={styles.inputLabel}>
                                Password
                            </InputLabel>
                        </div>
                        <TextField
                            focused
                            color="warning"
                            fullWidth
                            name="password"
                            placeholder="Enter your password"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            value={registrationFormik.values.password}
                            onChange={registrationFormik.handleChange}
                            helperText={registrationFormik.touched.password && registrationFormik.errors.password}
                            error={registrationFormik.touched.password && Boolean(registrationFormik.errors.password)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className="d-flex justify-content-start w-100">
                            <InputLabel color="warning" htmlFor="confirmPassword" className={styles.inputLabel}>
                                Confirm Password
                            </InputLabel>
                        </div>
                        <TextField
                            focused
                            color="warning"
                            fullWidth
                            name="confirmPassword"
                            placeholder="Confirm your password"
                            type={showConfirmPassword ? "text" : "password"}
                            id="confirmPassword"
                            value={registrationFormik.values.confirmPassword}
                            onChange={registrationFormik.handleChange}
                            helperText={registrationFormik.touched.confirmPassword && registrationFormik.errors.confirmPassword}
                            error={registrationFormik.touched.confirmPassword && Boolean(registrationFormik.errors.confirmPassword)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className="d-flex justify-content-start w-100">
                            <InputLabel color="warning" htmlFor="gender" className={styles.inputLabel}>
                                Gender
                            </InputLabel>
                        </div>
                        <FormControl
                            fullWidth
                            variant="standard"
                            error={registrationFormik.touched.gender && Boolean(registrationFormik.errors.gender)}
                        >
                            <Select
                                id="gender"
                                name="gender"
                                value={registrationFormik.values.gender}
                                onChange={registrationFormik.handleChange}
                                label="Gender"
                                placeholder="Select your gender"
                                onBlur={registrationFormik.handleBlur}
                            >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                            </Select>
                            {registrationFormik.touched.gender && registrationFormik.errors.gender && (
                                <FormHelperText>{registrationFormik.errors.gender}</FormHelperText>
                            )}
                        </FormControl>
                        <div className="d-flex justify-content-start w-100">
                            <InputLabel color="warning" htmlFor="country" className={styles.inputLabel}>
                                Country
                            </InputLabel>
                        </div>
                        <CountrySelect
                            value={registrationFormik.values.country}
                            onChange={(value) => registrationFormik.setFieldValue('country', value)}
                            error={registrationFormik.touched.country && Boolean(registrationFormik.errors.country)}
                            helperText={registrationFormik.touched.country && registrationFormik.errors.country}
                        />
                        <div className="d-flex justify-content-start w-100">
                            <InputLabel color="warning" htmlFor="phonenumber" className={styles.inputLabel}>
                                Phone Number
                            </InputLabel>
                        </div>
                        <TextField
                            placeholder="Enter your phone number"
                            focused
                            color="warning"
                            fullWidth
                            name="phonenumber"
                            id="phonenumber"
                            variant="standard"
                            value={registrationFormik.values.phonenumber}
                            onChange={registrationFormik.handleChange}
                            helperText={registrationFormik.touched.phonenumber && registrationFormik.errors.phonenumber}
                            error={registrationFormik.touched.phonenumber && Boolean(registrationFormik.errors.phonenumber)}
                        />
                    </div>
                    <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                        <Button
                            style={{ marginTop: "20px" }}
                            className={styles.signUpBtn}
                            type="submit"
                            disabled={loading}
                            variant="contained"
                            color="primary"
                        >
                            {loading ? "Loading..." : "Sign Up"}
                        </Button>
                    </div>
                    {alert && (
                        <Stack sx={{ width: "50%" }} className="my-5" spacing={2}>
                            <Alert variant="filled" severity="error">
                                {alertMessage}
                            </Alert>
                        </Stack>
                    )}
                </form>
            </div>
        </div>
    );
}
