import { TextField, Button } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { updateContacts, getComapnyProfile, getUserProfile } from "../../Middleware/CandidateApis";
import { getToken, getUserData } from "../../Middleware/Constans";
import styles from "./Contacts.module.css";
import { useNavigate } from 'react-router-dom';

export default function Contacts() {
    const token = getToken();
    const userData = getUserData();
    const navigate = useNavigate();
    const [isFormComplete, setIsFormComplete] = useState(false);

    useEffect(() => {
        if (userData.user_type === "company") {
            getComapnyProfile(userData.company_name).then((res) => {
                if (res.company_profile.completed === false) {
                    window.location.href = (`/companyProfile/${userData.company_slug}/editProfile`);
                }
            }).catch(error => {
                console.error("Error fetching company profile:", error);
            });
        }
    }, [userData, navigate]);

    useEffect(() => {
        if (userData.user_type === "applicant") {
            getUserProfile(token).then((res) => {
                const profile = res.applicant_profile;
                
                formik.setValues({
                    phoneNumber: profile.phonenumber || "",
                    linkedin: profile.linkedin_link || "",
                    instagram: profile.instgram_link || "",
                    twitter: profile.twitter_link || "",
                    website: profile.website || "",
                });
            }).catch(error => {
                console.error("Error fetching user profile:", error);
            });
        }
    }, [token]);

    const URL =
        // eslint-disable-next-line
        /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

    const validationSchema = yup.object({
        phoneNumber: yup
            .string()
            .matches(/^[0-9]{11}$/, "Phone number must be exactly 11 digits")
            .nullable(),
        linkedin: yup.string().url("Invalid URL format").nullable(),
        instagram: yup.string().url("Invalid URL format").nullable(),
        twitter: yup.string().url("Invalid URL format").nullable(),
        website: yup.string().matches(URL, "Enter a valid url").nullable(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            phoneNumber: "",
            linkedin: "",
            instagram: "",
            twitter: "",
            website: "",
        },
        validationSchema: validationSchema,

        onSubmit: async (values, { resetForm }) => {
            try {
                const res = await updateContacts({
                    token,
                    phonenumber: values.phoneNumber,
                    linkedin_link: values.linkedin,
                    instgram_link: values.instagram,
                    twitter_link: values.twitter,
                    website: values.website,
                });
                resetForm();
                window.location.reload();
            } catch (error) {
                console.error(error);
            }
        },
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values) => {
            const isComplete = Object.values(values).some((value) => value !== "");
            setIsFormComplete(isComplete && Object.keys(formik.errors).length === 0);
        },
    });

    return (
        <div className={styles.contactsContainer}>
            <div className={styles.personalInfoHeader}>
                <h2>Basic Information</h2>
                <h3>This information you can update anytime.</h3>
            </div>

            <div className={styles.personalDetailsSection}>
                <h2>Contacts</h2>
                <form onSubmit={formik.handleSubmit} className={styles.formSection}>
                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Phone Number</h4>
                        <TextField
                            id="phoneNumber"
                            name="phoneNumber"
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="tel"
                            placeholder="01xxxxxxxxx"
                            fullWidth
                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                            className={styles.txtField}
                        />
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Linkedin Profile</h4>
                        <TextField
                            id="linkedin"
                            name="linkedin"
                            value={formik.values.linkedin}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="url"
                            placeholder="https://linkedin.com/in/yourprofile"
                            error={formik.touched.linkedin && Boolean(formik.errors.linkedin)}
                            helperText={formik.errors.linkedin}
                            fullWidth
                            className={styles.txtField}
                        />
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Instagram</h4>
                        <TextField
                            id="instagram"
                            name="instagram"
                            type="url"
                            placeholder="https://instagram.com/yourprofile"
                            value={formik.values.instagram}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.instagram && Boolean(formik.errors.instagram)}
                            helperText={formik.errors.instagram}
                            fullWidth
                            className={styles.txtField}
                        />
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Twitter</h4>
                        <TextField
                            id="twitter"
                            name="twitter"
                            type="url"
                            placeholder="https://twitter.com/yourprofile"
                            value={formik.values.twitter}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.twitter && Boolean(formik.errors.twitter)}
                            helperText={formik.errors.twitter}
                            fullWidth
                            className={styles.txtField}
                        />
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Your website</h4>
                        <TextField
                            id="website"
                            name="website"
                            placeholder="https://yourwebsite.com"
                            value={formik.values.website}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.website && Boolean(formik.errors.website)}
                            helperText={formik.errors.website}
                            fullWidth
                            className={styles.txtField}
                        />
                    </div>

                    <div className={styles.saveBtnSection}>
                        <Button type="submit" variant="contained" color="primary" disabled={!isFormComplete}>
                            Save Changes
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
