import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
    FormGroup,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { loginUser } from "../../Middleware/CandidateApis";
import styles from "./CompanyLogin.module.css";

export default function CompanyLogin() {
    const [alert, setAlert] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    // Schema for the Form
    const validationSchema = yup.object({
        username: yup.string("Enter your username").required("email required"),
        password: yup.string().required("password is required"),
    });

    useEffect(() => {
        handleUserTypeCheck();
    }, []);

    const handleUserTypeCheck = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user.user_type !== "company") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            // window.location.href = "/signin"; // Directing to the sign-in page
            setAlertMessage("If you are a Job Seeker, please log in from the Job Seeker section.");
        }
    };

    useEffect(() => {
        const timer = window.setTimeout(() => {
            setAlert(false);
        }, 5000);
        return () => {
            window.clearTimeout(timer);
        };
    }, [alert]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { username: "", password: "" },
        validationSchema: validationSchema,

        onSubmit: async (values) => {

            try {
                const res = await loginUser(values.username, values.password);
                if (res.token) {
                    const { token, refresh_token, expiration_time, user } = res;
                    localStorage.setItem("token", token);
                    localStorage.setItem("refresh_token", refresh_token);
                    localStorage.setItem("expired-time", expiration_time);
                    localStorage.setItem("user", JSON.stringify(res.user));
                    // localStorage.setItem("all-token-response", JSON.stringify(res));

                    // Check user type after storing user data
                    if (user.user_type !== "company") {
                        setAlertMessage("If you are a Job Seeker, please log in from the Job Seeker section.");
                        return;
                    }
                    window.location.href = "/home";
                } else {
                    setAlert(true);
                }
            } catch (error) {
                console.error("Login failed:", error);
                setAlert(true);
            }
        },
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className={styles.signInContainer}>
            <div className={styles.formContainer}>
                <div className={styles.formHeader}>
                    <h2>Welcome Back</h2>
                    <h5>Login with email</h5>
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    className="w-100 d-flex justify-content-center flex-column align-items-center"
                >
                    <div className="d-flex gap-2 flex-column w-50 justify-content-center align-items-center all-form-res ">
                        <div className="d-flex justify-content-start w-100">
                            <InputLabel
                                color="warning"
                                htmlFor="username"
                                className={styles.inputLabel}
                            >
                                Username

                            </InputLabel>
                        </div>
                        <TextField
                            focused
                            color="warning"
                            fullWidth
                            autoFocus
                            name="username"
                            id="username"
                            variant="standard"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            helperText={formik.touched.username && formik.errors.username}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <PersonIcon className="icon-color" />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <div className="d-flex justify-content-start w-100">
                            <InputLabel
                                color="warning"
                                htmlFor="password"
                                className={styles.inputLabel}
                            >
                                Password
                            </InputLabel>
                        </div>
                        <TextField
                            focused
                            color="warning"
                            fullWidth
                            autoFocus
                            name="password"
                            id="password"
                            variant="standard"
                            value={formik.values.password}
                            onChange={formik.handleChange("password")}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? (
                                                <Visibility className="icon-color" />
                                            ) : (
                                                <VisibilityOff className="icon-color" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="d-flex w-50 align-items-center justify-content-start my-2 form-mobile-res">
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox color="warning" />}
                                label="Remember me"
                            />
                        </FormGroup>
                    </div>

                    <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                        <button
                            onClick={handleUserTypeCheck}
                            className={styles.logInBtn}
                            type="submit"
                        >
                            Log In
                        </button>
                        {alertMessage && <div className={styles.alertMessage}>{alertMessage}</div>}
                    </div>
                    {alert && (
                        <Stack sx={{ width: "50%" }} className="my-5" spacing={2}>
                            <Alert variant="filled" severity="error">
                                No active account found with the given credentials
                            </Alert>
                        </Stack>
                    )}
                </form>
            </div>
        </div>
    );
}
