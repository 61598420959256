import React, { useEffect, useState } from "react";
import {
  TextField,
  Radio,
  FormControlLabel,
  Grid,
  Paper,
  RadioGroup,
} from "@mui/material";
import Select from "react-select";
import styles from "./PostJob.module.css";

const JobInformation = ({
  formData,
  setFormData,
  handleNext,
  employmentTypes,
  jobLocations,
  categories,
  skills,
  jobLevels,
}) => {
  const [salaryRange, setSalaryRange] = useState([
    formData.minSalary || 0,
    formData.maxSalary || "",
  ]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      minSalary: salaryRange[0],
      maxSalary: salaryRange[1],
    }));
  }, [salaryRange, setFormData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEmploymentChange = (e) => {
    const employmentId = parseInt(e.target.value, 10);
    setFormData((prevData) => ({
      ...prevData,
      typeOfEmployment: [employmentId], // Ensure it's an array
    }));
  };

  const handleLocationChange = (e) => {
    const locationId = parseInt(e.target.value, 10);
    setFormData((prevData) => ({
      ...prevData,
      location: [locationId], // Ensure it's an array
    }));
  };

  const handleInputChange = (index) => (event) => {
    const newSalaryRange = [...salaryRange];
    newSalaryRange[index] =
      event.target.value === "" ? "" : Number(event.target.value);
    setSalaryRange(newSalaryRange);
  };

  const handleBlur = () => {
    if (salaryRange[0] < 0) {
      setSalaryRange([0, salaryRange[1]]);
    } else if (salaryRange[0] > salaryRange[1] && salaryRange[1] !== "") {
      setSalaryRange([salaryRange[1], salaryRange[1]]);
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setFormData((prevData) => ({
      ...prevData,
      categories: selectedIds,
    }));
  };

  const handleSkillChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setFormData((prevData) => ({
      ...prevData,
      requiredSkills: selectedIds,
    }));
  };

  const handleLevelChange = (selectedOption) => {
    const selectedId = selectedOption ? selectedOption.value : null;
    setFormData((prevData) => ({
      ...prevData,
      jobLevel: selectedId,
    }));
  };

  const today = new Date().toISOString().split("T")[0];
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;

    if (selectedDate < today) {
      setFormData((prevData) => ({
        ...prevData,
        applyBefore: today, // Reset to today if the date is in the past
      }));
      alert("Please select a date that is today or in the future.");
    } else {
      setFormData((prevData) => ({
        ...prevData,
        applyBefore: selectedDate,
      }));
    }
  };

  return (
    <Paper className={styles.paper}>
      <div className={styles.basicInfoSec}>
        <h2 className={styles.title}>Basic Information</h2>
        <p className={styles.subTitle}>
          This information will be displayed publicly
        </p>
      </div>
      <Grid container spacing={5}>
        <Grid item xs={10} className={styles.textField}>
          <div className={`d-flex justify-content-between ${styles.responsivePost}`}>
            <div>
              <h5 className={styles.title}>Job Title</h5>
              <p className={styles.subTitle}>
                Job titles must describe one position
              </p>
            </div>
            <TextField
              label="Job Title"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
              helperText="e.g., Software Engineer"
              FormHelperTextProps={{ className: styles.helperText }}
            />
          </div>
        </Grid>
        <hr />
        <Grid item xs={8} className={styles.formGroup}>
          <div className={`d-flex justify-content-between ${styles.responsivePost}`}>
            <div>
              <h5 className={styles.title}>Type of Employment</h5>
              <p className={styles.subTitle}>
                You can select multiple types of employment
              </p>
            </div>
            <RadioGroup
              column
              value={formData.typeOfEmployment[0]}
              onChange={handleEmploymentChange}
            >
              {employmentTypes.map((type) => (
                <FormControlLabel
                  key={type.id}
                  value={type.id}
                  control={<Radio />}
                  label={type.name}
                  className={styles.checkboxLabel}
                />
              ))}
            </RadioGroup>
          </div>
        </Grid>
        <hr />
        <Grid item xs={8} className={styles.formGroup}>
          <div className={`d-flex justify-content-between ${styles.responsivePost}`}>
            <div>
              <h5 className={styles.title}>Location</h5>
              <p className={styles.subTitle}>
                You can select multiple types of employment
              </p>
            </div>
            <RadioGroup
              className="me-5"
              column
              value={formData.location[0]}
              onChange={handleLocationChange}
            >
              {jobLocations.map((location) => (
                <FormControlLabel
                  key={location.id}
                  value={location.id}
                  control={<Radio />}
                  label={location.name}
                  className={styles.checkboxLabel}
                />
              ))}
            </RadioGroup>
          </div>
        </Grid>
        <hr />
        <Grid item xs={10} className={styles.textFieldForSalary}>
          <div className={`d-flex justify-content-between ${styles.responsivePost}`}>
            <div className={` ${styles.responsiveDiv}`}>
              <h5 className={styles.title}>Salary</h5>
              <p className={styles.subTitle}>
                Please specify the estimated salary range for the role. You can
                leave this blank.
              </p>
            </div>
            <div className={styles.salarySelection}>
              <TextField
                label="Min Salary"
                value={salaryRange[0]}
                className={styles.salaryInput}
                onChange={handleInputChange(0)}
                onBlur={handleBlur}
                inputProps={{
                  step: 1000,
                  min: 0,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
              <TextField
                label="Max Salary"
                value={salaryRange[1]}
                className={styles.salaryInput}
                onChange={handleInputChange(1)}
                onBlur={handleBlur}
                inputProps={{
                  step: 1000,
                  min: 0,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={10} className={styles.textField}>
          <div className={`d-flex justify-content-between ${styles.responsivePost}`}>
            <div className={` ${styles.responsiveDiv}`}>
              <h5 className={styles.title}>Categories</h5>
              <p className={styles.subTitle}>
                You can select multiple job categories
              </p>
            </div>
            <div className={styles.SelectResponsive}>
              <Select
                isMulti
                name="categories"
                options={categories.map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleCategoryChange}
                value={categories
                  .filter((category) =>
                    formData.categories.includes(category.id)
                  )
                  .map((category) => ({
                    value: category.id,
                    label: category.name,
                  }))}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={10} className={styles.textField}>
          <div className={`d-flex justify-content-between ${styles.responsivePost}`}>
            <div className={`${styles.responsiveDiv}`}>
              <h5 className={styles.title}>Required Skills</h5>
              <p className={styles.subTitle}>Add required skills for the job</p>
            </div>
            <div className={styles.SelectResponsive}>
              <Select
                isMulti
                name="requiredSkills"
                options={skills.map((skill) => ({
                  value: skill.id,
                  label: skill.name,
                }))}
                className="basic-multi-select w-100"
                classNamePrefix="select"
                onChange={handleSkillChange}
                value={skills
                  .filter((skill) => formData.requiredSkills.includes(skill.id))
                  .map((skill) => ({
                    value: skill.id,
                    label: skill.name,
                  }))}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={10} className={styles.textField}>
          <div className={`d-flex justify-content-between ${styles.responsivePost}`}>
            <div className={`${styles.responsiveDiv}`}>
              <h5 className={styles.title}>Job Level</h5>
              <p className={styles.subTitle}>
                Please select the job level for the position
              </p>
            </div>
            <div className={styles.SelectResponsive}>
              <Select
                name="jobLevel"
                options={jobLevels.map((level) => ({
                  value: level.id,
                  label: level.name,
                }))}
                className="basic-single-select w-100"
                classNamePrefix="select"
                onChange={handleLevelChange}
                value={jobLevels
                  .filter((level) => level.id === formData.jobLevel)
                  .map((level) => ({
                    value: level.id,
                    label: level.name,
                  }))}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={10} className={styles.textFieldApply}>
          <div className={`d-flex justify-content-between ${styles.responsivePost}`}>
            <div className={`${styles.responsiveDiv}`}>
              <h5 className={styles.title}>Apply Before</h5>
            </div>
            <div className={styles.SelectResponsive}>
              <TextField
                className="w-100"
                name="applyBefore"
                type="date"
                value={formData.applyBefore}
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: today, // Restrict past dates
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JobInformation;
