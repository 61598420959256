import { createSlice } from "@reduxjs/toolkit";

export const tokenSlice = createSlice({
  name: "token",
  initialState: "",
  reducers: {
    changeToken: (state, action) => {
      state = action.payload;
      return state;
    },
    clearToken: (state) => {
      state = "";
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeToken, clearToken } = tokenSlice.actions;

export default tokenSlice.reducer;
