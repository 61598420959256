import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, IconButton, InputAdornment, InputLabel, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { loginUser } from "../../Middleware/CandidateApis";
import { getUserData } from '../../Middleware/Constans';
import styles from "./CompanySignUp.module.css";

export default function CompanySignUp() {
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [step, setStep] = useState(1);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Schema for the initial email form
  const emailValidationSchema = yup.object({
    email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
  });

  // Schema for the registration form
  const registrationValidationSchema = yup.object({
    username: yup
      .string("Enter your username")
      .min(4, "Username must be at least 4 characters")
      .required("Username is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .required("Password is required"),
    confirmPassword: yup
      .string("Confirm your password")
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const emailFormik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "" },
    validationSchema: emailValidationSchema,

    onSubmit: async (values) => {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("email", values.email);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      try {
        const response = await fetch("https://hiringstg.eyouthlearning.com/company/check_company_email/", requestOptions);
        const result = await response.json();
        // 
        if (response.ok) {
          if (result.company.approved === false) {
            setAlertMessage("Your company is not approved yet.");
            setAlert(true);
          } else {
            setCompanyDetails(result.company);
            setStep(2);
          }
        } else {
          setAlertMessage(result.error || "An error occurred. Please try again.");
          setAlert(true);
        }
      } catch (error) {
        console.error("Error checking email:", error);
        setAlertMessage("An error occurred. Please try again.");
        setAlert(true);
      } finally {
        setLoading(false);
      }
    },
  });

  const registrationFormik = useFormik({
    enableReinitialize: true,
    initialValues: { username: "", password: "", confirmPassword: "" },
    validationSchema: registrationValidationSchema,

    onSubmit: async (values) => {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("username", values.username);
      formdata.append("password", values.password);
      formdata.append("email", companyDetails.work_email);
      formdata.append("company_name", companyDetails.name);
      formdata.append("role", "Company_Admin");

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      try {
        const response = await fetch("https://hiringstg.eyouthlearning.com/company/register/", requestOptions);
        const result = await response.json();
        if (response.ok) {
          // After successful registration, log in the user
          const loginResponse = await loginUser(values.username, values.password);
          if (loginResponse.token) {
            const { token, refresh_token, expiration_time, user } = loginResponse;
            localStorage.setItem("token", token);
            localStorage.setItem("refresh_token", refresh_token);
            localStorage.setItem("expired-time", expiration_time);
            localStorage.setItem("user", JSON.stringify(user));
            // localStorage.setItem("all-token-response", JSON.stringify(loginResponse));

            // Check user type after storing user data
            if (user.user_type !== "company") {
              setAlertMessage("If you are a Job Seeker, please log in from the Job Seeker section.");
              setLoading(false);
              return;
            }
            const userSlug = getUserData();
            window.location.href = `/companyProfile/${userSlug.company_slug}/editProfile`;
          } else {
            setAlert(true);
          }
        } else {
          setAlertMessage(result.error || "An error occurred. Please try again.");
          setAlert(true);
        }
      } catch (error) {
        console.error("Error registering company:", error);
        setAlertMessage("An error occurred. Please try again.");
        setAlert(true);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className={styles.signInContainer}>
      <div className={styles.formContainer}>
        {step === 1 ? (
          <>
            <div className={styles.formHeader}>
              <h2>Welcome</h2>
              <h5>Check your company email</h5>
            </div>
            <form
              onSubmit={emailFormik.handleSubmit}
              className="w-100 d-flex justify-content-center flex-column align-items-center"
            >
              <div className="d-flex gap-2 flex-column m-3 w-50 justify-content-center align-items-center all-form-res">
                <div className="d-flex justify-content-start w-100">
                  <InputLabel
                    color="warning"
                    htmlFor="email"
                    className={styles.inputLabel}
                  >
                    Email Address
                  </InputLabel>
                </div>
                <TextField
                  className="my-5"
                  placeholder="example.comapnyname.com"
                  focused
                  color="warning"
                  fullWidth
                  autoFocus
                  name="email"
                  id="email"
                  variant="standard"
                  value={emailFormik.values.email}
                  onChange={emailFormik.handleChange}
                  helperText={emailFormik.touched.email && emailFormik.errors.email}
                  error={emailFormik.touched.email && Boolean(emailFormik.errors.email)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PersonIcon className="icon-color" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                <Button
                  className={styles.logInBtn}
                  type="submit"
                  disabled={loading}
                  variant="contained"
                  color="primary"
                >
                  {loading ? "Loading..." : "Check Email"}
                </Button>
                {alert && (
                  <Stack sx={{ width: "50%" }} spacing={1}>
                    <Alert variant="filled" severity="error">
                      {alertMessage}
                    </Alert>
                    <p>
                      To submit your request, please click <a href="/partner-request" target="_blank" rel="noopener noreferrer"> Here.  </a>
                    </p>
                  </Stack>
                )}
              </div>

            </form>
          </>
        ) : (
          <>
            <div className={styles.formHeader}>
              <h2>Company Details</h2>
            </div>
            <div className="w-100 d-flex flex-column align-items-center">
              <div className="d-flex gap-2 flex-column m-3 w-50 justify-content-center align-items-center all-form-res">
                <div className="d-flex justify-content-start w-100">
                  <InputLabel color="warning" htmlFor="company_name" className={styles.inputLabel}>
                    Company Name
                  </InputLabel>
                </div>
                <TextField
                  focused
                  color="warning"
                  fullWidth
                  id="company_name"
                  variant="standard"
                  value={companyDetails.name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
            <div className="w-100 d-flex flex-column align-items-center">
              <div className="d-flex gap-2 flex-column m-3 w-50 justify-content-center align-items-center all-form-res">
                <div className="d-flex justify-content-start w-100">
                  <InputLabel color="warning" htmlFor="company_name" className={styles.inputLabel}>
                    Work Email
                  </InputLabel>
                </div>
                <TextField
                  focused
                  color="warning"
                  fullWidth
                  id="company_name"
                  variant="standard"
                  value={companyDetails.work_email}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
            <form
              onSubmit={registrationFormik.handleSubmit}
              className="w-100 d-flex justify-content-center flex-column align-items-center"
            >
              <div className="d-flex gap-2 flex-column m-3 w-50 justify-content-center align-items-center all-form-res">
                <div className="d-flex justify-content-start w-100">
                  <InputLabel color="warning" htmlFor="username" className={styles.inputLabel}>
                    Username
                  </InputLabel>
                </div>
                <TextField
                  focused
                  color="warning"
                  fullWidth
                  name="username"
                  id="username"
                  variant="standard"
                  value={registrationFormik.values.username}
                  onChange={registrationFormik.handleChange}
                  helperText={registrationFormik.touched.username && registrationFormik.errors.username}
                  error={registrationFormik.touched.username && Boolean(registrationFormik.errors.username)}
                />
                <div className="d-flex m-3 justify-content-start w-100">
                  <InputLabel color="warning" htmlFor="password" className={styles.inputLabel}>
                    Create Your Password
                  </InputLabel>
                </div>
                <TextField
                  focused
                  color="warning"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  variant="standard"
                  value={registrationFormik.values.password}
                  onChange={registrationFormik.handleChange}
                  helperText={registrationFormik.touched.password && registrationFormik.errors.password}
                  error={registrationFormik.touched.password && Boolean(registrationFormik.errors.password)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="d-flex m-3 justify-content-start w-100">
                  <InputLabel color="warning" htmlFor="confirmPassword" className={styles.inputLabel}>
                    Confirm Password
                  </InputLabel>
                </div>
                <TextField
                  focused
                  color="warning"
                  fullWidth
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  variant="standard"
                  value={registrationFormik.values.confirmPassword}
                  onChange={registrationFormik.handleChange}
                  helperText={registrationFormik.touched.confirmPassword && registrationFormik.errors.confirmPassword}
                  error={registrationFormik.touched.confirmPassword && Boolean(registrationFormik.errors.confirmPassword)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                <Button
                  className={styles.logInBtn}
                  type="submit"
                  disabled={loading}
                  variant="contained"
                  color="primary"
                >
                  {loading ? "Loading..." : "Register"}
                </Button>
              </div>
              {alert && (
                <Stack sx={{ width: "50%" }} className="my-5" spacing={2}>
                  <Alert variant="filled" severity="error">
                    {alertMessage}
                  </Alert>
                </Stack>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  );
}
