import React from "react";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import countries from "./countries";

const CountrySelect = ({ value, onChange, error, helperText }) => {
  return (
    <FormControl
      fullWidth
      variant="standard"
      error={error}
    >
      <Autocomplete
        id="country-select"
        options={countries}
        autoHighlight
        getOptionLabel={(option) => option.label}
        value={value ? countries.find(country => country.code === value) : null}
        onChange={(event, newValue) => {
          onChange(newValue ? newValue.code : '');
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a country"
            variant="standard"
            error={error}
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CountrySelect;
