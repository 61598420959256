import { createSlice } from "@reduxjs/toolkit";

export const rejectedSlice = createSlice({
  name: "rejectedSlice",
  initialState: [],
  reducers: {
    SET_REJECTED: (state, action) => {
      state = action.payload;
      return state;
    },
    ADD_REJECTED: (state, action) => {
      const found = state.find(
        (ele) => ele.username === action.payload.username
      );
      if (!found) {
        state.push(action.payload);
      }
      return state;
    },
    REMOVE_REJECTED: (state, action) => {
      return state.filter(
        (element) => element.username !== action.payload.username
      );
    },
  },
});

export const {
  REMOVE_REJECTED,
  ADD_REJECTED,
  SET_REJECTED,
} = rejectedSlice.actions;
export default rejectedSlice.reducer;
