import React from 'react';
import styles from './PersonalInformation.module.css';
function PersonalInformation(props) {
  return (
    <>
      <h5 className='mb-4'>Personal Info</h5>
      <div className='d-flex justify-content-between'>
        <div>
          <div className='mb-3'>
            <span className='text-muted'>Full name </span>
            <h6>
              {props.FullName ? (
                props.FullName
              ) : (
                <>
                  <span className={styles.NoDataStyle}>No Data Found </span>
                </>
              )}
            </h6>
          </div>
          <div className='my-3'>
            <span className='text-muted'>Date of Birth </span>
            <h6>
              {props.dateOfBirth ? (
                props.dateOfBirth
              ) : (
                <>
                  <span className={styles.NoDataStyle}>No Data Found</span>
                </>
              )}
            </h6>
          </div>
          <div className='my-3'>
            <span className='text-muted'>Address </span>
            <h6>
              {props.street} , {props.city} , {props.country}
            </h6>
          </div>
        </div>
        <div>
          <div className='mb-3'>
            <span className='text-muted'>Nationality </span>
            <h6>
              {props.nationality ? (
                props.nationality
              ) : (
                <>
                  <span className={styles.NoDataStyle}>No Data Found</span>
                </>
              )}
            </h6>
          </div>
          <div className='my-3'>
            <span className='text-muted'>Gender </span>
            <h6>
              {props.gender ? (
                props.gender
              ) : (
                <>
                  <span className={styles.NoDataStyle}>set your Gender</span>
                </>
              )}
            </h6>
          </div>
          <div className='my-3'>
            <span className='text-muted'>Military status </span>
            <h6>
              {props.military_status ? (
                props.military_status
              ) : (
                <>
                  <span className={styles.NoDataStyle}>No Data Found</span>
                </>
              )}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalInformation;
