import { Button, createTheme, Grid, ThemeProvider } from "@mui/material";
import React from "react";
// import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ListItemText from "@mui/material/ListItemText";
import { useSelector } from "react-redux";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../config";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2575FC",
    },
    secondary: {
      main: "#FDAD10",
    },
    text: {
      main: "#7A7A7A",
    },
    accent: {
      main: "#61CE70",
    },
  },
  typography: {
    h1: {
      fontSize: "64px",
      color: "white",
    },
    h2: {
      fontSize: "28px",
      color: "white",
    },
    h4: {
      fontWeight: "100",
      color: "text.main",
    },
    h5: {
      fontWeight: "100",
      color: "text.main",
    },
  },
});

function VacancyDashboard() {
  const state = useSelector((state) => state);
  const loggedUser = useSelector((state) => state.loggedUserReducer);
  const navigate = useNavigate();
  const [vacancy, setVacancy] = React.useState([]);
  const { id } = useParams();
  // const candidate = useSelector((state) => state.candidates.value);
  // const [search, setSearch] = React.useState("");

  // const filteredCandidates = candidate.filter((val) => {
  //   if (search === "") {
  //     return candidate;
  //   } else if (val.name.toLowerCase().includes(search.toLocaleLowerCase())) {
  //     return val;
  //   }
  // });

  // const handleInput = (e) => {
  //   setSearch(e.target.value);
  // };

  React.useEffect(() => {
    document.title = "Hiring Portal-Vacancy Dashboard";
    if (state.loggedUserReducer.token === undefined) navigate("/signin");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/vacancy/myvacancies/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // 
        setVacancy(result.data[0]);
      })
      .catch((error) => console.error(error));
  }, []);

  // if (candidate && candidate.length !== 0) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: "#F7F7F7", minHeight: "100vh" }}>
        <Grid
          container
          direction="row"
          justifyContent="left"
          sx={{ mx: "auto", py: 1, px: 3 }}
        >
          <Grid item sx={{ fontSize: "2rem" }}>
            {vacancy.title}
          </Grid>
          <Grid item sx={{ fontSize: "2rem", ml: 1 }}>
            <Link
              to={`/edit-vacancy/${vacancy._id}`}
              style={{ textDecorationLine: "none", color: "navy" }}
            >
              <EditIcon />
            </Link>
          </Grid>
          <Grid item sx={{ fontSize: "2rem", width: "100%" }}>
            <ListItemText
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {vacancy.location}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item sx={{ fontSize: "2rem", width: "100%" }}>
            <Link to={`/vacancy-dashboard/${id}/talent-pool`}>
              <Button>Talent Pool</Button>
            </Link>
            <Link to={`/vacancy-dashboard/${id}/pipelines`}>
              <Button>Pipelines</Button>
            </Link>
          </Grid>
        </Grid>
        <Outlet />
      </Box>
    </ThemeProvider>
  );
  // }

  // return (
  //   <Box
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     height="100vh"
  //   >
  //     <CircularProgress size={100} />
  //   </Box>
  // );
}

export default VacancyDashboard;
