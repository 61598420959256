import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Alert from "@mui/material/Alert";
import CV from "../../../components/CV/CV";
import Contacts from "../../../components/Contacts/Contacts";
import { PersonalInfo } from "../../../components/PersonalInfo/PersonalInfo";
import ProfessionalInfo from "../../../components/ProfessionalInfo/ProfessionalInfo";
import styles from "./EditProfile.module.css";
import { getUserProfile } from "../../../Middleware/CandidateApis";
import { getToken } from "../../../Middleware/Constans";

export const EditProfile = () => {
  const [value, setValue] = useState("one");
  const [showAlert, setShowAlert] = useState(false);
  const token = getToken();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const result = await getUserProfile(token);
        if (result.completed === false) {
          setShowAlert(true);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [token]);

  return (
    <div className={styles.editProfileContainer}>
      {showAlert && (
        <Alert severity="warning" onClose={() => setShowAlert(false)}>
          You should complete your profile to find new job .
        </Alert>
      )}
      <div className={styles.editHeader}>
        <h2>Edit Profile</h2>
        <Link to="/user-profile">
          <button>Back to Profile page</button>
        </Link>
      </div>

      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="profile-sections"
        >
          <Tab className={styles.tab} value="one" label="Personal info" />
          <Tab className={styles.tab} value="two" label="Contacts" />
          <Tab className={styles.tab} value="three" label="Professional info" />
          <Tab className={styles.tab} value="four" label="CV & Attachments" />
        </Tabs>
      </div>

      <div className={styles.tabsContent}>
        {value === "one" && <PersonalInfo />}
        {value === "two" && <Contacts />}
        {value === "three" && <ProfessionalInfo />}
        {value === "four" && <CV />}
      </div>
    </div>
  );
};
