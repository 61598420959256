import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import registerImage from "../../images/assets/company-reg.jpg";
import { postCompanyRequest } from "../../Middleware/CompanyApis";
import styles from "./PartnerRequest.module.css";

export default function CompanyRegister() {
    const [alertMsg, setAlertMsg] = useState("");
    const navigate = useNavigate();

    const URL_REGEX = /^https:\/\/www\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[^\s]*)?$/;

    const PHONE_REGEX = /^\d{9,15}$/;
    const WORK_EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const validationSchema = yup.object({
        fullName: yup.string().required("Full name is required"),
        phone: yup
            .string()
            .required("Phone number is required")
            .matches(PHONE_REGEX, "Enter a valid phone number between 9 and 15 digits"),
        eMail: yup
            .string()
            .matches(WORK_EMAIL_REGEX, "Enter a valid work email")
            .required("Email is required"),
        companyName: yup.string().required("Company name is required"),
        companySize: yup.string().required("Company size is required"),
        website: yup
            .string()
            .matches(URL_REGEX, "Enter a valid URL starting with https://www.")
            .required("Website is required"),
    });


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            fullName: "",
            phone: "",
            eMail: "",
            companyName: "",
            companySize: "",
            website: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const res = await postCompanyRequest(
                    values.fullName,
                    values.phone,
                    values.eMail,
                    values.companyName,
                    values.companySize,
                    values.website
                );
                // Handle successful response
                setAlertMsg("Request sent successfully!");
                setSubmitting(false);
                setTimeout(() => {
                    navigate("/home"); // Redirect to home page after 10 seconds
                }, 10000);
            } catch (error) {
                console.error("Error posting company request:", error);
                try {
                    const errorMessage = JSON.parse(error.message);
                    if (errorMessage.name) {
                        setAlertMsg("A company with this name already exists.");
                    } else if (errorMessage.work_email) {
                        setAlertMsg("Emails from domain 'gmail.com' are not allowed. Please use a company email.");
                    } else {
                        setAlertMsg(error.message);
                    }
                } catch (parseError) {
                    setAlertMsg(error.message);
                }
                setSubmitting(false);
                setTimeout(() => {
                    setAlertMsg("");
                }, 20000); // Clear the alert message after 10 seconds
            }
        },
    });

    return (
        <div className={styles.companyRegisterContainer}>
            <div className={styles.contactLogo}>
                <img src={registerImage} alt="company-register" />
            </div>

            <div className={styles.registerSection}>
                <form className={styles.formContainer} onSubmit={formik.handleSubmit}>
                    <div className={styles.txtFieldContainer}>
                        <TextField
                            id="fullName"
                            name="fullName"
                            value={formik.values.fullName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="text"
                            placeholder="Full Name"
                            fullWidth
                            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                            helperText={formik.touched.fullName && formik.errors.fullName}
                            className={styles.txtField}
                        />
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <TextField
                            id="phone"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="tel"
                            placeholder="Phone Number"
                            fullWidth
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                            className={styles.txtField}
                        />
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <TextField
                            id="eMail"
                            name="eMail"
                            value={formik.values.eMail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="email"
                            placeholder="Work E-mail"
                            fullWidth
                            error={formik.touched.eMail && Boolean(formik.errors.eMail)}
                            helperText={formik.touched.eMail && formik.errors.eMail}
                            className={styles.txtField}
                        />
                    </div>

                    <div className={styles.test}>
                        <div>
                            <TextField
                                id="companyName"
                                name="companyName"
                                value={formik.values.companyName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="text"
                                placeholder="Company Name"
                                error={
                                    formik.touched.companyName &&
                                    Boolean(formik.errors.companyName)
                                }
                                helperText={
                                    formik.touched.companyName && formik.errors.companyName
                                }
                                className={styles.txtField}
                            />
                        </div>

                        <div className={styles.selectSizeContainer}>
                            <select
                                id="companySize"
                                name="companySize"
                                value={formik.values.companySize}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={styles.selectSize}
                            >
                                <option value="" disabled>
                                    Company size
                                </option>
                                <option value="1-50">1-50</option>
                                <option value="51-100">51-100</option>
                                <option value="101-200">101-200</option>
                                <option value="201-500">201-500</option>
                                <option value="501-1000">501-1000</option>
                            </select>
                            {formik.touched.companySize && formik.errors.companySize && (
                                <div className={styles.errorText}>
                                    {formik.errors.companySize}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <TextField
                            id="website"
                            name="website"
                            value={formik.values.website}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="text"
                            placeholder="Website URL"
                            fullWidth
                            error={formik.touched.website && Boolean(formik.errors.website)}
                            helperText={formik.touched.website && formik.errors.website}
                            className={styles.txtField}
                        />
                    </div>

                    <div className="w-100 d-flex justify-content-center mt-4 align-items-center flex-column">
                        <button className={styles.saveBtnSection} type="submit">
                            Submit
                        </button>
                        {alertMsg && <div className={styles.alertMessage}>{alertMsg}</div>}
                    </div>
                </form>
            </div>
        </div>
    );
}
