import React from 'react';
import {
  TextField,
  Button,
  IconButton,
  Grid,
  Paper,
} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './EditJob.module.css';

const JobDescription = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleArrayChange = (e, key, index) => {
    const newValues = [...formData[key]];
    newValues[index] = { description: e.target.value };
    setFormData({ ...formData, [key]: newValues });
  };

  const handleAddField = (key) => {
    const newValues = [...formData[key], { description: '' }];
    setFormData({ ...formData, [key]: newValues });
  };

  const handleRemoveField = (key, index) => {
    const newValues = [...formData[key]];
    newValues.splice(index, 1);
    setFormData({ ...formData, [key]: newValues });
  };

  return (
    <Paper className={styles.paper}>
      <div className='d-flex justify-content-between align-items-center w-75'>
        <div>
          <h5 className={styles.title}>
            Job Description
          </h5>
        </div>
        <TextField
          className='w-50'
          label="Job Description"
          name="jobDescription"
          multiline
          rows={4}
          value={formData.jobDescription}
          onChange={handleChange}
          margin="normal"
        />
      </div>

      <div className='d-flex justify-content-between align-items-center w-75'>
        <h5 className={styles.title}>
          Responsibilities
        </h5>
        <div className='d-flex flex-column'>
          {formData.responsibilities.map((responsibility, index) => (
            <Grid container spacing={1} key={index} alignItems="flex-end">
              <Grid item xs={10}>
                <TextField
                  label={`Responsibility ${index + 1}`}
                  name="responsibilities"
                  value={responsibility.description}
                  onChange={(e) => handleArrayChange(e, 'responsibilities', index)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveField('responsibilities', index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAddField('responsibilities')}
            startIcon={<AddIcon />}
          >
            Add Responsibility
          </Button>
        </div>
      </div>

      <div className='d-flex justify-content-between align-items-center w-75'>
        <h5 className={styles.title}>
          Qualifications
        </h5>
        <div className='d-flex flex-column'>
          {formData.qualifications.map((qualification, index) => (
            <Grid container spacing={1} key={index} alignItems="flex-end">
              <Grid item xs={10}>
                <TextField
                  label={`Qualification ${index + 1}`}
                  name="qualifications"
                  value={qualification.description}
                  onChange={(e) => handleArrayChange(e, 'qualifications', index)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveField('qualifications', index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAddField('qualifications')}
            startIcon={<AddIcon />}
          >
            Add Qualification
          </Button>
        </div>
      </div>

      <div className='d-flex justify-content-between align-items-center w-75'>
        <h5 className={styles.title}>
          Nice-to-Haves
        </h5>
        <div className='d-flex flex-column'>
          {formData.niceToHaves.map((niceToHave, index) => (
            <Grid container spacing={1} key={index} alignItems="flex-end">
              <Grid item xs={10}>
                <TextField
                  label={`Nice-to-Have ${index + 1}`}
                  name="niceToHaves"
                  value={niceToHave.description}
                  onChange={(e) => handleArrayChange(e, 'niceToHaves', index)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveField('niceToHaves', index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAddField('niceToHaves')}
            startIcon={<AddIcon />}
          >
            Add Nice-to-Have
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default JobDescription;
