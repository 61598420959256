import { WEBSITE_URL } from "./Constans";
// get users data
export async function getUserProfile(token) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const res = await fetch(
      `${WEBSITE_URL}/applicant/profile/`,
      requestOptions
    );
    const result = await res.json();
    return result;
  } catch (error) {
    console.error(error);
  }
}

export async function getUserProfileComapnyPreview(token, username) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const res = await fetch(
      `${WEBSITE_URL}/applicant/profile/?username=${username}`,
      requestOptions
    );
    const result = await res.json();
    // 
    return result;
  } catch (error) {
    console.error(error);
  }
}
//login data
export async function loginUser(username, password) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      username: username,
      password: password,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const res = await fetch(`${WEBSITE_URL}/login/`, requestOptions);
    const result = await res.json();
    // 

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function RefreshToken(refreshToken) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      refresh_token: refreshToken,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(`${WEBSITE_URL}/refreshtoken/`, requestOptions);
    const result = await res.json();

    if (result.new_token) {  // Update this to `new_token`
      return {
        new_token: result.new_token,
        expiration_time: result.expiration_time,
      };
    } else {
      throw new Error("Failed to refresh token");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}



// update candidate contacts
export async function updateContacts({
  token,
  phonenumber,
  email,
  linkedin_link,
  instgram_link,
  twitter_link,
  website,
}) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      phonenumber: phonenumber,
      email: email,
      linkedin_link: linkedin_link,
      instgram_link: instgram_link,
      twitter_link: twitter_link,
      website: website,
    });
    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const res = await fetch(
      `${WEBSITE_URL}/applicant/profile/`,
      requestOptions
    );
    if (!res.ok) {
      const errorText = await res.text();
      throw new Error(
        `HTTP error! status: ${res.status}, message: ${errorText}`
      );
    }
    const result = await res.json();
    // 
    // 

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

//edit user Profile
export async function editUserProfile(
  FullName,
  job_title,
  gender,
  phonenumber,
  nationality,
  country,
  city,
  street,
  military_status,
  marital_status,
  date_birth,
  work_experience_years,
  selectedCategories, // Add this parameter
  token
) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      fullname: FullName,
      job_title: job_title,
      gender: gender,
      phonenumber: phonenumber,
      nationality: nationality,
      country: country,
      city: city,
      street: street,
      military_status: military_status,
      marital_status: marital_status,
      date_birth: date_birth,
      work_experience_years: work_experience_years,
      categories: selectedCategories, // Add this field
      completed: true
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      `${WEBSITE_URL}/applicant/profile/`,
      requestOptions
    );
    const result = await res.json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function editAbout(about, token) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      about: about,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      `${WEBSITE_URL}/applicant/profile/`,
      requestOptions
    );
    const result = await res.json();
    // 

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function editUserImage(fileInput, imageName, token) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);

    const formdata = new FormData();
    formdata.append("image", fileInput.files[0], imageName);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const res = await fetch(
      `${WEBSITE_URL}/applicant/profileimage/`,
      requestOptions
    );
    const result = await res.json();
    // 
    return result;
  } catch (error) {
    console.error(error);
  }
}
export async function addNewFileCV(token, file, fileType) {
  if (!file) {
    throw new Error("No file selected");
  }

  const supportedTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  if (!supportedTypes.includes(file.type)) {
    throw new Error(
      "Unsupported file type. Please upload a PDF, DOC, or DOCX file."
    );
  }

  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);

    const formData = new FormData();
    formData.append("short_description", fileType);
    formData.append("attach_file", file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    const res = await fetch(
      `${WEBSITE_URL}/applicant/attachment/`,
      requestOptions
    );
    const result = await res.json();
    if (!res.ok) {
      throw new Error(result.error || "Failed to upload file");
    }

    return result;
  } catch (error) {
    console.error("Upload error:", error);
    throw error;
  }
}

export async function getAllCompanies() {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const res = await fetch(`${WEBSITE_URL}/company/all/`, requestOptions);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const result = await res.json();
    // 
    return result; // Ensure the result is returned
  } catch (error) {
    console.error(error);
    throw error; // Ensure the error is re-thrown to be caught by the caller
  }
}
export async function getComapnyProfile(companyID) {
  try {
    const myHeaders = new Headers();
    // myHeaders.append("Authorization", `JWT ${Token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${WEBSITE_URL}/company/profile/?company_name=${companyID}`
    );
    const result = await response.json();
    // 
    return result;
  } catch (error) {
    console.error("Error fetching jobs:", error);
    throw error;
  }
}
export async function getAllJobs() {
  try {
    const response = await fetch(`${WEBSITE_URL}/job/job/`);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching jobs:", error);
    throw error;
  }
}

export async function changeComapnyLogo(token, formData) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);

    // const formdata = new FormData();
    // formdata.append("image", fileInput.files[0], imageName);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    const res = await fetch(
      `${WEBSITE_URL}/company/profileimage/`,
      requestOptions
    );
    const result = await res.json();
    // 
    return result;
  } catch (error) {
    console.error("Error changing company logo:", error);
    throw error;
  }
}

export async function changeCompanyProfileLinks(token, company_name, links) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(links);

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${WEBSITE_URL}/company/profile/?company_name=${company_name}`,
      requestOptions
    );

    const data = await response.json();

    return {
      status: response.status,
      data: data,
    };
  } catch (error) {
    console.error("Error updating company profile links:", error);
    throw error;
  }
}

// apply job

export async function applyJob(token, applicantId, jobId) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      applicant: applicantId,
      job: jobId,
      status: "Submitted",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      `${WEBSITE_URL}/job/job-application/?job_id=${jobId}`,
      requestOptions
    );
    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(errorData.error || "Failed to apply for job");
    }
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error applying for job:", error);
    throw error;
  }
}

// get job Applicant

export async function ApplicantsInJob(token, jobId) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `JWT ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${WEBSITE_URL}/job/job-application/?job_id=${jobId}`,
      requestOptions
    );
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error fetching applicants:", error);
    throw error;
  }
}

export async function getCat() {
  try {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const res = await fetch(`${WEBSITE_URL}/job/job-category/`, requestOptions);
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error updating company profile links:", error);
    throw error;
  }
}
export async function getSkills() {
  try {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const res = await fetch(`${WEBSITE_URL}/job/job-skill/`, requestOptions);
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error updating company profile links:", error);
    throw error;
  }
}
export async function getJobLocation() {
  try {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const res = await fetch(
      `${WEBSITE_URL}/job/type-of-job-location/`,
      requestOptions
    );
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error updating company profile links:", error);
    throw error;
  }
}
export async function getJobEmployment() {
  try {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const res = await fetch(
      `${WEBSITE_URL}/job/type-of-employment/`,
      requestOptions
    );
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error updating company profile links:", error);
    throw error;
  }
}

export async function getJobLevel() {
  try {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const res = await fetch(`${WEBSITE_URL}/job/job-level/`, requestOptions);
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error updating company profile links:", error);
    throw error;
  }
}

export async function getIndustry() {
  try {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const res = await fetch(`${WEBSITE_URL}/company/industry/`, requestOptions);
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error updating company profile links:", error);
    throw error;
  }
}

export async function saveWorkExperience(experience, token) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(experience);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${WEBSITE_URL}/applicant/work-experience/`,
      requestOptions
    );
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error saving work experience:", error);
    throw error;
  }
}

// Function to get work experience
export async function getWorkExperience(token) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${WEBSITE_URL}/applicant/work-experience/`,
      requestOptions
    );
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching work experience:", error);
    throw error;
  }
}
export async function saveEducation(experience, token) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(experience);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${WEBSITE_URL}/applicant/education/`,
      requestOptions
    );
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error saving work experience:", error);
    throw error;
  }
}

// Function to get work experience
export async function getEducation(token) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${WEBSITE_URL}/applicant/education/`,
      requestOptions
    );
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching work experience:", error);
    throw error;
  }
}

export async function updateApplicantStatus(token, id, status) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `JWT ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ status });

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${WEBSITE_URL}/job/job-application/${id}/`,
      requestOptions
    );
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error updating applicant status:", error);
    throw error;
  }
}

export async function fetchApplicantsByStatus(token, jobId, status) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `JWT ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const url = `${WEBSITE_URL}/job/job-application/?job_id=${jobId}&status=${status}`;

  try {
    const res = await fetch(url, requestOptions);
    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error fetching applicants by status:", error);
    throw error;
  }
}
