import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { editAbout, getEducation, getWorkExperience, saveEducation, saveWorkExperience } from '../../Middleware/CandidateApis';
import { getToken } from '../../Middleware/Constans';
import addIcon from "../../images/assets/Group.svg";
import CertificationModal from "./CertificationModal";
import EducationModal from "./EducationModal";
import styles from "./ProfessionalInfo.module.css";
import WorkExperienceModal from "./WorkExperienceModal";

export default function ProfessionalInfo() {
  const [aboutMe, setAboutMe] = useState("Introduce Yourself");
  const [workExperiences, setWorkExperiences] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAllWork, setShowAllWork] = useState(false);
  const [educationDetails, setEducationDetails] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [isEducationModalOpen, setIsEducationModalOpen] = useState(false);
  const [showAllEducation, setShowAllEducation] = useState(false);
  const [skills, setSkills] = useState([]);
  const [newSkill, setNewSkill] = useState("");  // New state for the text input
  const [editingSkillId, setEditingSkillId] = useState(null);
  const [editedSkill, setEditedSkill] = useState("");
  const [languages, setLanguages] = useState([]);
  const [newLanguage, setNewLanguage] = useState("");
  const [newProficiency, setNewProficiency] = useState("");
  const [editingLanguageId, setEditingLanguageId] = useState(null);
  const [editedLanguage, setEditedLanguage] = useState("");
  const [editedProficiency, setEditedProficiency] = useState("");
  const [certifications, setCertifications] = useState([]);
  const [selectedCertification, setSelectedCertification] = useState(null);
  const [isCertificationModalOpen, setIsCertificationModalOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const token = getToken();

  const proficiencyLevels = ["Beginner", "Intermediate", "Advanced", "Native"];

  useEffect(() => {
    const fetchWorkExperience = async () => {
      try {
        const result = await getWorkExperience(token);
        setWorkExperiences(result);
        
      } catch (error) {
        console.error("Failed to fetch work experience:", error);
      }
    };

    const fetchEducationDetails = async () => {
      try {
        const result = await getEducation(token);
        setEducationDetails(result);
        
      } catch (error) {
        console.error("Failed to fetch education details:", error);
      }
    };

    const fetchSkills = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `JWT ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };

        const response = await fetch("https://hiringstg.eyouthlearning.com/applicant/skills/", requestOptions);
        const result = await response.json();
        setSkills(Array.isArray(result) ? result : []);

        
      } catch (error) {
        console.error("Failed to fetch skills:", error);
        setSkills([]);
      }
    };

    const fetchLanguages = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `JWT ${token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };

        const response = await fetch("https://hiringstg.eyouthlearning.com/applicant/language/", requestOptions);
        const result = await response.json();
        setLanguages(Array.isArray(result) ? result : []);

        
      } catch (error) {
        console.error("Failed to fetch languages:", error);
        setLanguages([]);
      }
    };

    const fetchCertifications = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `JWT ${token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };

        const response = await fetch("https://hiringstg.eyouthlearning.com/applicant/certifications/", requestOptions);
        const result = await response.json();
        setCertifications(Array.isArray(result) ? result : []);

        
      } catch (error) {
        console.error("Failed to fetch certifications:", error);
        setCertifications([]);
      }
    };

    fetchWorkExperience();
    fetchEducationDetails();
    fetchSkills();
    fetchLanguages();
    fetchCertifications();
  }, [token]);

  const handleSaveAboutMe = async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      const result = await editAbout(aboutMe, token);
      
      setSnackbarMessage('About Me saved successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error(error);
      setSnackbarMessage('Failed to save About Me.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleAddExperience = () => {
    setSelectedExperience({
      job_title: '',
      organization: '',
      description: '',
      year_experience: '3 years',
      fromm: '',
      to: '',
      currently_working: false,
      reference_name: '',
      reference_phone: '',
      reference_jobtitle: '',
      reference_relationship: ''
    });
    setIsModalOpen(true);
  };

  const handleEditExperience = (experience) => {
    setSelectedExperience(experience);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedExperience(null);
  };

  const handleSaveExperience = async (experience) => {
    try {
      await saveWorkExperience(experience, token);
      
      setIsModalOpen(false);
      setWorkExperiences(prev => {
        const index = prev.findIndex(item => item.id === experience.id);
        if (index !== -1) {
          const updatedExperiences = [...prev];
          updatedExperiences[index] = experience;
          return updatedExperiences;
        }
        return [...prev, experience];
      });
      setSnackbarMessage('Work experience saved successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Failed to save work experience:", error);
      setSnackbarMessage('Failed to save work experience.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleAddEducation = () => {
    setSelectedEducation({
      university: '',
      college: '',
      degree: '',
      grade: '',
      fromm: '',
      to: ''
    });
    setIsEducationModalOpen(true);
  };

  const handleEditEducation = (education) => {
    setSelectedEducation(education);
    setIsEducationModalOpen(true);
  };

  const handleCloseEducationModal = () => {
    setIsEducationModalOpen(false);
    setSelectedEducation(null);
  };

  const handleSaveEducation = async (education) => {
    try {
      await saveEducation(education, token);
      
      setIsEducationModalOpen(false);
      setEducationDetails(prev => {
        const index = prev.findIndex(item => item.id === education.id);
        if (index !== -1) {
          const updatedEducation = [...prev];
          updatedEducation[index] = education;
          return updatedEducation;
        }
        return [...prev, education];
      });
      setSnackbarMessage('Education details saved successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Failed to save education details:", error);
      setSnackbarMessage('Failed to save education details.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleSkillChange = (event) => {
    setNewSkill(event.target.value);
  };

  const handleAddSkill = async () => {
    if (newSkill.trim() !== "") {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `JWT ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ title: newSkill });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        const response = await fetch("https://hiringstg.eyouthlearning.com/applicant/skills/", requestOptions);
        const result = await response.json();

        setSkills(prev => [...prev, result]);
        setNewSkill("");

        
        setSnackbarMessage('Skill added successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      } catch (error) {
        console.error("Failed to add skill:", error);
        setSnackbarMessage('Failed to add skill.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    }
  };

  const handleEditSkill = (skill) => {
    setEditingSkillId(skill.id);
    setEditedSkill(skill.title);
  };

  const handleSaveEditedSkill = async (skillId) => {
    if (editedSkill.trim() !== "") {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `JWT ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ id: skillId, title: editedSkill });

        const requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        const response = await fetch("https://hiringstg.eyouthlearning.com/applicant/skills/", requestOptions);
        const result = await response.json();

        setSkills(prev => prev.map(skill => skill.id === skillId ? result : skill));
        setEditingSkillId(null);
        setEditedSkill("");

        
        setSnackbarMessage('Skill edited successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      } catch (error) {
        console.error("Failed to edit skill:", error);
        setSnackbarMessage('Failed to edit skill.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    }
  };

  const handleLanguageChange = (event) => {
    setNewLanguage(event.target.value);
  };

  const handleProficiencyChange = (event) => {
    setNewProficiency(event.target.value);
  };

  const handleAddLanguage = async () => {
    if (newLanguage.trim() !== "" && newProficiency.trim() !== "") {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `JWT ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ language: newLanguage, grade: newProficiency });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        const response = await fetch("https://hiringstg.eyouthlearning.com/applicant/language/", requestOptions);
        const result = await response.json();

        setLanguages(prev => [...prev, result]);
        setNewLanguage("");
        setNewProficiency("");

        
        setSnackbarMessage('Language added successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      } catch (error) {
        console.error("Failed to add language:", error);
        setSnackbarMessage('Failed to add language.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    }
  };

  const handleEditLanguage = (language) => {
    setEditingLanguageId(language.id);
    setEditedLanguage(language.language);
    setEditedProficiency(language.grade);
  };

  const handleSaveEditedLanguage = async (languageId) => {
    if (editedLanguage.trim() !== "" && editedProficiency.trim() !== "") {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `JWT ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ id: languageId, language: editedLanguage, grade: editedProficiency });

        const requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        const response = await fetch("https://hiringstg.eyouthlearning.com/applicant/language/", requestOptions);
        const result = await response.json();

        setLanguages(prev => prev.map(language => language.id === languageId ? result : language));
        setEditingLanguageId(null);
        setEditedLanguage("");
        setEditedProficiency("");

        
        setSnackbarMessage('Language edited successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      } catch (error) {
        console.error("Failed to edit language:", error);
        setSnackbarMessage('Failed to edit language.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    }
  };

  const handleAddCertification = () => {
    setSelectedCertification({
      title: '',
      description: '',
      organization: '',
      link: '',
      date: ''
    });
    setIsCertificationModalOpen(true);
  };

  const handleEditCertification = (certification) => {
    setSelectedCertification(certification);
    setIsCertificationModalOpen(true);
  };

  const handleCloseCertificationModal = () => {
    setIsCertificationModalOpen(false);
    setSelectedCertification(null);
  };

  const handleSaveCertification = async (certification) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `JWT ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: certification.id ? "PUT" : "POST",
        headers: myHeaders,
        body: JSON.stringify(certification),
        redirect: "follow"
      };

      const url = certification.id ? `https://hiringstg.eyouthlearning.com/applicant/certifications/${certification.id}/` : "https://hiringstg.eyouthlearning.com/applicant/certifications/";

      const response = await fetch(url, requestOptions);
      const result = await response.json();

      if (certification.id) {
        setCertifications(prev => prev.map(c => c.id === certification.id ? result : c));
      } else {
        setCertifications(prev => [...prev, result]);
      }

      setIsCertificationModalOpen(false);
      setSelectedCertification(null);
      setSnackbarMessage('Certification saved successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Failed to save certification:", error);
      setSnackbarMessage('Failed to save certification.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const displayedExperiences = showAllWork ? workExperiences : workExperiences.slice(0, 3);
  const displayedEducation = showAllEducation ? educationDetails : educationDetails.slice(0, 3);

  return (
    <div className={styles.professionalInfoContainer}>
      <div className={styles.personalInfoHeader}>
        <h2>Professional information</h2>
        <h3>
          This is information about your career paths and job descriptions that
          you can update anytime.
        </h3>
      </div>

      <div className={styles.aboutMeSection}>
        <div>
          <h2>About Me</h2>
          <h3>key details about yourself</h3>
        </div>
        <TextField
          placeholder="Introduce Yourself"
          multiline
          rows={5}
          maxRows={9}
          value={aboutMe}
          onChange={(e) => setAboutMe(e.target.value)}
          helperText="Maximum 500 characters"
          className={styles.aboutMeTxt}
        />
        <div>
          <button className={styles.saveBtn} onClick={handleSaveAboutMe}>Save</button>
        </div>
      </div>

      <div className={styles.experienceSection}>
        <div className={styles.experienceHeaderSection}>
          <h2>Work Experience</h2>
          <h3>Outline some details about each job</h3>
        </div>
        <div className='d-flex flex-wrap gap-3'>
          {displayedExperiences.map((experience, index) => (
            <div key={index} className={styles.personalDetailsSection}>
              <div className={styles.experienceItem}>
                <h4>{experience.organization}</h4>
                <p>{experience.job_title}</p>
                <IconButton onClick={() => handleEditExperience(experience)}>
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
        {workExperiences.length > 3 && (
          <div className={styles.seeMoreContainer}>
            <Button onClick={() => setShowAllWork(!showAllWork)} className={styles.seeMoreBtn}>
              {showAllWork ? 'See Less' : 'See More'}
            </Button>
          </div>
        )}

        <div className={styles.btnSection}>
          <button className={styles.addJobBtn} onClick={handleAddExperience}>
            <img className="pe-2" src={addIcon} alt="add" />
            add new job
          </button>
        </div>
      </div>

      <WorkExperienceModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        experience={selectedExperience}
        setExperience={setSelectedExperience}
        handleSave={handleSaveExperience}
      />

      <div className={styles.educationSection}>
        <div className={styles.educationHeaderSection}>
          <h2>Education</h2>
          <h3>Outline some details about Your Formal Education</h3>
        </div>
        <div className='d-flex flex-wrap gap-3'>
          {displayedEducation.map((education, index) => (
            <div key={index} className={styles.personalDetailsSection}>
              <div className={styles.educationItem}>
                <h4>{education.university}</h4>
                <p>{education.degree}</p>
                <IconButton onClick={() => handleEditEducation(education)}>
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
        {educationDetails.length > 3 && (
          <div className={styles.seeMoreContainer}>
            <Button onClick={() => setShowAllEducation(!showAllEducation)} className={styles.seeMoreBtn}>
              {showAllEducation ? 'See Less' : 'See More'}
            </Button>
          </div>
        )}

        <div className={styles.btnSection}>
          <button className={styles.addJobBtn} onClick={handleAddEducation}>
            <img className="pe-2" src={addIcon} alt="add" />
            add new Education
          </button>
        </div>
      </div>

      <EducationModal
        open={isEducationModalOpen}
        handleClose={handleCloseEducationModal}
        education={selectedEducation}
        setEducation={setSelectedEducation}
        handleSave={handleSaveEducation}
      />

      {/* Skills Section */}
      <div className={styles.skillsSection}>
        <div className={styles.skillsHeaderSection}>
          <h2>Skills</h2>
          <h3>Outline some details about Your Skills</h3>
        </div>
        <FormControl fullWidth>
          <TextField
            placeholder="Enter a skill"
            value={newSkill}
            onChange={handleSkillChange}
          />
          <Button onClick={handleAddSkill} className={styles.addSkillBtn}>Add Skill</Button>
        </FormControl>
        <div className={styles.selectedSkillsSection}>
          {skills.map((skill) => (
            <div key={skill.id} className={styles.skillChip}>
              {editingSkillId === skill.id ? (
                <TextField
                  value={editedSkill}
                  onChange={(e) => setEditedSkill(e.target.value)}
                  onBlur={() => handleSaveEditedSkill(skill.id)}
                />
              ) : (
                <>
                  {skill.title}
                  <IconButton onClick={() => handleEditSkill(skill)}><EditIcon /></IconButton>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Languages Section */}
      <div className={styles.languagesSection}>
        <div className={styles.languagesHeaderSection}>
          <h2>Languages</h2>
          <h3>Outline some details about Your Language Skills</h3>
        </div>
        <div className="d-flex gap-3">
          <FormControl fullWidth>
            <TextField
              placeholder="Enter a language"
              value={newLanguage}
              onChange={handleLanguageChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <Select
              value={newProficiency}
              onChange={handleProficiencyChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Proficiency</em>;
                }
                return selected;
              }}
            >
              {proficiencyLevels.map((level) => (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button onClick={handleAddLanguage} className={styles.addLanguageBtn}>Add Language</Button>
        </div>
        <div className={styles.selectedLanguagesSection}>
          {languages.map((language) => (
            <div key={language.id} className={styles.languageChip}>
              {editingLanguageId === language.id ? (
                <div className="d-flex gap-2">
                  <TextField
                    value={editedLanguage}
                    onChange={(e) => setEditedLanguage(e.target.value)}
                    onBlur={() => handleSaveEditedLanguage(language.id)}
                  />
                  <Select
                    value={editedProficiency}
                    onChange={(e) => setEditedProficiency(e.target.value)}
                    onBlur={() => handleSaveEditedLanguage(language.id)}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Proficiency</em>;
                      }
                      return selected;
                    }}
                  >
                    {proficiencyLevels.map((level) => (
                      <MenuItem key={level} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ) : (
                <>
                  {language.language} - {language.grade}
                  <IconButton onClick={() => handleEditLanguage(language)}><EditIcon /></IconButton>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Certifications Section */}
      <div className={styles.certificationsSection}>
        <div className={styles.certificationsHeaderSection}>
          <h2>Certifications</h2>
          <h3>Outline some details about Your Certifications</h3>
        </div>
        <div className='d-flex flex-wrap gap-3'>
          {certifications.map((certification, index) => (
            <div key={index} className={styles.personalDetailsSection}>
              <div className={styles.certificationItem}>
                <h4>{certification.title}</h4>
                <p>{certification.organization}</p>
                <IconButton onClick={() => handleEditCertification(certification)}>
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.btnSection}>
          <button className={styles.addJobBtn} onClick={handleAddCertification}>
            <img className="pe-2" src={addIcon} alt="add" />
            add new Certification
          </button>
        </div>
      </div>

      <CertificationModal
        open={isCertificationModalOpen}
        handleClose={handleCloseCertificationModal}
        certification={selectedCertification}
        setCertification={setSelectedCertification}
        handleSave={handleSaveCertification}
      />

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
