import React, { useEffect, useState } from 'react';
import { Alert, Button, TextField } from '@mui/material';
import styles from './SocialLinks.module.css';
import {
    changeCompanyProfileLinks,
    getComapnyProfile,
} from '../../Middleware/CandidateApis';
import { getToken } from '../../Middleware/Constans';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';

export default function SocialLinks() {
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const token = getToken();
    const { id } = useParams();

    const URL_REGEX =
        // eslint-disable-next-line
        /^https:\/\/(?:www\.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

    const validationSchema = yup.object({
        facebookLink: yup
            .string()
            .matches(URL_REGEX, 'Enter a valid URL (must include https://)')
            .nullable(),
        twitterLink: yup
            .string()
            .matches(URL_REGEX, 'Enter a valid URL (must include https://)')
            .nullable(),
        instagramLink: yup
            .string()
            .matches(URL_REGEX, 'Enter a valid URL (must include https://)')
            .nullable(),
        youtubeLink: yup
            .string()
            .matches(URL_REGEX, 'Enter a valid URL (must include https://)')
            .nullable(),
        linkedinLink: yup
            .string()
            .matches(URL_REGEX, 'Enter a valid URL (must include https://)')
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            facebookLink: '',
            twitterLink: '',
            instagramLink: '',
            youtubeLink: '',
            linkedinLink: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const links = {
                facebook_link: values.facebookLink,
                twitter_link: values.twitterLink,
                instagram_link: values.instagramLink,
                youtube_link: values.youtubeLink,
                linkedin_link: values.linkedinLink,
            };

            // Remove empty values
            const filteredLinks = Object.fromEntries(
                Object.entries(links).filter(([key, value]) => value !== '')
            );

            try {
                const result = await changeCompanyProfileLinks(
                    token,
                    id,
                    filteredLinks
                );

                if (result.status === 400) {
                    setErrorMessage(
                        result.data.error || 'An error occurred. Please try again.'
                    );
                    setShowErrorAlert(true);
                    setShowSuccessAlert(false);
                } else {
                    setShowSuccessAlert(true);
                    setShowErrorAlert(false);
                    setErrorMessage('');
                }
                setSubmitting(false);
            } catch (error) {
                console.error('Failed to update links', error);
                setErrorMessage('An error occurred while updating links.');
                setShowErrorAlert(true);
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        const loadCompanyProfile = async () => {
            try {
                const res = await getComapnyProfile(id);
                const profile = res.company_profile;

                formik.setValues({
                    facebookLink: profile.facebook_link || '',
                    twitterLink: profile.twitter_link || '',
                    instagramLink: profile.instagram_link || '',
                    youtubeLink: profile.youtube_link || '',
                    linkedinLink: profile.linkedin_link || '',
                });
            } catch (error) {
                console.error('Failed to load company profile', error);
            }
        };

        loadCompanyProfile();
    }, [id]);

    return (
        <div className={styles.socialLinksContainer}>
            <div className={styles.personalDetailsSection}>
                <div className={styles.Basic_Information}>
                    <h2>Social Links</h2>
                    <h3>
                        Add or update the social media links for your company profile.
                        Please make sure to enter valid URLs.
                    </h3>
                </div>
                <form onSubmit={formik.handleSubmit} className={styles.formSection}>
                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Instagram</h4>
                        <TextField
                            fullWidth
                            margin='normal'
                            className={styles.txtField}
                            placeholder='https://instagram.com/Company-Name'
                            name='instagramLink'
                            value={formik.values.instagramLink}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.instagramLink &&
                                Boolean(formik.errors.instagramLink)
                            }
                            helperText={
                                formik.touched.instagramLink && formik.errors.instagramLink
                            }
                        />
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Twitter</h4>
                        <TextField
                            fullWidth
                            className={styles.txtField}
                            placeholder='https://twitter.com/Company-Name'
                            name='twitterLink'
                            value={formik.values.twitterLink}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.twitterLink && Boolean(formik.errors.twitterLink)
                            }
                            helperText={
                                formik.touched.twitterLink && formik.errors.twitterLink
                            }
                        />
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Facebook</h4>
                        <TextField
                            fullWidth
                            className={styles.txtField}
                            placeholder='https://facebook.com/Company-Name'
                            name='facebookLink'
                            value={formik.values.facebookLink}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.facebookLink &&
                                Boolean(formik.errors.facebookLink)
                            }
                            helperText={
                                formik.touched.facebookLink && formik.errors.facebookLink
                            }
                        />
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>LinkedIn</h4>
                        <TextField
                            fullWidth
                            className={styles.txtField}
                            placeholder='https://linkedin.com/company/Company-Name'
                            name='linkedinLink'
                            value={formik.values.linkedinLink}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.linkedinLink &&
                                Boolean(formik.errors.linkedinLink)
                            }
                            helperText={
                                formik.touched.linkedinLink && formik.errors.linkedinLink
                            }
                        />
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>YouTube</h4>
                        <TextField
                            fullWidth
                            className={styles.txtField}
                            placeholder='https://youtube.com/Company-Name'
                            name='youtubeLink'
                            value={formik.values.youtubeLink}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.youtubeLink && Boolean(formik.errors.youtubeLink)
                            }
                            helperText={
                                formik.touched.youtubeLink && formik.errors.youtubeLink
                            }
                        />
                    </div>

                    <div>
                        {showErrorAlert && (
                            <Alert
                                variant='filled'
                                severity='error'
                                onClose={() => setShowErrorAlert(false)}
                            >
                                {errorMessage}
                            </Alert>
                        )}

                        {showSuccessAlert && (
                            <Alert
                                variant='filled'
                                severity='success'
                                onClose={() => setShowSuccessAlert(false)}
                            >
                                Data updated successfully!
                            </Alert>
                        )}

                        <div className={styles.saveBtnSection}>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                disabled={formik.isSubmitting}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
