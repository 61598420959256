import Box from "@mui/material/Box";
import React from "react";
import Point1 from "../../images/New-Design-Images/Point-1.png";
import Point2 from "../../images/New-Design-Images/Point-2.png";
import Point3 from "../../images/New-Design-Images/Point-3.png";
import Point4 from "../../images/New-Design-Images/Point-4.png";
import styles from "./WhyToUseSection.module.css";

function WhyToUseSection() {

  return (
    <div className={styles.whyUseContainer}>
      <h2>
        Why to use <span style={{ color: "#263A96" }}>EYouth</span> Hiring Portal
      </h2>
      <div className={styles.portalBenefits}>
        <Box component="div" className={styles.points}>
          <img src={Point1} alt="point-logo" />
          <span>
            WE connect you with qualified job seekers.
          </span>
        </Box>

        <Box component="div" className={styles.points}>
          <img src={Point2} alt="point-logo" />
          <span>
            Free of charge, we aim to support our program graduates.
          </span>
        </Box>

        <Box component="div" className={styles.points}>
          <img src={Point3} alt="point-logo" />
          <span>
            Qualified youth profiles waiting to join the labor market.
          </span>
        </Box>

        <Box component="div" className={styles.points}>
          <img src={Point4} alt="point-logo" />
          <span>
            Fast, easy to use & excellent quality
          </span>
        </Box>
      </div>
    </div >
  );
}

export default WhyToUseSection;
