import { Delete, Download, Upload } from '@mui/icons-material'
import { Avatar, Button, TextField, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import React from 'react'
import styles from './AccountEdit.module.css'

function AccountEdits() {
    return (
        <>
            <div className={styles.banner}>
                <div className={styles.bannerContent}>
                    <Avatar
                        alt="user.fullName"
                        sx={{ width: 96, height: 96, justifySelf: "flex-start", bgcolor: indigo[900] }}
                    />
                    <div className={styles.uploadPosition}>
                        <Button size='small' variant="contained" startIcon={<Upload />} className={styles.actions} sx={{ mx: 1 }}>
                            Upload
                        </Button>
                        <Button size='small' variant="outlined" color="error" startIcon={<Delete />} className={styles.actions}>
                            Remove
                        </Button>
                    </div>
                </div>
            </div>
            <div className={styles.detailsContent}>
                <form >
                    <div className={styles.inputContainer}>
                        <div className={styles.inputWidth}>
                            <label htmlFor="fullName">
                                <strong>Full Name</strong>
                            </label>
                            <TextField id="fullName" name='fullName' variant="outlined" size='small' helperText="tesst" />
                        </div>
                        <div className={styles.inputWidth}>
                            <label htmlFor="email">
                                <strong>Email Address</strong>
                            </label>
                            <TextField id="email" name='email' variant="outlined" size='small' helperText="test" />
                        </div>
                    </div>

                    <div className={styles.inputWidth}>
                        <label htmlFor="phone">
                            <strong>Phone</strong>
                        </label>
                        <TextField type="number" id="phone" name='phone' variant="outlined" size='small' helperText="test" />
                    </div>
                </form>


                <Typography variant="h6" gutterBottom>
                    Email: &nbsp;<span style={{ color: '#263997' }}>user.email</span>
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Phone: &nbsp;<span style={{ color: '#263997' }}>user.phone</span>
                </Typography>
                <div>
                    <Button variant="contained" startIcon={<Download />} sx={{ background: "#263997", fontWeight: "bold" }}>
                        View Contract
                    </Button>
                    {/* <a href='/somefile.txt' download>Click to download</a>
                    <Link to="/files/myfile.pdf" target="_blank" download>Download</Link> */}

                </div>

            </div>


        </>


    )
}

export default AccountEdits