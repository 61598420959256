import { Grid } from "@mui/material";
import React from "react";
// import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { API_URL } from "../../config";
import styles from './NewVacancy.module.css';

function NewVacancy() {
  // const state = useSelector((state) => state);
  const loggedUser = useSelector((state) => state.loggedUserReducer);
  const navigate = useNavigate();
  const [orgName, setOrgName] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [visibility, setVisibility] = React.useState("");
  const [experience, setExperience] = React.useState("");
  const [type, setType] = React.useState("");

  const validationSchema = yup.object({
    title: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    location: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    category: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(200, "Too Long!")
      .required("Required"),
    experience: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    type: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(200, "Too Long!")
      .required("Required"),
    requirements: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(200, "Too Long!")
      .required("Required"),
    roles: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(200, "Too Long!")
      .required("Required"),
    org: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    visibility: yup
      .string()
      .trim()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required")
  });

  const navigateMyVacancies = () => {
    navigate("/my-vacancies/active");
  };

  const postVacancy = (values) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: values.title,
      location: values.location,
      category: values.category,
      experience: values.experience,
      type: values.type,
      requirements: values.requirements,
      roles: values.roles,
      org: values.org,
      visibility: values.visibility,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/vacancy/create`, requestOptions)
      .then((response) => response.json())
      .then(() => {
        navigateMyVacancies();
      })
      .catch((error) => console.error(error));
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      location: "",
      category: "",
      experience: "",
      type: "",
      requirements: "",
      roles: "",
      org: "",
      visibility: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      postVacancy(values);
      // signup(values);
    },
  });

  const handleChangeOrg = (event) => {
    setOrgName(event.target.value);
    formik.values.org = event.target.value;
  };
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
    formik.values.category = event.target.value;
  };
  const handleChangeVisibilty = (event) => {
    setVisibility(event.target.value);
    formik.values.visibility = event.target.value;
  };
  const handleChangeExperience = (event) => {
    setExperience(event.target.value);
    formik.values.experience = event.target.value;
  };
  const handleChangeType = (event) => {
    setType(event.target.value);
    formik.values.type = event.target.value;
  };

  useEffect(() => {
    document.title = "Hiring Portal - New vacancy";
    // if (state.loggedUserReducer.token === undefined) navigate("/signin");
  }, []);

  return (
    <Grid
      item
      xs={10}
      sm={10}
      md={6}
      lg={7}
      mx={7}
      mb={10}
      mt={15}
      className={styles.vacancyForm}
      sx={{ background: "rgba(254, 239, 221, 0.35)",
        border: "3px solid #263997",
        boxShadow: "5px 5px 25px rgba(0, 0, 0, 0.25)", 
        position: "relative" , 
        }}
    >
        <Typography variant="h5" className={styles.overlappingTitle}>
          Fill in your vacancy details
        </Typography>
      <Box component="div" sx={{ mt: 1, color: "black" }}>
        <Box component="div" px={2} pt={4}>
          <Typography variant="h6" sx={{ color: "#1B307F" }}>
            Vacancy details
          </Typography>
        </Box>
        <Box component="div" sx={{ p: 2 }}>
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                maxWidth: "100%",
                mb: 2
              }}
            >
              <label>Job title*</label>
              <input
                autoFocus
                name="title"
                id="title-small"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={
                  formik.touched.title && Boolean(formik.errors.title)
                }
              />
              {formik.touched.title && formik.errors.title && (
                <small className="text-danger">
                  {formik.errors.title}
                </small>
              )}
            </Box>              
            <Box
              sx={{
                maxWidth: "100%",
                mb: 2,
              }}
            >
              <label>Location*</label>
              <input
                name="location"
                id="location-small"
                value={formik.values.location}
                onChange={formik.handleChange}
                error={
                  formik.touched.location &&
                  Boolean(formik.errors.location)
                }
              />
              {formik.touched.location && formik.errors.location && (
                <small className="text-danger">
                  {formik.errors.location}
                </small>
              )}
            </Box>
            <Box
                sx={{
                  maxWidth: "100%",
                  mb: 2,
                }}
              >
                  <FormControl fullWidth>
                    <label htmlFor="category-select" id="category-select-label">
                      Vacancy Category*
                    </label>
                    <div className={styles.selectWrapper}>
                      <select
                        id="category-select"
                        value={category}
                        label="category"
                        onChange={handleChangeCategory}
                      >
                        <option value={"Private"}>Private</option>
                        <option value={"Public"}>Public</option>
                      </select>
                    </div>
                  </FormControl>
                  {formik.touched.category &&
                    formik.errors.category && (
                      <small className="text-danger">
                        {formik.errors.category}
                      </small>
                    )}
                </Box>
            <Box
              sx={{
                maxWidth: "100%",
                mb: 2,
              }}
            >
              <FormControl fullWidth>
                <label htmlFor="experience-select" id="experience-select-label">
                  Experience*
                </label>
                <div className={styles.selectWrapper}>
                  <select
                    id="experience-select"
                    value={experience}
                    label="experience"
                    onChange={handleChangeExperience}
                  >
                    <option value={"Full time"}>Full time</option>
                    <option value={"Part time"}>Part time</option>
                    <option value={"Hybrid"}>Hybrid</option>
                    <option value={"Remote"}>Remote</option>
                  </select>
                </div>
              </FormControl>
              {formik.touched.experience &&
                formik.errors.experience && (
                  <small className="text-danger">
                    {formik.errors.experience}
                  </small>
                )}
            </Box>
            <Box
              sx={{
                maxWidth: "100%",
                mb: 2,
              }}
            >
              <FormControl fullWidth>
                <label htmlFor="type-select" id="type-select-label">
                  Job type*
                </label>
                <div className={styles.selectWrapper}>
                  <select
                    id="type-select"
                    value={type}
                    onChange={handleChangeType}
                  >
                    <option value={"Full time"}>Full time</option>
                    <option value={"Part time"}>Part time</option>
                    <option value={"Hybrid"}>Hybrid</option>
                    <option value={"Remote"}>Remote</option>
                  </select>
                </div>
              </FormControl>
              {formik.touched.type && formik.errors.type && (
                <small className="text-danger">
                  {formik.errors.type}
                </small>
              )}
            </Box>
            <Box
              sx={{
                maxWidth: "100%",
                mb: 2,
              }}
            >
              <FormControl fullWidth>
                <label htmlFor="company-select" id="company-select-label">
                  Company hiring for*
                </label>
                <div className={styles.selectWrapper}>
                  <select
                    id="company-select"
                    value={orgName}
                    onChange={handleChangeOrg}
                  >
                    <option value={"EYouth"}>EYouth</option>
                    <option value={"LinkedIn"}>LinkedIn</option>
                  </select>
                </div>
              </FormControl>
              {formik.touched.org && formik.errors.org && (
                <small className="text-danger">
                  {formik.errors.org}
                </small>
              )}
            </Box>
            <Box px={1}>
              <Box component="div">
                <Typography variant="h6" sx={{ color: "#1B307F" }}>
                  Make this project visible to:
                </Typography>
              </Box>
              <Box
                sx={{
                  maxWidth: "100%",
                  mb: 2,
                }}
              >
                <FormControl fullWidth>
                  <label htmlFor="visibility-select" id="visibility-select-label">
                    Visibility
                  </label>
                  <div className={styles.selectWrapper}>
                    <select
                      id="visibility-select"
                      value={visibility}
                      label="Visibility"
                      onChange={handleChangeVisibilty}
                    >
                      <option value={"Private"}>Private</option>
                      <option value={"Public"}>Public</option>
                    </select>
                  </div>
                </FormControl>
                {formik.touched.visibility &&
                  formik.errors.visibility && (
                    <small className="text-danger">
                      {formik.errors.visibility}
                    </small>
                  )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <button type="submit" variant="contained" className={styles.overlappingSubmit}>
        Submit
      </button>
    </Grid>
  );

}
export default NewVacancy;
