import { FormControl, MenuItem, Select, Snackbar, Alert } from "@mui/material";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import * as yub from "yup";
import styles from "./CV.module.css";
import { getToken } from "../../Middleware/Constans";
import { addNewFileCV } from "../../Middleware/CandidateApis";

export default function CV() {
    const token = getToken();
    const fileInputRef = useRef(null);
    const [backendError, setBackendError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState('');

    const validationSchema = yub.object({
        description: yub.string().required("Description is required"),
        file: yub.mixed().required("File is required")
            .test("fileFormat", "Unsupported Format", value => {
                return value && ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: "",
            file: null,
        },
        validationSchema: validationSchema,

        onSubmit: async (values, { resetForm }) => {
            setBackendError('');
            try {
                // eslint-disable-next-line
                const res = await addNewFileCV(token, values.file, values.description);
                resetForm();
                setSelectedFileName('');
                setSuccessMessage('File uploaded successfully!');
                setOpenSnackbar(true);
            } catch (error) {
                console.error(error);
                setBackendError(error.message || 'Failed to upload file. Please try again.');
            }
        },
    });

    const handleFileChange = (e) => {
        const file = e.currentTarget.files[0];
        formik.setFieldValue("file", file);
        setSelectedFileName(file.name);
    };

    const handleChooseFileClick = () => {
        fileInputRef.current.click();
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div className={styles.cvContainer}>
            <div className={styles.personalInfoHeader}>
                <h2>Attachments</h2>
                <h3>you can upload your CV\Cover letter \Certifications</h3>
            </div>

            <div className={styles.personalDetailsSection}>
                <h2>Files</h2>
                <form onSubmit={formik.handleSubmit} className={styles.formSection}>
                    <div className={styles.fileUploadSection}>
                        <FormControl fullWidth margin="normal">
                            <h4 className={styles.fieldsName}>File Type *</h4>
                            <Select
                                id="description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.description && Boolean(formik.errors.description)
                                }
                                helperText={
                                    formik.touched.description && formik.errors.description
                                }
                                className={styles.txtField}
                            >
                                <MenuItem value="cv">C.V</MenuItem>
                                <MenuItem value="cover_letter">Cover Letter</MenuItem>
                                <MenuItem value="certificate">Certificate</MenuItem>
                                <MenuItem value="others">Others</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <h4 className={styles.fieldsName}>Upload *</h4>
                            <input
                                ref={fileInputRef}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <div className={styles.uploadBtnContainer}>
                                <button
                                    type="button"
                                    className={styles.uploadBtn}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleChooseFileClick}
                                >
                                    Choose File
                                </button>
                                {selectedFileName && (
                                    <div className={styles.selectedFileName}>
                                        {selectedFileName}
                                    </div>
                                )}
                            </div>
                            {formik.touched.file && formik.errors.file && (
                                <div className={styles.errorText}>{formik.errors.file}</div>
                            )}
                        </FormControl>
                    </div>

                    {backendError && (
                        <div className={styles.backendError}>{backendError}</div>
                    )}

                    <div className={styles.saveBtnSection}>
                        <button type="submit">Save Changes</button>
                    </div>
                </form>
            </div>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={10000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {successMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}
