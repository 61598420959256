import React from 'react';
import styles from './ProfessionalInfo.module.css';

const SkillsComponent = ({ skills }) => {

  return (
    <div className='Skills'>
      <h6 className={styles.textProfessional}>Skills</h6>
      <ul className={styles.skillsContainer}>
        {skills.map((skill, index) => (
          <li key={index} className={styles.skillsContent}>{skill.title}</li>
        ))}
      </ul>
    </div>
  );
};

export default SkillsComponent;
