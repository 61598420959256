import React, { useState } from 'react';
import styles from './ProfessionalInfo.module.css';

const WorkExperienceComponent = ({ work }) => {
  const [showAll, setShowAll] = useState(false);

  const displayedExperiences = showAll ? work : work.slice(0, 2);

  return (
    <div>
      <h5 className='mb-4'>Work Experience</h5>
      {Array.isArray(displayedExperiences) && displayedExperiences.map((job, index) => {
        const isLast = index === displayedExperiences.length - 1;
        const {
          job_title,
          organization,
          year_experience,
          reference_name,
          reference_jobtitle,
          reference_phone,
          reference_relationship
        } = job;

        return (
          <div key={index} className={styles.BorderDiv} style={{ borderBottom: isLast ? 'none' : '1px solid #ccc' }}>
            <div className='d-flex justify-content-between'>
              <div className={styles.CurrentJob}>
                <span>Job</span>
                <h6>{job_title} at {organization}</h6>
              </div>
              <div className={styles.CurrentJob}>
                <span>Experience</span>
                <h6>{year_experience} years</h6>
              </div>
            </div>
            {(reference_name || reference_jobtitle || reference_phone || reference_relationship) && (
              <>
                <h6><strong>References:</strong></h6>
                <ul className='d-flex justify-content-around border p-3'>
                  <li>
                    <div className='d-flex flex-column justify-content-around'>
                      <span>{reference_name || "N/A"}</span>
                      <span>{reference_jobtitle || "N/A"}</span>
                    </div>
                  </li>
                  <li>
                    <div className='d-flex flex-column justify-content-around'>
                      <span>Phone</span>
                      <span>{reference_phone || "N/A"}</span>
                    </div>
                  </li>
                  <li>
                    <div className='d-flex flex-column justify-content-around'>
                      <span>Relationship</span>
                      <span>{reference_relationship || "N/A"}</span>
                    </div>
                  </li>
                </ul>
              </>
            )}
          </div>
        );
      })}
      {work.length > 2 && (
        <button onClick={() => setShowAll(!showAll)} className={styles.toggleButton}>
          {showAll ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  );
};

export default WorkExperienceComponent;
