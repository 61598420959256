import { Alert, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getUserProfile } from '../../../Middleware/CandidateApis';
import { getToken } from '../../../Middleware/Constans';
import styles from './EYouthCertification.module.css';

function EYouthCertification() {
  const [certifications, setCertifications] = useState([]);
  const [offlineCourses, setOfflineCourses] = useState([]); // New state for offline courses
  const [courseCode, setCourseCode] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const token = getToken();

  useEffect(() => {
    getUserProfile(token).then((res) => {
      const profileData = res.applicant_profile.eyouth_certifications;
      setCertifications(profileData);
    }).catch((error) => {
      console.error(error);
    });

    // Fetch offline courses
    const fetchOfflineCourses = () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `JWT ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch("https://hiringstg.eyouthlearning.com/applicant/offline-course/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setOfflineCourses(result);
        })
        .catch((error) => {
          console.error("Failed to fetch offline courses:", error);
        });
    };

    fetchOfflineCourses();

  }, [token]);

  const handleCourseCodeChange = (e) => {
    setCourseCode(e.target.value);
  };

  const handleSubmit = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "code": courseCode,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://hiringstg.eyouthlearning.com/applicant/offline-course/", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result && result.title) {
          setSuccessMessage(`${result.title} Points added`);
          setErrorMessage('');
          setOfflineCourses((prevCourses) => [...prevCourses, result]); // Update offline courses list
        } else {
          setErrorMessage('Failed to add course. Please try again.');
          setSuccessMessage('');
        }
      })
      .catch(error => {
        console.error(error);
        setErrorMessage('Failed to add course. Please try again.');
        setSuccessMessage('');
      });
  };

  return (
    <div>
      {/* <div className={styles.OnlineCourses}>
        <div className='col-4'>
          <h6 className={styles.h6Headline}>Online Courses</h6>
          <span>Outline some details about Your Formal Education</span>
        </div>
        <div className={`col-8 ${styles.coursesOnlineCertifications}`}>
          {certifications.map((cert, index) => (
            <div key={index} className={styles.courseCertification}>
              <h5 style={{ color: "var(--main_color)" }}>Certificate</h5>
              <h6 style={{ minHeight: "8vh" }}>{cert.course_display_name}</h6>
              <div className='mb-5'>
                <p className='p-0 m-0'>from</p>
                <span>EYouth</span>
              </div>
              <div className={styles.buttonCertificates}>
                <a href={`experience.eyouthlearning.com${cert.download_url}`} target="_blank" rel="noopener noreferrer">
                  <button className="btn btn-outline-primary">View Certificate <LaunchIcon className={styles.LaunchIcon} /> </button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      {/* <hr></hr> */}
      <div className={styles.OfflineCourses}>
        <h6 className={styles.h6Headline}>Offline Courses</h6>
        <span>Outline some details about Your Formal Education</span>

        <TextField
          label="Course Code"
          value={courseCode}
          onChange={handleCourseCodeChange}
          fullWidth
          margin="normal"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>

        {successMessage && (
          <Alert severity="success" style={{ marginTop: '10px' }}>
            {successMessage}
          </Alert>
        )}

        {errorMessage && (
          <Alert severity="error" style={{ marginTop: '10px' }}>
            {errorMessage}
          </Alert>
        )}

        <div className={styles.offlineCourseList}>
          {offlineCourses.map((course, index) => (
            <div key={index} className={styles.courseCertification}>
              {/* <h5 style={{ color: "var(--main_color)" }}>Course Name:</h5> */}
              <h3 style={{ color: "var(--main_color)" }} className='text-center'>{course.course_code.title}</h3> {/* Accessing and displaying the course title */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default EYouthCertification;
