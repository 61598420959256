import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import categoryReducer from "./features/categorySlice";
import tokenReducer from "./features/tokenSlice";
import uncontactedReducer from "./features/uncontactedSlice.js";
import contactedReducer from "./features/contactedSlice.js";
import phoneReducer from "./features/phoneSlice.js";
import acceptedReducer from "./features/acceptedSlice.js";
import rejectedReducer from "./features/rejectedSlice.js";
import thunk from 'redux-thunk';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import currentPipelineReducer from "./features/currentPipelineSLice.js";
import categoryResultsReducer from "./features/categoryResultsSlice";
import loggedUserReducer from "./features/loggedUserSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  categoryReducer,
  tokenReducer,
  currentPipelineReducer,
  uncontactedReducer,
  contactedReducer,
  phoneReducer,
  acceptedReducer,
  rejectedReducer,
  categoryResultsReducer,
  loggedUserReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'], // Ignore the persist actions
        ignoredPaths: ['register'], // Ignore paths that contain non-serializable values
      },
    }).concat(thunk),
});

const persistor = persistStore(store);

export { persistor };
export default store;
