import React, { useEffect, useState } from 'react';
import styles from './Points.module.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getUserProfile } from '../../../Middleware/CandidateApis';
import { getToken } from '../../../Middleware/Constans';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const getColorForPoints = (points) => {
  if (points === 100) return 'green';
  if (points >= 50 && points < 100) return 'orange';
  return 'red';
};

function Points() {
  const [profile, setProfile] = useState({});
  const token = getToken();

  useEffect(() => {
    getUserProfile(token).then((res) => {
      const profileData = res.applicant_profile;
      setProfile(profileData);
      
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <aside>
      <div className='d-flex align-items-center flex-column border-bottom border-top p-2 my-2'>
        <h4 className={styles.contactH1}><strong>Complete your profile</strong></h4>
        <span className='text-center text-muted'>Complete your profile to speed up your hiring process!</span>
      </div>
      <div>
        <div className='mb-2'>
          <Typography variant="body1" className={styles.pointsInfo} style={{ color: getColorForPoints(profile.personal_points) }}>
            {profile.personal_points === 100 ? (
              <StyledCheckCircleOutlineIcon />
            ) : (
              <CheckCircleOutlineIcon />
            )}
            Personal Info ( {`${profile.personal_points}/100`})
          </Typography>
          <span className='ms-5'>{profile.personal_points === 100 ? 'completed' : `${profile.personal_points}/100`}</span>
        </div>
        <div className='mb-2'>
          <Typography variant="body1" className={styles.pointsInfo} style={{ color: getColorForPoints(profile.contact_points) }}>
            {profile.contact_points === 100 ? (
              <StyledCheckCircleOutlineIcon />
            ) : (
              <CheckCircleOutlineIcon />
            )}
            Contacts Info ( {`${profile.contact_points}/100`})
          </Typography>
          <span className='ms-5'>{profile.contact_points === 100 ? 'completed' : `${profile.contact_points}/100`}</span>
        </div>
        <div className='mb-2'>
          <Typography variant="body1" className={styles.pointsInfo} style={{ color: getColorForPoints(profile.professional_points) }}>
            {profile.professional_points === 100 ? (
              <StyledCheckCircleOutlineIcon />
            ) : (
              <CheckCircleOutlineIcon />
            )}
            Professional Info ( {`${profile.professional_points}/100`})
          </Typography>
          <span className='ms-5'>{profile.professional_points === 100 ? 'completed' : `Not Completed `}</span>
        </div>
        {/* <div className='mb-2'>
          <Typography variant="body1" className={styles.pointsInfo} style={{ color: getColorForPoints(profile.eyouth_certifications_points) }}>
            {profile.eyouth_certifications_points === 100 ? (
              <StyledCheckCircleOutlineIcon />
            ) : (
              <CheckCircleOutlineIcon />
            )}
            EYouth-Certifications 
          </Typography>
          <span className='text-muted ms-5'>
            {profile.eyouth_certifications_points === 100 ? 'completed' : 'Earn valuable points with our certificates!'}
          </span>
        </div> */}
      </div>
    </aside>
  );
}

export default Points;
