import { createSlice } from "@reduxjs/toolkit";

export const uncontactedSlice = createSlice({
  name: "uncontactedSlice",
  initialState: [],
  reducers: {
    SET_UNCONTACTED: (state, action) => {
      state = action.payload;
      return state;
    },
    ADD_UNCONTACTED: (state, action) => {
      const found = state.find(
        (ele) => ele.username === action.payload.username
      );
      if (!found) {
        state.push(action.payload);
      }
      return state;
    },
    REMOVE_UNCONTACTED: (state, action) => {
      return state.filter(
        (element) => element.username !== action.payload.username
      );
    },
  },
});

export const {
  ADD_UNCONTACTED,
  REMOVE_UNCONTACTED,
  SET_UNCONTACTED,
} = uncontactedSlice.actions;
export default uncontactedSlice.reducer;
