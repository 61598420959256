import GridViewIcon from "@mui/icons-material/GridView";
import SettingsIcon from "@mui/icons-material/Settings";
import { FormControl } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ActiveJob.module.css";

export default function ActiveJob({ jobs, onStatusChange }) {
    const navigate = useNavigate();

    const handleApplyClick = (companyName, jobSlug) => {
        navigate(`/job-details/${companyName}/${jobSlug}`);
    };

    const handleStatusChange = (jobId, event) => {
        const newStatus = event.target.value === "true";
        onStatusChange(jobId, newStatus);
    };

    return (
        <div className={styles.activeJobContainer}>
            {jobs.map((job) => (
                <div
                    key={job.id}
                    className={styles.singleJobContainer}
                >
                    <div className={styles.logoSection}>
                        <img
                            src={job.company && job.company.logo}
                            alt={`${job.company.name} Logo`}
                            className={styles.companyLogo}
                        />
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <select
                                name="job_status"
                                id="job_status"
                                value={job.is_active}
                                className={styles.jobStatus}
                                onChange={(e) => handleStatusChange(job.id, e)}
                            >
                                <option value="true">Active</option>
                                <option value="false">Archived</option>
                            </select>
                        </FormControl>
                    </div>
                    <div className={styles.jobTitle}>
                        <h2 onClick={() => handleApplyClick(job.company.slug, job.slug)}>{job.title}</h2>
                        <h3>
                            {job.type_of_job_location &&
                                job.type_of_job_location[0] &&
                                job.type_of_job_location[0].name}{" "}
                            .{" "}
                            {job.company &&
                                job.company.locations &&
                                job.company.locations[0] &&
                                job.company.locations[0].title}
                        </h3>
                        <p>{job.job_description}</p>
                    </div>
                    <div className={`d-flex my-3 ${styles.jobDescription}`}>
                        <h4>
                            {job.job_category &&
                                job.job_category[0] &&
                                job.job_category[0].name}
                        </h4>
                        <h4>
                            {job.type_of_employment &&
                                job.type_of_employment[0] &&
                                job.type_of_employment[0].name}
                        </h4>
                    </div>
                    <div className={styles.btnSections}>
                        <Link to={`/edit-job/${job.company.slug}/${job.slug}`}>
                            <button className={styles.editJobBtn}>
                                <SettingsIcon className="mx-1" />
                                Edit Job
                            </button>
                        </Link>
                        <a href={`/job-dashboard/${job.company.slug}/${job.slug}`}>
                            <button className={styles.dashboardBtn}>
                                <GridViewIcon className="mx-1" sx={{ color: "#FFCF44" }} />
                                Job Dashboard
                            </button>
                        </a>
                    </div>
                </div>
            ))}
        </div>
    );
}
