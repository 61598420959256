import React, { useState, useEffect } from "react";
import styles from "./Overview.module.css";
import iconPhoto from "../../images/assets/IconPhoto.svg";
import companyLogo from "../../images/notFound.jpeg";
import { Alert, TextField, FormControl } from "@mui/material";
import { getToken, getUserData, WEBSITE_URL } from "../../Middleware/Constans";
import { changeComapnyLogo, getIndustry } from "../../Middleware/CandidateApis";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";

export default function Overview() {
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showImageAlert, setShowImageAlert] = useState(false);
    const [showImageError, setShowImageError] = useState(false);
    const [selected, setSelected] = useState([]);
    const [logo, setLogo] = useState(companyLogo);
    const [industries, setIndustries] = useState([]);
    const [apiMessage, setApiMessage] = useState("");
    const token = getToken();
    const userData = getUserData();
    const options = [
        { value: "Algerian", label: "Algeria" },
        { value: "Bahraini", label: "Bahrain" },
        { value: "Comoran", label: "Comoros" },
        { value: "Djiboutian", label: "Djibouti" },
        { value: "Egyptian", label: "Egypt" },
        { value: "Iraqi", label: "Iraq" },
        { value: "Jordanian", label: "Jordan" },
        { value: "Kuwaiti", label: "Kuwait" },
        { value: "Lebanese", label: "Lebanon" },
        { value: "Libyan", label: "Libya" },
        { value: "Mauritanian", label: "Mauritania" },
        { value: "Moroccan", label: "Morocco" },
        { value: "Omani", label: "Oman" },
        { value: "Palestinian", label: "Palestine" },
        { value: "Qatari", label: "Qatar" },
        { value: "Saudi", label: "Saudi Arabia" },
        { value: "Somali", label: "Somalia" },
        { value: "Sudanese", label: "Sudan" },
        { value: "Syrian", label: "Syria" },
        { value: "Tunisian", label: "Tunisia" },
        { value: "Emirati", label: "United Arab Emirates" },
        { value: "Yemeni", label: "Yemen" }
    ];
    const URL_REGEX = /^https:\/\/(?:www\.)?.+/i;
    const today = new Date();

    const validationSchema = yup.object({
        website: yup
            .string()
            .matches(URL_REGEX, "Enter a valid URL (must include https://)")
            .required("Website is required"),
        company_size: yup.string().required("Number of employees is required"),
        industryy: yup.string().required("Industry is required"),
        date_founded: yup
            .date()
            .max(today, "Date founded cannot be in the future")
            .required("Date founded is required"),
        about: yup.string().required("About company is required"),
        locations: yup
            .array()
            .min(1, "At least one location is required")
            .required("Location is required"),
    });

    const formik = useFormik({
        initialValues: {
            locations: [],
            company_size: "",
            website: "",
            industryy: "",
            date_founded: "",
            about: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `JWT ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                locations: values.locations,
                company_size: values.company_size,
                website: values.website,
                industryy: values.industryy,
                date_founded: values.date_founded,
                about: values.about,
                completed: true,
            });

            const requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                const response = await fetch(
                    `${WEBSITE_URL}/company/profile/?company_name=${userData.company_name}`,
                    requestOptions
                );
                const result = await response.json();
                // 
                setApiMessage(result.message || "Data updated successfully!");
                setShowSuccessAlert(true);
                setTimeout(() => {
                    setShowSuccessAlert(false);
                }, 5000);
                setSubmitting(false);
                window.location.reload();
            } catch (error) {
                console.error(error);
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        const fetchIndustries = async () => {
            try {
                const industryData = await getIndustry();
                const industryOptions = industryData.map((industryy) => ({
                    value: industryy.name,
                    label: industryy.name,
                }));
                setIndustries(industryOptions);
            } catch (error) {
                console.error("Failed to fetch industries:", error);
            }
        };

        const fetchData = async () => {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `JWT ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            try {
                const response = await fetch(
                    `${WEBSITE_URL}/company/profile/?company_name=${userData.company_name}`,
                    requestOptions
                );
                const result = await response.json();
                if (result) {
                    const { company_profile } = result;

                    formik.setValues({
                        locations: company_profile.locations.map((loc) => loc.title) || [],
                        company_size: company_profile.num_employees || "",
                        website: company_profile.website || "",
                        industryy: company_profile.industryy || "",
                        date_founded: company_profile.date_founded || "",
                        about: company_profile.about || "",
                    });
                    setSelected(
                        company_profile.locations.map((loc) => ({
                            value: loc.title,
                            label: loc.title,
                        }))
                    );
                    if (company_profile.logo) {
                        setLogo(`https://hiringstg.eyouthlearning.com${company_profile.logo}`);
                    }
                }
            } catch (error) {
                console.error("Error fetching company data:", error);
            }
        };

        fetchData();
        fetchIndustries();
    }, [token, userData.company_name]);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            color: "black",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            color: "black",
        }),
        option: (provided) => ({
            ...provided,
            color: "black",
        }),
    };

    const handleLocationChange = (selectedOptions) => {
        const values = selectedOptions
            ? selectedOptions.map((option) => option.label)
            : [];
        setSelected(selectedOptions);
        formik.setFieldValue("locations", values);
    };

    const handleSelectChange = (name) => (selectedOption) => {
        formik.setFieldValue(name, selectedOption ? selectedOption.value : "");
    };

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
            if (!validImageTypes.includes(file.type)) {
                setShowImageError(true);
                return;
            }
            setShowImageError(false);
            const reader = new FileReader();
            reader.onload = () => {
                setLogo(reader.result);
            };
            reader.readAsDataURL(file);

            // Automatically submit the image after selection
            const formData = new FormData();
            formData.append('image', file, file.name);
            try {
                const result = await changeComapnyLogo(token, formData);
                if (result && result.imageUrl) {
                    setLogo(result.imageUrl); // Update the logo
                    setApiMessage(result.message || "Logo updated successfully!");
                    setShowImageAlert(true);
                    setTimeout(() => {
                        setShowImageAlert(false);
                    }, 5000); // Alert will disappear after 5 seconds
                } else {
                    console.error("Failed to update the logo: Invalid response structure", result);
                    setApiMessage("Logo updated successfully!");
                    setShowImageAlert(true);
                    setTimeout(() => {
                        setShowImageAlert(false);
                    }, 5000);
                }
            } catch (error) {
                console.error("Error uploading image:", error);
                setApiMessage("Error uploading image. Please try again.");
                setShowImageAlert(true);
                setTimeout(() => {
                    setShowImageAlert(false);
                }, 5000);
            }
        }
    };

    return (
        <div className={styles.overviewContainer}>
            <div className={styles.personalInfoHeader}>
                <h2>Basic Information</h2>
                <h3>This is company information that you can update anytime.</h3>
            </div>

            <div className={styles.photoSection}>
                <div className={styles.photoHead}>
                    <h2>Company Logo</h2>
                    <h3>This image will be shown publicly as company logo.</h3>
                </div>
                <form className="d-flex gap-3 flex-column">
                    <div className="d-flex gap-3 justify-content-end">
                        <img src={logo} alt="company" className={styles.profileImage} />
                        <div className={styles.uploadImgSection}>
                            <label htmlFor="upload-image" className={styles.uploadLabel}>
                                <img src={iconPhoto} alt="icon" />
                                <h4>
                                    <span>Click to replace </span>
                                </h4>
                                <h5>SVG, PNG, JPG or GIF (max. 400 x 400px)</h5>
                            </label>
                            <input
                                id="upload-image"
                                type="file"
                                className={styles.uploadInput}
                                onChange={handleImageChange}
                            />
                        </div>
                    </div>
                    {showImageAlert && (
                        <Alert severity="success" onClose={() => setShowImageAlert(false)}>
                            {apiMessage}
                        </Alert>
                    )}
                    {showImageError && (
                        <Alert severity="error" onClose={() => setShowImageError(false)}>
                            Please select a valid image file (JPG, PNG, GIF, SVG).
                        </Alert>
                    )}
                </form>
            </div>

            <div className={styles.personalDetailsSection}>
                <div className={styles.Company_Details}>
                    <h2>Company Details</h2>
                    <h3>
                        Introduce your company core info quickly to users by filling up
                        company details
                    </h3>
                </div>

                <form className={styles.formSection} onSubmit={formik.handleSubmit}>
                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Website</h4>
                        <TextField
                            name="website"
                            fullWidth
                            className={styles.txtField}
                            value={formik.values.website}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.website && Boolean(formik.errors.website)}
                            helperText={formik.touched.website && formik.errors.website}
                            required
                        />
                    </div>

                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Location</h4>
                        <Select
                            isMulti
                            value={selected}
                            onChange={handleLocationChange}
                            options={options}
                            styles={customStyles}
                            className={styles.test}
                            required
                        />
                        {formik.touched.locations && formik.errors.locations && (
                            <div className={styles.errorText}>{formik.errors.locations}</div>
                        )}
                    </div>

                    <div className="d-flex gap-3 pb-2 mt-2">
                        <FormControl fullWidth>
                            <h4 className={styles.fieldsName}>Employee</h4>
                            <Select
                                name="company_size"
                                className={styles.selectFieldSmall}
                                value={{
                                    value: formik.values.company_size,
                                    label: formik.values.company_size,
                                }}
                                onChange={handleSelectChange("company_size")}
                                options={[
                                    { value: "1-50", label: "1-50" },
                                    { value: "51-100", label: "51-100" },
                                    { value: "101-200", label: "101-200" },
                                ]}
                                styles={customStyles}
                                isClearable
                                required
                            />
                            {formik.touched.company_size && formik.errors.company_size && (
                                <div className={styles.errorText}>
                                    {formik.errors.company_size}
                                </div>
                            )}
                        </FormControl>

                        <FormControl fullWidth>
                            <h4 className={styles.fieldsName}>Industry</h4>
                            <Select
                                name="industryy"
                                className={styles.selectFieldSmall}
                                value={{
                                    value: formik.values.industryy,
                                    label: formik.values.industryy,
                                }}
                                onChange={handleSelectChange("industryy")}
                                options={industries}
                                styles={customStyles}
                                isClearable
                                required
                            />
                            {formik.touched.industryy && formik.errors.industryy && (
                                <div className={styles.errorText}>{formik.errors.industryy}</div>
                            )}
                        </FormControl>
                    </div>

                    <div className="pb-2">
                        <h4 className={styles.fieldsName}>Date Founded</h4>
                        <TextField
                            type="date"
                            name="date_founded"
                            fullWidth
                            className={styles.txtField}
                            value={formik.values.date_founded}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.date_founded &&
                                Boolean(formik.errors.date_founded)
                            }
                            helperText={
                                formik.touched.date_founded && formik.errors.date_founded
                            }
                            required
                        />
                    </div>

                    <div className={styles.aboutMeSection}>
                        <div className="w-25">
                            <h2>About Company</h2>
                            <h3>Brief description for your company. URLs are hyperlinked.</h3>
                        </div>
                        <TextField
                            name="about"
                            placeholder="Introduce Your Company"
                            multiline
                            rows={5}
                            maxRows={9}
                            value={formik.values.about}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.about && Boolean(formik.errors.about)}
                            helperText={formik.touched.about && formik.errors.about}
                            className={styles.aboutMeTxt}
                            required
                        />
                    </div>

                    <div className={styles.saveBtnSection}>
                        <button
                            type="submit"
                            disabled={formik.isSubmitting || !formik.isValid}
                            className={styles.saveChangesBtn}
                        >
                            Save Changes
                        </button>
                    </div>
                </form>
            </div>

            <div>
                {showSuccessAlert && (
                    <Alert
                        variant="filled"
                        severity="success"
                        onClose={() => setShowSuccessAlert(false)}
                    >
                        {apiMessage}
                    </Alert>
                )}
            </div>
        </div >
    );
}
