import { RefreshToken } from './CandidateApis';

export const WEBSITE_URL = "https://hiringstg.eyouthlearning.com";
export const getToken = () => {
  return localStorage.getItem('token');
};
export const getUserData = () => {
  const userData = localStorage.getItem('user');
  if (!userData) return {};
  return JSON.parse(userData);
};

function isTokenExpired(expirationTime) {
  const currentTime = new Date().getTime();
  const expirationTimeMs = new Date(expirationTime).getTime();
  return currentTime > expirationTimeMs;
}
// Function to handle token refresh
async function handleTokenRefresh() {
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return false;
  }

  try {
    const result = await RefreshToken(refreshToken);
    if (result.new_token) {
      // Update tokens in localStorage
      localStorage.setItem("token", result.new_token);
      localStorage.setItem("expired-time", result.expiration_time);
      return true;
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    return false;
  }
  return false;
}

export async function checkAndRefreshToken() {
  const expirationTime = localStorage.getItem("expired-time");
  const token = localStorage.getItem("token");

  if (!token || !expirationTime) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("expired-time");
    return;
  }

  if (isTokenExpired(expirationTime)) {
    const refreshed = await handleTokenRefresh();
    if (!refreshed) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("expired-time");
      // Optionally, you can redirect to the login page here
    }
  }
}




