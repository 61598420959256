import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import styles from "./ProfessionalInfo.module.css";

const WorkExperienceModal = ({ open, handleClose, experience, setExperience, handleSave }) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!experience) {
      setExperience({
        job_title: "",
        organization: "",
        description: "",
        year_experience: "",
        fromm: "",
        to: "",
        currently_working: false,
        reference_name: "",
        reference_phone: "",
        reference_jobtitle: "",
        reference_relationship: ""
      });
    }
  }, [experience, setExperience]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExperience((prev) => {
      const updatedExperience = { ...prev, [name]: value };
      if (name === 'fromm' || name === 'to') {
        updatedExperience.year_experience = calculateExperience(updatedExperience.fromm, updatedExperience.to);
      }
      return updatedExperience;
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setExperience((prev) => {
      const updatedExperience = { ...prev, [name]: checked };
      if (name === 'currently_working' && checked) {
        updatedExperience.to = "";
        updatedExperience.year_experience = calculateExperience(updatedExperience.fromm, "");
      }
      return updatedExperience;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateFields(experience);
    if (Object.keys(newErrors).length === 0) {
      handleSave(experience);
    } else {
      setErrors(newErrors);
    }
  };

  const calculateExperience = (fromDate, toDate) => {
    if (!fromDate) return "";
    const startDate = new Date(fromDate);
    const endDate = toDate ? new Date(toDate) : new Date();

    const diffYears = endDate.getFullYear() - startDate.getFullYear();
    const diffMonths = endDate.getMonth() - startDate.getMonth();
    const diffDays = endDate.getDate() - startDate.getDate();

    // Adjust if months are negative
    let adjustedYears = diffYears;
    let adjustedMonths = diffMonths;

    if (diffMonths < 0 || (diffMonths === 0 && diffDays < 0)) {
      adjustedYears--;
      adjustedMonths += 12;
    }

    // Calculate total experience in years and months as a decimal
    return (adjustedYears + adjustedMonths / 12).toFixed(1);
  };

  const validateFields = (experience) => {
    const errors = {};
    if (!experience.job_title) errors.job_title = "Job title is required";
    if (!experience.organization) errors.organization = "Organization is required";
    if (!experience.fromm) errors.fromm = "Start date is required";
    if (!experience.to && !experience.currently_working) errors.to = "End date is required unless currently working";
    if (experience.to && experience.fromm && experience.to < experience.fromm) {
      errors.to = "End date cannot be before start date";
    }
    return errors;
  };

  if (!experience) return null;

  return (
    <Dialog sx={{
      "& .MuiPaper-root": {
        maxHeight: "60%", // Adjust the max height as needed
      },
    }} open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        {experience.organization ? 'Edit Work Experience' : 'Add Work Experience'}
      </DialogTitle>
      <DialogContent>
        <form className={styles.formSection} onSubmit={handleSubmit}>
          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>Company Name *</h6>
            <TextField
              name="organization"
              value={experience.organization || ''}
              onChange={handleChange}
              className={styles.txtField}
              error={!!errors.organization}
              helperText={errors.organization}
              fullWidth
            />
          </div>

          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>Your Position *</h6>
            <TextField
              name="job_title"
              value={experience.job_title || ''}
              onChange={handleChange}
              className={styles.txtField}
              error={!!errors.job_title}
              helperText={errors.job_title}
              fullWidth
            />
          </div>

          <div className="d-flex gap-3">
            <div className={styles.dateField}>
              <h6 className={styles.fieldsName}>Start Date *</h6>
              <TextField
                type="date"
                name="fromm"
                value={experience.fromm || ''}
                onChange={handleChange}
                className={styles.dateInput}
                error={!!errors.fromm}
                helperText={errors.fromm}
                fullWidth
              />
            </div>

            <div className={styles.dateField}>
              <h6 className={styles.fieldsName}>End Date *</h6>
              <TextField
                type="date"
                name="to"
                value={experience.to || ''}
                onChange={handleChange}
                className={styles.dateInput}
                error={!!errors.to}
                helperText={errors.to}
                fullWidth
                disabled={experience.currently_working}
              />
            </div>
          </div>

          <FormControlLabel
            className={styles.checkbox}
            control={
              <Checkbox
                name="currently_working"
                checked={experience.currently_working || false}
                onChange={handleCheckboxChange}
              />
            }
            label="Currently Work there"
          />

          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>Years of Experience</h6>
            <TextField
              name="year_experience"
              value={experience.year_experience || ''}
              className={styles.txtField}
              disabled
              fullWidth
            />
          </div>

          <div className={styles.txtFieldContainer}>
            <h6 className={styles.fieldsName}>Reference Name *</h6>
            <TextField
              name="reference_name"
              value={experience.reference_name || ''}
              onChange={handleChange}
              className={styles.txtField}
              error={!!errors.reference_name}
              helperText={errors.reference_name}
              fullWidth
            />
          </div>

          <div className="d-flex flex-row gap-3">
            <div className={styles.referenceInfo}>
              <h6 className={styles.fieldsName}>Reference phone *</h6>
              <TextField
                name="reference_phone"
                value={experience.reference_phone || ''}
                onChange={handleChange}
                className={styles.referenceTxtField}
                error={!!errors.reference_phone}
                helperText={errors.reference_phone}
                fullWidth
              />
            </div>
            <div className={styles.referenceInfo}>
              <h6 className={styles.fieldsName}>Relationship *</h6>
              <TextField
                name="reference_relationship"
                value={experience.reference_relationship || ''}
                onChange={handleChange}
                className={styles.referenceTxtField}
                error={!!errors.reference_relationship}
                helperText={errors.reference_relationship}
                fullWidth
              />
            </div>
          </div>

          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default WorkExperienceModal;
