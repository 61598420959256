import { createSlice } from "@reduxjs/toolkit";

export const phoneSlice = createSlice({
  name: "phoneSlice",
  initialState: [],
  reducers: {
    SET_PHONE: (state, action) => {
      state = action.payload;
      return state;
    },
    ADD_PHONE: (state, action) => {
      const found = state.find(
        (ele) => ele.username === action.payload.username
      );
      if (!found) {
        state.push(action.payload);
      }
      return state;
    },
    REMOVE_PHONE: (state, action) => {
      return state.filter(
        (element) => element.username !== action.payload.username
      );
    },
  },
});

export const { ADD_PHONE, REMOVE_PHONE, SET_PHONE } = phoneSlice.actions;
export default phoneSlice.reducer;
