import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import styles from './AppliedJobs.module.css';
import companyLogo from "../../../images/assets/Nomad.svg";
import { getUserData, getToken } from '../../../Middleware/Constans';
import Loading from '../../../components/Loading/Loading';

function AppliedJobs() {
  const userData = getUserData();
  const token = getToken();
  const applicantID = userData.applicant_id;
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchJobs = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `JWT ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      try {
        const response = await fetch(`https://hiringstg.eyouthlearning.com/job/job-application/?applicant_id=${applicantID}`, requestOptions);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();

        setJobs(result.results); // Adjust this line based on the actual structure of the response
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchJobs();
  }, [applicantID, token]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleJobClick = (companySlug, jobSlug) => {
    navigate(`/job-details/${companySlug}/${jobSlug}`);
  };

  return (
    <>
      {jobs.map((job) => (

        <div
          key={job.id}
          className={styles.jobsListCard}
          onClick={() => handleJobClick(job.job.company.slug, job.job.slug)} // Correctly access nested properties
          style={{ cursor: 'pointer', textDecoration: "none" }} // Optional: Change cursor to pointer
        >
          <div className={styles.jobInfo}>
            <img width="20%" src={`https://hiringstg.eyouthlearning.com${job.job.company.logo}` || companyLogo} alt="company-logo" />
            <div className="d-flex flex-column gap-2">
              <h2>{job.job.title}</h2>
              <h4>{job.job.company.name}</h4>
              <div className="d-flex gap-3">
                <h3>{job.job.type_of_employment.map(type => type.name).join(', ')}</h3>
                <h3>{job.job.type_of_job_location.map(location => location.name).join(', ')}</h3>
              </div>
            </div>
          </div>
          <div className={styles.applyBtn}>
            {job.status}
          </div>
        </div>
      ))}
    </>
  );
}

export default AppliedJobs;
