import React from "react";
import { Autoplay, Navigation } from "swiper";
import "swiper/modules/autoplay/autoplay";
import "swiper/modules/navigation/navigation";
import "swiper/modules/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import imagesData1 from "../../images/images";

export default function PartnersImages() {
  return (
    <>
      <div>
        <Swiper
          breakpoints={{
            320: {
              width: 320,
              slidesPerView: 2,
              spaceBetween: 2,
            },
            480: {
              width: 480,
              slidesPerView: 2,
              spaceBetween: 2,
            },
            768: {
              width: 768,
              slidesPerView: 3,
              spaceBetween: 3,
            },
            1279: {
              width: 1279,
              slidesPerView: 5,
              spaceBetween: 4,
            },
          }}
          autoplay={{
            delay: 1200,
            disableOnInteraction: false,
          }}
          navigation={false}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          {imagesData1.map((img, i) => {
            return (
              <SwiperSlide
                key={i}
                style={{
                  lineHeight: "7rem",
                }}
              >
                <img className="dimmed-photo" src={img.img} alt={img.title} style={{ width: "6em"}} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

    </>
  );
}
