import { createSlice } from "@reduxjs/toolkit";

export const loggedUserSlice = createSlice({
  name: "loggedUser",
  initialState: {},
  reducers: {
    setLoggedUser: (state, action) => {
      state.userData = action.payload.user;
      state.token = action.payload.token;
      return state;
    },
    deleteLoggedUser: (state) => {
      state = {};
      return state;
    },
    updateLoggedUser: (state, action) => {
      state.userData = action.payload;
      return state;
    },
  },
});

export const { setLoggedUser, deleteLoggedUser, updateLoggedUser } =
  loggedUserSlice.actions;

export default loggedUserSlice.reducer;
